import { Model, model, modelAction, prop } from "mobx-keystone";
import { runInAction } from 'mobx';
import { SiteElement } from "./SiteElement";
import { SiteMaterial } from "./SiteMaterial";
import { SiteLanduse } from "./SiteLanduse";
import { LandscapeDataSchema, SiteElementSchema } from "./schemas";
import { AppliedSiteElement } from "./AppliedSiteElement";

@model("dtp/LandscapeData")
export class LandscapeData extends Model({
    siteLanduses: prop<SiteLanduse[]>(() => []),
    siteElements: prop<SiteElement[]>(() => []),
    siteMaterials: prop<SiteMaterial[]>(() => []),
}) {

    @modelAction
    addSiteElement(siteElement: SiteElement) {
        this.siteElements.push(siteElement);
    }

    @modelAction
    addSiteLanduse(siteLanduse: SiteLanduse) {
        this.siteLanduses.push(siteLanduse);
    }

    @modelAction
    addSiteMaterial(material: SiteMaterial) {
        this.siteMaterials.push(material);
    }

    getMaterial(materialId: string) {
        return this.siteMaterials.find(m => m.materialId === materialId);
    }

    @modelAction
    loadFromFile(file: LandscapeDataSchema) {
        const siteElementLookup: { [k: string]: SiteElement } = {};
        file.siteElements.forEach((siteElementData: SiteElementSchema) => {
            let siteElement = new SiteElement({});
            this.addSiteElement(siteElement);
            siteElementLookup[siteElementData['Item Ref. #']] = siteElement;
        })
        file.siteLanduses.forEach((siteLanduseData, i) => {
            let siteLanduse = new SiteLanduse({});
            this.addSiteLanduse(siteLanduse);
            siteLanduseData.subItems.forEach((subItem, j) => {
                const appliedSiteElement = new AppliedSiteElement({
                    percentage: subItem.percentage
                })
                appliedSiteElement.setSiteElement(siteElementLookup[subItem.subItemRefNum]);
                siteLanduse.subItems.push(appliedSiteElement);
            });
        });
    }

    static load(file: LandscapeDataSchema) {
        const landscapeData = new LandscapeData({});
        landscapeData.loadFromFile(file);
        return landscapeData;
    }
}
