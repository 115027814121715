import {observer} from "mobx-react";
import {useDtpStores} from "../../../../core";
import {DensityTypes, GrossTypologyFromDensity} from "../../../../core/stores/ks/Typologies";
import {EmbodiedCarbonSimulation} from "../../../../core/simulations/EmbodiedCarbonSimulation";
import {fixedRectStyle, getUid} from "../../../../core/ui/helpers";
import {PanelHeader} from "../../../../core/ui/elements/PanelHeader";
import {NameColorInput} from "../../../../core/ui/elements/NameColorInput";
import {LabelledSelectList, SelectList} from "../../../../core/simulations/TrafficSimulation";
import {BuildingFloorsInput} from "../../../../core/ui/elements/BuildingFloorsInput";
import {ApplyCancel} from "../../../../core/ui/elements/ApplyCancel";
import React from "react";
import {landuseManagerBack} from "../LandusePanel";
import {LabelledTweakableInput, TweakableInput} from "../../../../core/ui/TweakableInput";
import {SwatchPicker} from "../../../../core/ui/elements/SwatchPicker";
import {EditModes} from '../../../../core/stores/UiStore';

export const EditLanduse = observer(() => {
    const mainStore = useDtpStores();
    const {layout, ui, dataStore} = mainStore;
    const {activeTypology} = ui;
    const grossTypology = activeTypology as GrossTypologyFromDensity;

    if (!grossTypology) return null;
    const carbonSimulation = dataStore.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;
    if (!carbonSimulation) return null;
    const costSimulation = dataStore.simulations.find(s => s.id === 'COST') as EmbodiedCarbonSimulation;
    if (!costSimulation) return null;
    const uid = getUid();

    const options = [DensityTypes.Building, DensityTypes.Surface];
    return <>
        <PanelHeader breadCrumbs={[landuseManagerBack(ui)]} title={'Edit Landuse'}
                     description={'Choose a name, color and type, then define your material palette. If you like, set a per area cost to see an early cost summary.'}/>
        <div className={'panelInner'}>
            <NameColorInput
                name={grossTypology.name}
                setName={(val) => grossTypology.setName(val)}
                color={grossTypology.color}
                setColor={(val) => grossTypology.setColor(val)}
            />
            <LabelledTweakableInput label={'Footprint Area'} tweakableMetric={grossTypology.surfaceAreaConversion}
                                    hideToggle={true}/>
            <LabelledSelectList label={'Landuse Type'} options={options} getter={() => grossTypology.densityType}
                                setter={(val) => grossTypology.setDensityType(val as DensityTypes)}/>

            {grossTypology.densityType === DensityTypes.Building &&
            <BuildingFloorsInput grossTypology={grossTypology}/>}

            {grossTypology.densityType === DensityTypes.Building && <div className={'entry'}>
                <label>
                    Gross Area
                </label>
                <span className={'read-only'}>{grossTypology.grossAreaDisplay}</span>
            </div>}
            {costSimulation.renderTypologyInput(grossTypology)}

            {grossTypology.densityType !== DensityTypes.Empty && carbonSimulation.renderLanduseEditorInput(grossTypology, (component) => {
                mainStore.storeCancelState(`edit_${EditModes.EditComponent}`);
                ui.setEditComponentMode(component)
            })}
            <ApplyCancel onApply={() => {
                ui.setEditMode(EditModes.None)
            }} onCancel={() => {
                ui.setEditMode(EditModes.None);
                mainStore.applyCancelState(`edit_${EditModes.EditLanduse}`);
            }}/>
        </div>
    </>;
});
