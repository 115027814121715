import { ExtendedModel, findParent, model, prop } from "mobx-keystone";
import { MixComponent } from "../../../stores/ks/Simulation";
import { ILabel } from "../../../stores/ks/DataStore";
import { computed } from "mobx";
import { distanceOptions, globals, transportationFactors } from "./assumptions";
import { LandscapeData } from "./LandscapeData";

@model("dtp/SiteElement")
export class SiteElement extends ExtendedModel(MixComponent, {
    itemId: prop<string>('', { setterAction: true }),
    selectedMaterialId: prop<string>('', { setterAction: true }),
    selectedTransOption: prop<string>('', { setterAction: true }),
    selectedTransDistance: prop<string>('', { setterAction: true }),
    //Quantity factor (% of M^3 for volumes, % of M^2 for Areas)
    quantityFactor: prop<number>(0, { setterAction: true }),
}) implements ILabel {

    @computed
    get landscapeData() {
        return findParent<LandscapeData>(this, (parentNode: any) => {
            return parentNode instanceof LandscapeData;
        });
    }

    get label() {
        return '';
    }

    @computed
    get selectedMaterial() {
        return this.landscapeData?.getMaterial(this.selectedMaterialId);
    }

    @computed
    get materialCostLow() {
        return this.selectedMaterial?.costLow || 0;
    }

    @computed
    get materialCostHigh() {
        return this.selectedMaterial?.costHigh || 0;
    }

    @computed
    get materialStored() {
        return this.selectedMaterial?.stored || 0;
    }

    // Replacement Factor
    @computed
    get replacementFactor() {
        return this.selectedMaterial?.replacementFactor || 0;
    }


    //Density (kg/M^3)
    @computed
    get density() {
        return this.selectedMaterial?.density || 0;
    }

    //Mass (kg)
    @computed
    get mass() {
        return this.quantityFactor * this.density;
    }

    //Trans. Factor (CO2e kg/(kg/km))
    @computed
    get transFactor() {
        return transportationFactors[this.selectedTransOption];
    }

    //Transportation Distance Estimate (km)
    @computed
    get transDistance() {
        return distanceOptions[this.selectedTransDistance];
    }

    // Transportation (Co2e kg/m^2)
    @computed
    get transportation() {
        return this.mass * this.transFactor * this.transDistance;
    }

    // Embodied Carbon Cost (Low) (CO2e Kg/m^2)
    @computed
    get costLow() {
        return this.mass * this.materialCostLow * this.replacementFactor * globals.installationContingency.low + this.transportation
    }

    // Embodied Carbon Cost (High)  (kg CO2 eq./m^2)
    @computed
    get costHigh() {
        return this.mass * this.materialCostHigh * this.replacementFactor * globals.installationContingency.high + this.transportation
    }

    // Net Carbon Sequestered (60 years)  (kg CO2 eq./m^2)
    @computed
    get sequestered() {
        return (this.selectedMaterial?.sequestered || 0);
    }

    // Carbon Stored in Materials (kg CO2 eq./m^2) OR existing ecosystems to be preserved
    @computed
    get stored() {
        return (this.selectedMaterial?.carbonStoredRate || 0) * this.quantityFactor;
    }

}
