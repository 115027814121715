import { useState } from "react";
import { observer } from "mobx-react";
import * as Icon from 'react-feather';

import { useDtpStores } from "../..";
import { Button } from '../elements/Button';
import { IconButton } from "../elements/IconButton";
import {Settings} from "react-feather";


export default observer(function ToolBar() {
    const { dataStore, persist, ui } = useDtpStores();
    const [showSave, setShowSave] = useState(false);
    const [saving, setSaving] = useState(false);

    return (
        <div className="ToolBar">
            <div className="toolButtons">
                <IconButton icon={<Icon.Info />} onClick={() => ui.setInfoModalOpen()}/>
                <IconButton icon={<Icon.Settings />} onClick={() => ui.setSettingsModalOpen()}/>
                <IconButton icon={<Icon.BarChart2 />} onClick={() => ui.setExportModalOpen()}/>
                <IconButton icon={<Icon.Folder />} onClick={() => ui.setFileModalOpen()} />
                <IconButton
                    icon={<Icon.Save />}
                    onMouseEnter={() => setShowSave(true)}
                    onMouseLeave={() => setShowSave(false)}
                />
            </div>

            <div className={`SaveOptions ${showSave ? 'active' : ''}`}>
                <Button
                    title={saving ? 'Saving ...' : 'Save'}
                    className={`primary ${!persist.exists || saving ? 'disabled' : ''}`}
                    disabled={!persist.exists || saving}
                    onClick={() => {
                        setSaving(true);
                        setTimeout(() => setSaving(false), 2000);
                        persist.save(dataStore);
                    }}
                />

            <Button title={'Save As'} className="secondary" onClick={() => ui.setSaveAsModalOpen()} />
            </div>
        </div>
    );
});
