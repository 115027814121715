import { action, computed, observable } from 'mobx';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { IMainStore } from './MainStore';


export interface IUserStore {
    user: any;
    displayName: string;
    isLoggedIn: boolean;
    isVerified: boolean;
    login(): void;
    logout(): void;
    loginWithEmailPassword(email: string, password: string): void;
    create(name: string, email: string, password: string): void;
    resetPassword(email: string): void;
    verifyEmail(): void;
    whitelist: string[];
};

class UserStore implements IUserStore {

    private mainStore: IMainStore;

    whitelist: string[];

    constructor(mainStore: IMainStore) {
        this.mainStore = mainStore;
        this.whitelist = mainStore.config.whitelist;

        if (firebase.apps.length === 0) {
            firebase.initializeApp(this.mainStore.config);
        }

        firebase.auth().onAuthStateChanged(user => {
            // @ts-ignore
            if (user && user.multiFactor && user.multiFactor.user) {
            // @ts-ignore
                this._setUser(user.multiFactor.user);
            }
            else {
                this._setUser(null);
            }
        });
    }

    @observable
    user: any = null;

    @computed
    get displayName() {
        return this.user ? this.user.displayName : '';
    }

    async create(displayName: string, email: string, password: string) {
        const res = await firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
        if (res && res.user) {
            res.user.updateProfile({ displayName })
            this.verifyEmail();
        }
    }

    login() {
        firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    async loginWithEmailPassword(email: string, password: string) {
        return firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password);
    }

    logout() {
        firebase.auth().signOut();
        window.location.reload();
    }

    resetPassword(email: string) {
        firebase.auth().sendPasswordResetEmail(email.toLowerCase()).catch(() => {});
    }

    verifyEmail() {
        const user = firebase.auth().currentUser;
        if (user) {
            user.sendEmailVerification().catch(() => {});
        }

    }

    @computed
    get isLoggedIn() {
        return this.user !== null;
    }

    @computed
    get isVerified() {
        return this.user && this.user.emailVerified;
    }

    @action
    private _setUser(user: any) {
        this.user = user;
    }

}


export default UserStore;
