import {useState} from 'react';
import {observer} from 'mobx-react';

const tabs = [
    {
        title: 'General Information',
        body: <>
            <p>
                This tool is a web-based design analysis tool linked to a land use dataset that projects probable
                embodied Carbon Emissions, Sequestration, and Storage per unit area. This is a comparative analysis tool
                to help with decision making and iteration during master planning or early concept design phases.
            </p>

            <p>
                This tool is not an energy modeling tool, nor does it project carbon associated with operations and
                maintenance or transportation beyond construction. This tool is not intended to be an accurate estimate
                of carbon for a detailed design. While information generated by this calculator is taken from
                peer-reviewed sources and is cited, numerous assumptions were required per land use, all of which can be
                reviewed in our Methodology page. If you are looking for a tool to evaluate detailed design, energy
                modeling, or operations and maintenance recommendations, please go to our Carbon Calculators and LCAs
                Reviews and Recommendations page.
            </p>
        </>
    },
    {
        title: 'Resources',
        body: <>
            <p>
                Read the <a href="https://firebasestorage.googleapis.com/v0/b/carbon-conscience.appspot.com/o/Carbon%20Conscience%20white%20paper.pdf?alt=media&token=1500d535-4ec5-4786-99a8-2587012cc8e6" target={'_blank'} rel={'noreferrer'}>Carbon Conscience white paper</a>.
            </p>

            <p>
                Watch <a href="https://vimeo.com/sasvideos/carbon-conscience-primer" target={'_blank'} rel={'noreferrer'}>How to Reduce Carbon Impacts from Kickoff</a>: a walk-through of the Carbon Conscience tool with Sasaki landscape architect Chris Hardy.
            </p>
        </>
    },
    {
        title: 'Motivation',
        body: <>
            <p>
                “A clear conscience is the sure sign of a bad memory.” ~ Mark Twain
            </p>

            <p>
                “Whether we and our politicians know it or not, Nature is party to all our deals and decisions, and she
                has more votes, a longer memory, and a sterner sense of justice than we do.” ~ Wendell Berry
            </p>

            <p>
                “To achieve great things, two things are needed; a plan, and not quite enough time.” ~ Leonard Bernstein
            </p>

            <p>
                We believe the earliest decisions in a plan can have the most impact on the ultimate design. Often, in
                master planning phases, concept design, or urban design projects, the time for nuanced decision making
                can be compressed, constricted by public or regulatory processes, production requirements, or creative
                processes. These decisions can inform the fundamental future fabric and land use of places and cities.
                We needed a tool to quickly understand the impacts of our work in terms of embodied carbon, so carbon is
                a part of the design discussion from the earliest sketches. Only through understanding the impacts of
                our work can we make informed recommendations and improve our designs. Carbon Conscience is a tool to
                help us quickly understand the carbon impacts of our designs, compare high-level land use options, and
                inform our clients and communities the implications of design options.
            </p>
        </>
    },
    {
        title: 'Terms and Definitions to Know',
        body: <>
            <p>
                <b>LCA: Life-Cycle-Assessment</b>: A material or product data sheet that estimates a variety of impacts for
                that material on the environment over the material life span, including Global Warming Potential (GWP)
                measured in kilograms of carbon emissions equivalent.
            </p>

            <p>
                <b>Carbon Emissions</b>: Carbon dioxide emitted through an activity or process, such as material extraction,
                fabrication, transportation and assembly, and possible demolition.
            </p>

            <p>
                <b>Carbon Equivalent</b>: The equivalent mass of carbon dioxide in terms of GWP (global warming potential).
                This simplifies emissions of various greenhouse gases into one metric, and is the basis for most LCA
                estimates of carbon impact of materials.
            </p>

            <p>
                <b>Carbon Factor</b>: A multiplier that refers to emissions per mass of a given material. The standard unit
                used is kgC/kg (Kilograms of carbon dioxide emissions equivalent per kilograms of material).
            </p>

            <p>
                <b>Embodied Carbon</b>: Estimate of the probable carbon emission from a material or product’s sourcing,
                fabrication, transport, and installation on a site. The standard unit used in this data set is kgCO2e
                (Kilograms of carbon dioxide emissions equivalent) (abbreviated in our tables as kgC).
            </p>

            <p>
                <b>Carbon Storage</b>: The mass of carbon locked up within building materials, vegetation, or soils that is not
                readily off-gassed into the atmosphere. For the purposes of this project, we counted carbon stored for
                at least 20 years. The standard unit used in this data set is kgC (Kilograms of Carbon dioxide stored).
            </p>

            <p>
                <b>Land use</b>: A description of the coverage of a piece of land; for example, “Restored Wetland”, “2- Story
                Single Family Residence”, and “Low-Intensity Concrete Hardscape” are all different land uses. This tool
                focuses primarily on proposed land use, with the exception of preserved existing-to-remain habitats.
            </p>

            <p>
                <b>Carbon Sequestration</b>: The active storing of carbon from the atmosphere into vegetation or soils.
            </p>

            <p>
                <b>Projected Carbon Sequestered</b>: The amount of carbon actively stored or fixed from the atmosphere in
                vegetation or soils, after construction. For the purposes of this project, we used the net sequestration
                of carbon within a given habitat or vegetation coverage in living and non-living biomass, accounting for
                respiration and decay. The standard rate of sequestration used in this data set is kgC/m2/yr (Kilograms
                of Carbon dioxide sequestered per square meter per year). Because of the variable sequestration rates
                over the growth of vegetation and decline or balancing with net respiration and decay (depending on
                ecosystem) at maturity (Pugh et al., 2019; Radford, 2019), we project carbon sequestered within 80 years
                of project installation (80 years as the standard estimate of forest maturity by the United States
                Forest Service) (USFS, 2003). The standard unit for projected carbon sequestered in this tool is kgC/m2.
            </p>
        </>
    },
    {
        title: 'How to Use This Product',
        body: <>
            <h4>Defining your Palette of Land Uses</h4>
            <p>
                The first step is to develop your project palette of land uses, including both architectural and
                landscape land uses. Once you have your palette, you can enter areas - either numerically or using the
                map painting tool to sketch land uses.
            </p>

            <h4>Adding land uses</h4>
            <p>
                To add a land use, click on the "Create Landuse" button. This opens the land use editor, where you must
                assign a unique short name for your land use, pick a color (every land use should have a unique color),
                and assign associated parameters.
            </p>

            <p>
                You can then define the which "materials" make up that land use. You may have as many materials as you
                like for each landuse. This solution lets you test more detailed design decisions and preview the Carbon
                impact without designing them in detail.
            </p>

            <p>
                For architectural materials, the first parameter to select is the building program, such as ‘office’ or
                ‘education’. Within that program, define the total number of stories and specify how many are below
                grade. Once the category is selected, you may choose to also select a structural system, and then select
                an envelope system. This will refine the estimate of carbon impacts per square meter of construction for
                that unique land use. The tool will then multiply this value for all stories of the proposed building.
            </p>

            <p>
                For landscape materials, the first parameter to pick is the ‘category’. This is a high level description
                of the land use type, such as ‘Softscape’ or ‘Hardscape’. Next, select the subcategory, then you will be
                able to pick from a range of land use ‘cards’ that include carbon data and a unique land use detailed
                description.
            </p>

            <h4>Understanding the Results</h4>
            <p>
                In the left hand panel of the application, the user will see a summary of the carbon impacts for the
                project. In general, the high estimates correlate to higher or less efficient material carbon costs
                found in literature. The lower estimates correlate to lower carbon estimates usually tied to specific
                material specifications (such as assuming use of low carbon concretes). The total carbon sequestered and
                total carbon stored are both single values less impacted by nuanced material specifications. The total
                carbon sequestered is projected as the total carbon fixed in living and non-living biomass within the
                mapped land uses by maturity, or 80 years per the United States Forest Service standard. The total
                carbon stored is the sum of carbon content for all wood included in built land uses mapped, anticipated
                to be in place for more than 2o years.
            </p>

            <p>
                These results provide a comparative value, to help inform land use decisions from a carbon perspective.
                These results are most useful when comparing options between schemes for a specific project to see
                relative carbon impacts.
            </p>

            <h4>Choosing Units</h4>
            <p>
                CarbonConscience can be used with a number of different currency units. Area metrics can be tracked in meters or feet. Use the settings dialog (via the gear icon) to choose your units. <a href={'https://sasakistrategies.typeform.com/carbon'} target={'_blank'}>Let us know</a> if there are additional units we should support.
            </p>

            <h4>Using the Map Painting Tool (Optional)</h4>
            <p>
                Areas can be entered manually (e.g. from CAD take-offs) or using the integrated painting tool. Open the painting tool using the links from the panel on the right. You can either open the tool in a
                separate window or on another device.
            </p>

            <p>Locate your project on the map using the search box or by zooming and panning. Follow the instructions at
                the bottom that will guide you to zoom in to a resolution where you can begin painting land uses.</p>

            <p>
                Land uses defined in the main window will show as swatches in the paint tool. Select a paint swatch to begin painting with the marker tool.
            </p>
            <h4>Pan tool </h4>
            <p>Pan and zoom the map (default map behavior).</p>
            <h4>Marker </h4>
            <p>Paint pixels with a circular brush. Use the slider to modify the marker paint size.
                Note this is zoom dependent. You get finer brush details as you zoom in. Real-world size will be reported below. Specifying an exact brush size in real-world units is not supported as pixel size varies by latitude. Zooming in can help get a more accurate size for drawing objects of a known width such as paths.
            </p>
            <p>Useful shortcuts:</p>
            <ul>
                <li>Shift+Click to draw straight line segments.</li>
                <li>Ctrl+Click when in marker mode to fill using Paint Bucket tool.</li>
                <li>Spacebar: temporarily enable pan mode while spacebar is down. Release spacebar to return to the previous
                    tool.
                </li>
            </ul>
            <h4>Eraser
            </h4>
            <p>The eraser provides the same controls and shortcuts as the marker to erase content.</p>
            <h4>Paint Bucket
            </h4>
            <p>Fill an enclosed area with the selected paint color.</p>
        </>
    },
];
export default observer(function ToolInfo() {
    const [tab, setTab] = useState(0);

    return (
        <>
            <div className="col">
                {/*<p>*/}
                {/*    Let us explain the details of the metrics, inputs and outputs. Find below a simple product overview.*/}
                {/*</p>*/}
                <p>
                    Use this tool to quickly compare projected embodied Carbon emissions, Carbon sequestration, and
                    Carbon
                    stored, for your master planning, urban design, and concept design projects. This is an
                    interdisciplinary
                    tool, developed for planning, urban design, architecture, landscape architecture, civil
                    engineering, and
                    ecological restoration professionals.
                </p>
            </div>

            <ul className="accordion">
                {tabs.map(({title, body}, i) => (
                    <li key={title} className={tab === i ? 'open' : ''} onClick={() => setTab(i)}>
                        <h3><span>{title}</span></h3>
                        <div className="accordion-content">
                            {body}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
});

