// https://docs.google.com/spreadsheets/d/1InuNELCUc1sZlcAIOMW2eb6cpD_wLdgp_Q4-ZsM4S_k/edit#gid=740968684
export const transportationFactors: { [key: string]: number } = {
    "Assume 100% Truck (Typical)": 0.00111,
    "Assume 90% Rail, 10% Truck": 0.00024,
    "Assume 90% Shipping, 10% Truck": 0.00063,
    "Assume 95% Air, 5% Truck": 0.00842,
    "Assume entirely bicycle or renewable energy for transit": 0.00000
}

export const distanceOptions: { [key: string]: number } = {
    "On-site": 1,
    "Hyper-Local": 16,
    "Local": 160,
    "Regional": 800,
    "Long Distance": 4800,
}

export const globals = {
    installationContingency: {
        low: 1.05,
        high: 1.05,
    }
}
