import { ExtendedModel, model, modelAction, prop, Ref } from "mobx-keystone";
import { MixComponent } from "../../../stores/ks/Simulation";
import { SiteElement } from "./SiteElement";
import { ILabel } from "../../../stores/ks/DataStore";
import { computed } from "mobx";
import { siteElementRef } from "./SiteLanduse";

@model("dtp/AppliedSiteElement")
export class AppliedSiteElement extends ExtendedModel(MixComponent, {
    siteElementRef: prop<Ref<SiteElement> | undefined>(() => siteElementRef('')),
    percentage: prop<number>(1),
}) implements ILabel {

    @computed
    get siteElement() {
        return this.siteElementRef ? this.siteElementRef.maybeCurrent : undefined;
    }

    @modelAction
    setSiteElement(siteElement: SiteElement | undefined) {
        if (!siteElement) return;
        this.siteElementRef = siteElementRef(siteElement);
    }

    get label() {
        return '';
    }
}
