import React from "react";
import ReactSlider from "react-slider";
import {observer} from "mobx-react";

export const HorizontalMixer = observer((props: { colors: string[], labels: string[], breakValues: number[], onChange: (value: number[], index: number) => void }) => {
    const {colors, breakValues, onChange, labels} = props;
    const thumbSize = 12;//match $thumbSize in scss
    const percLabel = (values: number[], index: number) => {
        values = [...values, 100];//TODO get max
        return index > 0 ? values[index] - values[index - 1] : values[index]
    };
    const trackLabels = (values: number[], index: number) => {
        const perc = percLabel(values, index);
        const label = labels[index] || '';
        return <div>
            <div className={'perc'}>{perc}%</div>
            <div className={'label'}>{label}</div>
        </div>;
    };
    return (
        <div className={'HorizontalMixer'}>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="mixer-thumb"
                trackClassName="mixer-track"
                value={breakValues}
                onChange={(value, i) => {
                    value = Array.isArray(value) ? value : [value]
                    onChange(value, i);
                }}
                ariaLabel={['Leftmost thumb', 'Middle thumb', 'Rightmost thumb']}
                renderThumb={(props, state) => {
                    // return <div className="mixer-"{...props}>{state.valueNow}</div>;
                    return <div className="mixer-"{...props}/>;
                }}
                renderTrack={(props, state) => {
                    if (props.style) {
                        props.style.backgroundColor = colors[state.index];
                        if (typeof props.style.left === 'number') props.style.left += thumbSize / 2;
                        if (typeof props.style.right === 'number') props.style.right += thumbSize / 2;
                    }
                    return <div {...props}>
                        <div className="track-label">
                            {trackLabels(Array.isArray(state.value) ? state.value : [state.value], state.index)}
                        </div>
                    </div>
                }}
                pearling
                minDistance={1}
            />
        </div>
    );
});
