import {observer} from "mobx-react";
import React, {MouseEventHandler} from "react";
import {Swatch} from "./Swatch";
import {SketchPicker} from "react-color";
import {TextInput} from "../TextInput";
import {SwatchPicker} from "./SwatchPicker";
import {getUid} from "../helpers";


export const NameColorInput = observer((props: { color: string, name: string, setName: (name: string) => void, setColor: (color: string) => void, }) => {
    const {color, name, setName, setColor} = props;
    const uid = getUid();
    return <div className="NameColorInput">
        <div className={'entry'}>
            <label htmlFor={`color_${uid}`}>
                Color
            </label>
            <SwatchPicker color={color} setColor={setColor}/>
        </div>
        <div className={'entry'}>
            <label htmlFor={`name_${uid}`}>Name</label>
            <TextInput value={name} setValue={setName}/>
        </div>

    </div>;
});
