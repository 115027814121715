import {Composite, DataStream, FauxVersion, MetaData, MutableVersion} from "../../core/stores/ks/Streams";
import {DataStore} from "../../core/stores/ks/DataStore";
import {phaseGroups, zones} from "../cc-hellenikon/data/config";

//the basic version of Carbon Conscience uses Firebase RealTime DB for its streams and doesn't use versions
export const addSimpleStreams = (dataStore: DataStore) => {

    const version = MutableVersion.fromData({});
    const stream = new DataStream({
        id: 'simple-stream',
        name: 'Simple Stream',
        metadata: new MetaData({}),
        versionHistory: [version]
    });
    dataStore.addStream(stream);

    const composite = new Composite({name:stream.name});
    dataStore.addComposite(composite);
    composite.addDataStream(stream);
}
