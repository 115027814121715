import {observer} from "mobx-react";
import React from "react";

export interface IBreadCrumb {
    title: string,
    onClick: () => void
}

export const PanelHeader = observer((props: { title: string, description: string, breadCrumbs?: IBreadCrumb[] }) => {
    const {title, description, breadCrumbs} = props;
    return <div className={'PanelHeader'}>
        {breadCrumbs && breadCrumbs.map((b,i) => {
            return <div className={'breadcrumb'} key={`${i}_${b.title}`} onClick={() => b.onClick()} style={{display: 'inline-block'}}>{b.title} &gt;</div>
        })}
        <h2>{title}</h2>
        <p>{description}</p>
    </div>
});
