import {observer} from 'mobx-react';
import Modal from '@sasaki-dev/react-modal';

import {useDtpStores} from '../..';
import {Button} from '../elements/Button';
import ToolInfo from "./ToolInfo";
import {useState} from "react";
import {Info} from "react-feather";


export default observer(function InfoModal() {
    const {config, ui, user, dataStore} = useDtpStores();
    const [showingGuide, showGuide] = useState(false);

    return (
        <>
            <Modal className={`InfoModal ${ui.infoModalIsOpen ? 'active' : ''}`}
                   onClose={() => ui.setInfoModalOpen(false)}>
                <header>Overview</header>
                {/*<p>documentId: {dataStore.documentId}</p>*/}
                <div className="content">
                    <ul className={"info"}>
                        {config.product && <li>
                            <label>Product</label>
                            <span>{config.product}</span>
                        </li>}

                        {config.project && (<li>
                            <label>Project</label>
                            <span>{config.project}</span>
                        </li>)}

                        {config.source && (
                            <li>
                                <label>Data Source</label>
                                <span>{config.source}</span>
                            </li>
                        )}

                        {config.contact.startsWith('http') ?
                            <li>
                                <label>Contact</label>
                                <span><a className="button" href={config.contact} target="_blank" rel="noopener noreferrer">
                                    Contact form
                                </a></span>

                            </li>
                            :
                            <li>
                                <label>Contact</label>
                                <span>{config.contact}</span>
                            </li>
                        }
                    </ul>

                    <button className={"more-info"} onClick={() => showGuide(!showingGuide)}>
                        <Info />
                        <span>{showingGuide ? 'Hide' : 'Show'} full information</span>
                    </button>

                    {showingGuide &&
                    <div className="user-guide-scroll">
                        <ToolInfo/>
                    </div>}

                    <div className="modal-buttons">
                        <Button title={`Logout ${user.displayName}`} className="text" onClick={() => user.logout()}/>
                        <Button title='Done' className="primary" onClick={() => ui.setInfoModalOpen(false)}/>
                    </div>
                </div>

            </Modal>

        </>
    );
});
