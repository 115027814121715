import {detach, ExtendedModel, getRoot, Model, model, modelAction, prop, Ref, rootRef} from "mobx-keystone";
import {computed} from "mobx";
import {DataStore, IBuildingTypology, IColoredSharedMix, IGrossTypology, ISurfaceTypology} from "./DataStore";
import {AreaMetric, Metric, TweakableAreaMetric} from "./Metrics";
import {AreaMetricConverter} from "./Units";

export const typologyRef = rootRef<Typology>("dtp/TypologyRef");

@model("dtp/Typology")
export class Typology extends Model({
    id: prop<string>('', {setterAction: true}),
    name: prop<string>('', {setterAction: true}),
    color: prop<string>('', {setterAction: true})

}) {

    @modelAction
    setName(name: string) {
        this["name"] = name;//using 'indexer' access here seems to make TS happy!
    }

    @modelAction
    setColor(color: string) {
        this.color = color;
    }

    remove() {
        detach(this);
    }
}

@model("dtp/TypologyLinked")
export class TypologyLinked extends Model({
    typologyRef: prop<Ref<Typology> | undefined>(() => typologyRef('')),
}) {
    @computed
    get typology(): Typology | undefined {
        return this.typologyRef ? this.typologyRef.maybeCurrent : undefined;
    }

    @modelAction
    setTypology(typology: Typology | undefined) {
        if (!typology) return;
        this.typologyRef = typologyRef(typology);
    }
}


@model("dtp/TypologyMixComponent")
export class TypologyMixComponent extends ExtendedModel(TypologyLinked, {
    shareAmount: prop<number>(0, {setterAction: true}),
}) implements IColoredSharedMix {
    get color() {
        return this.typology?.color || '#000000'
    }
}

@model("dtp/SurfaceTypology")
export class SurfaceTypology extends ExtendedModel(Typology, {
    surfaceAreaMetric: prop<TweakableAreaMetric>(),//sq m
}) implements ISurfaceTypology {

    @computed
    get surfaceArea() {
        return this.surfaceAreaMetric.currentValue
    }

    @computed
    get dataStore() {
        return getRoot(this) as DataStore;
    }

    @computed
    get explodedSurfaceArea() {
        //go through all MixedTypologies and grab all area shares that match this typology
        const {dataStore} = this;
        let area = this.surfaceArea;
        dataStore.typologiesOfType<MixedTypology>(['dtp/MixedTypology']).forEach((mixedTypology: MixedTypology) => {
            mixedTypology.components.forEach((component: TypologyMixComponent) => {
                if (component.typology === this) {
                    area += component.shareAmount * mixedTypology.surfaceArea;
                }
            })
        });
        return area;
    }

    @computed
    get surfaceAreaConversion() {
        return new AreaMetricConverter(this.surfaceAreaMetric, this.dataStore.unitSettings);
    }
}

export enum DensityTypes {
    FAR = "FAR",
    Building = "Building",
    Surface = "Surface",
    Empty = "-",
    DU_Acre = "DU_Acre",
}

@model("dtp/GrossTypology")
export class GrossTypology extends ExtendedModel(SurfaceTypology, {
    grossAreaMetric: prop<TweakableAreaMetric>(() => new TweakableAreaMetric({areaSqM: 100}), {setterAction: true}),
    unitSize: prop<AreaMetric>(() => new AreaMetric({areaSqM: 100}), {setterAction: true}),
}) implements IGrossTypology {

    @computed
    get grossArea() {
        return this.grossAreaMetric.value;
    }
}

export interface IComponentMix {
    components: IColoredSharedMix[]

    addComponent(): void;
}

@model("dtp/GrossTypologyFromDensity")
export class GrossTypologyFromDensity extends ExtendedModel(GrossTypology, {
    densityType: prop<DensityTypes>(DensityTypes.FAR, {setterAction: true}),
    densityValue: prop<Metric>(() => new Metric({value: 1}), {setterAction: true}),
    buildingFloorsBelowGrade: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),//pretty specific to building density type - but this allows switching back and forth without destroying anything...

}) implements IGrossTypology, IBuildingTypology {

    @computed
    get grossAreaDisplay() {
        const {dataStore} = this;
        return dataStore.unitSettings.getAreaDisplay(this.grossArea);
    }


    @computed
    get floorsBelowGrade() {
        return this.buildingFloorsBelowGrade.value;
    }

    @computed
    get isBuilding() {
        return this.densityType === DensityTypes.Building;
    }

    @computed
    get totalFloors() {
        return this.densityValue.value;
    }

    @computed
    get grossArea() {
        if (this.grossAreaMetric.overrideEnabled) {
            return this.grossAreaMetric.currentValue;
        }
        if (this.densityType === DensityTypes.DU_Acre) {
            return this.densityValue.value * this.surfaceArea * this.unitSize.value;
        } else {
            return this.densityValue.value * this.surfaceArea;
        }
    }

    @modelAction
    setDensityType(densityType: DensityTypes | undefined) {
        if (!densityType) {
            densityType = DensityTypes.Empty;
        }
        if (typeof densityType === 'string') {
            this.densityType = (<any>DensityTypes)[densityType];
        } else {
            this.densityType = densityType;
        }

    }
}


@model("dtp/MixedTypology")
export class MixedTypology extends ExtendedModel(SurfaceTypology, {
    components: prop<TypologyMixComponent[]>(() => []),
}) implements IComponentMix {

    @modelAction
    addTypologyComponent(typology: Typology, shareAmount: number) {
        const mixComponent = new TypologyMixComponent({shareAmount});
        mixComponent.setTypology(typology);
        this.components.push(mixComponent);
    }

    @modelAction
    addComponent(): void {
        //TODO this is called when we push the 'add' button - but where should we choose the typology to use?
        //for now, it just creates a component without a typology, which we could select and assign...?
        const mixComponent = new TypologyMixComponent({shareAmount: 0});
        this.components.push(mixComponent);
    }
}
