import React, {Component} from "react";
import {observer} from "mobx-react";

type Line = { points: { x: number, y: number }[], isSelected: boolean, colorOverride?: string, zIndex: number, onClick?: () => void };
type Label = { x: number, y: number, text: string, color?: string, zIndex?: number };
type ParallelPlotProps = {
    data: any[],
    fields: any[],
    joinRanges: any[],
    width: number,
    height: number,
    yAxisLabels?: { top?: string, bottom?: string },
    onLineClick?: (record: any) => void
};
export const ParallelPlot = observer((props: ParallelPlotProps) => {
    const {data, fields, joinRanges, width, height, onLineClick, yAxisLabels} = props;
    const calculatePlots = () => {
        const padding = {top: 20, bottom: 30, right: 20, left: 30};
        const ranges: any = {};
        fields.forEach(field => {
            ranges[field] = {};
        })
        data.forEach(({record}, i) => {
            fields.forEach(field => {
                const val = record[field];
                if (val !== undefined) {
                    if (ranges[field].min === undefined) {
                        ranges[field].min = val;
                    } else {
                        ranges[field].min = Math.min(ranges[field].min, val);
                    }
                    if (ranges[field].max === undefined) {
                        ranges[field].max = val;
                    } else {
                        ranges[field].max = Math.max(ranges[field].max, val);
                    }
                }

            })
        });

        joinRanges.forEach((joinSet, i) => {
            const setRange: any = {};
            joinSet.forEach((field: string, i: number) => {
                if (setRange.min === undefined) {
                    setRange.min = ranges[field].min;
                } else {
                    setRange.min = Math.min(setRange.min, ranges[field].min);
                }
                if (setRange.max === undefined) {
                    setRange.max = ranges[field].max;
                } else {
                    setRange.max = Math.max(setRange.max, ranges[field].max);
                }
                ranges[field] = setRange;
            });
        });

        const fmt = (v: number) => {
            if (!v) return '-'
            return v.toFixed(2);//match display in LandUse.js
        }

        const lines: Line[] = [];
        const labels: Label[] = [];

        const stepX = (j: number) => {
            return padding.left + (j - 0.5) * step
        };

        const chartWidth = width - padding.left - padding.right;
        const chartHeight = height - padding.top - padding.bottom;
        const step = chartWidth / fields.length;
        fields.forEach((field, j) => {
            let x = stepX(j);
            labels.push({x, y: height - 5, text: field});
            labels.push({x, y: height - 30, text: fmt(ranges[field].min)});
            labels.push({x, y: 20, text: fmt(ranges[field].max)});
        });

        if (yAxisLabels) {
            if (yAxisLabels.top) {
                labels.push({x: stepX(fields.length), y: 30, text: yAxisLabels.top});
            }
            if (yAxisLabels.bottom) {
                labels.push({x: stepX(fields.length), y: height - 30, text: yAxisLabels.bottom});
            }
        }

        data.forEach(({record, isSelected, colorOverride}, i) => {
            const line: Line = {points: [], isSelected, colorOverride, zIndex: isSelected ? 1 : 0};
            fields.forEach((field, j) => {
                const val = record[field];
                if (val != undefined) {
                    const valAlong = (val - ranges[field].min) / (ranges[field].max - ranges[field].min);
                    let pos = {
                        x: stepX(j),
                        y: padding.top + chartHeight - valAlong * chartHeight
                    };
                    if (onLineClick) {
                        line.onClick = () => {
                            onLineClick(record)
                        };
                    }
                    line.points.push(pos);
                    if (isSelected) {
                        labels.push({x: pos.x, y: pos.y - 10, text: fmt(val), color: 'red', zIndex: 1});
                    }
                }

            });

            lines.push(line);
        });

        let compareZIndex = (a: Line | Label, b: Line | Label) => (a.zIndex || 0) - (b.zIndex || 0);
        lines.sort(compareZIndex);
        labels.sort(compareZIndex);

        return {lines, ranges, labels};
    }

    const {lines, ranges, labels} = calculatePlots();
    const strokeColor = (colorOverride: string | undefined, isSelected: boolean) => {
        if (colorOverride) {
            return colorOverride;
        }
        return isSelected ? 'red' : 'gray'
    }
    return (<div className="ParallelPlot">
        <svg width={width} height={height} viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg">
            {lines.map(({points, colorOverride, isSelected, onClick}, i) =>
                <polyline className="plot-line" key={`${i}`}
                          onClick={() => {
                              if (onClick) onClick();
                          }}
                          fill={'none'}
                          stroke={strokeColor(colorOverride, isSelected)}
                          strokeWidth={isSelected ? 2 : 3}
                          strokeOpacity={isSelected ? 1 : 0.3}
                          points={points.map(pt => pt.x + ',' + pt.y).join(' ')}/>)}
            {labels.map((label, i) => <text key={`${i}_${label.text}`} x={label.x} y={label.y}
                                            textAnchor={'middle'}
                                            fill={label.color || "#000000"} fontSize="14px">{label.text}
            </text>)}
        </svg>
    </div>)

});
