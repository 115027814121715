import { createContext, useContext } from 'react';

import {IMainStore} from "./stores/MainStore";
import {setupConnections} from "./dtpApp/connections";
import {setupAnalytics} from "./utils/analytics";

let _context: any;

// export function bootstrap(firebaseConfig: FirebaseConfig, main: IMainStore) {
export function bootstrap(main: IMainStore) {
    _context = createContext(main);

    setupAnalytics();
    setupConnections(main);
}

export function useDtpStores():IMainStore {
    return useContext(_context);
}
