import {ExtendedModel, getRoot, Model, model, modelAction, prop} from "mobx-keystone";
import {computed} from "mobx";
import {Simulation, SimulationInput} from "../stores/ks/Simulation";
import {CostMetric} from "../stores/ks/Metrics";
import {GrossTypology, SurfaceTypology, Typology} from "../stores/ks/Typologies";
import {DataStore} from "../stores/ks/DataStore";
import {LabelledNumericInput, NumericInput} from "../ui/NumericInput";
import {observer} from "mobx-react";
import React from "react";
import {Color} from "chart.js/types/color";
import {FontSpec} from "chart.js";
import {BarChart, BarChartItem} from "../ui/charts/BarChart";
import {ChartSection} from "./EmbodiedCarbonSimulation";

@model("dtp/CostSimulation")
export class CostSimulation extends ExtendedModel(Simulation, {
    costInputs: prop<CostInput[]>(() => []),
}) {

    @modelAction
    addInput(typology: Typology, value: number): void {
        let costInput = new CostInput({
            cost: new CostMetric({value})
        });
        costInput.setTypology(typology);
        this.costInputs.push(costInput);
    }

    @modelAction
    setInput(typology: Typology, costUSD: number): void {
        const linkedInput = this.costInputs.find(input => input.typology === typology);
        if (linkedInput) {
            linkedInput.cost.value = costUSD;
        }
    }

    @computed
    get id(): string {
        return 'COST'
    }

    @computed
    get name(): string {
        return 'Cost Model'
    }

    renderTypologyInput(typology: Typology): JSX.Element | null {
        const dataStore = getRoot(this) as DataStore;

        const linkedInput = this.costInputs.find(input => input.typology === typology);
        const grossTypology = typology as GrossTypology;
        if (!linkedInput || !grossTypology) {
            return null;
        }
        return <LabelledNumericInput label={`${dataStore.unitSettings.currencyUnits} / ${dataStore.unitSettings.areaUnits}`} metric={linkedInput.cost}/>
    }

    renderOutputs(): JSX.Element | null {
        const dataStore = getRoot(this) as DataStore;
        if (!dataStore || this.costInputs.length === 0) {
            return null;
        }
        let costData: BarChartItem[] = [];
        const unit = dataStore.unitSettings.currencyUnits;
        let totalCost = 0;
        dataStore.typologiesOfType<GrossTypology>(['dtp/GrossTypology', 'dtp/GrossTypologyFromDensity']).map((grossTypology) => {
            const linkedInput = this.costInputs.find(input => input.typology === grossTypology);
            if (!linkedInput) return;
            let costVal = linkedInput.calculateCost(grossTypology.grossArea)
            if (costVal === 0) return;
            costData.push({color: grossTypology.color, label: grossTypology.name, value: costVal});
            totalCost += costVal;
        });

        const formatVal = (value: number) => {
            if (value > 1000000) {
                return unit + Math.round(100 * value / 1000000) / 100 + 'M';
            }
            if (value > 1000) {
                return unit + Math.round(100 * value / 1000) / 100 + 'K';
            }
            return unit + value;
        }


        return <div>
            <div className="CostOutput">
                <ChartSection title={'Cost'} total={`${formatVal(totalCost)}`}>
                    <BarChart data={costData} format={formatVal} height={480}/>
                </ChartSection>
            </div>
        </div>
    }
}

@model("dtp/CostInput")
export class CostInput extends ExtendedModel(SimulationInput, {
    cost: prop<CostMetric>()
}) {
    calculateCost(surfaceArea: number) {
        return this.cost.value * surfaceArea;
    }
}




