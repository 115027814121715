import './index.scss';
import { bootstrap } from '../../core';
import MainStore from "../../core/stores/MainStore";
import {App} from "./App";
import config from './config';
import {addSimpleStreams} from "./simpleStreams";
import {DataStore} from "../../core/stores/ks/DataStore";

const main = new MainStore(config, {}, (dataStore:DataStore) => {
    addSimpleStreams(dataStore);
});

main.layout.setRightPanelSize(295, 0.15);

// bootstrap(firebaseConfig, { main });
bootstrap(main);

export default App;
