import {useCallback, useState} from 'react';
import {observer} from 'mobx-react';
import {useDtpStores} from '../..';

import {Button} from '../elements/Button';
import {validateEmail} from '../../utils/utils';
import SasakiLogo from '../assets/sasaki.svg';
import {ChevronRight, Info, HelpCircle} from 'react-feather';

import ProjectDataScreen from '../assets/project-data-screen.png';
import TimelineScreen from '../assets/timeline-screen.png';
import DataScreen from '../assets/data-screen.png';
import ToolInfo from "../InfoModal/ToolInfo";


let timeout: any;

const LoginMechanics = observer(()=> {
    const {config, user} = useDtpStores();
    const [form, setForm] = useState('login');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [created, setCreated] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const checkPassword = useCallback((_password, _passwordConfirm) => {
        setPasswordError(false);
        if (_password !== password) {
            setPassword(_password);
        }
        if (_passwordConfirm !== passwordConfirm) {
            setPasswordConfirm(_passwordConfirm);
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (_password !== '' && _passwordConfirm !== '') {
            timeout = setTimeout(() => {
                if (_password !== _passwordConfirm) {
                    setPasswordError(true);
                }
            }, 750);
        }
    }, [password, passwordConfirm]);

    const create = useCallback(() => {
        setLoggingIn(true);

        if (user.whitelist.length > 0 && user.whitelist.indexOf(email.toLowerCase()) === -1) {
            setForm('invalid');
            setEmail('');
            setPassword('');
            setLoggingIn(false);
        } else {
            user.create(name, email, password)
                .then(() => setCreated(true))
                .catch(() => {
                    setForm('invalid');
                    setLoggingIn(false)
                });
        }
    }, [email, name, password, user]);

    const login = useCallback(() => {
        setLoggingIn(true);

        if (email.endsWith('@sasaki.com')) {
            user.login();
        } else {
            user.loginWithEmailPassword(email, password).catch(() => {
                setLoginError(true);
                setLoggingIn(false);
            });
        }
    }, [email, password, user]);

    return <div className="LoginMechanics">
        {user.isLoggedIn ? <>
            {created ? <>
                <p>
                    An email has been sent to {email} to verify your account. Please verify your email
                    address before continuing.
                </p>

                <div className="change-form">
                    <Button title="Return to Sign In" className="text" onClick={() => user.logout()}/>
                    &nbsp; or &nbsp;
                    <Button title="Re-send Verification Email" className="text"
                            onClick={() => user.verifyEmail()}/>
                </div>
            </> : <>
                <p>
                    You have not yet verified the email address associated with your account. Please check
                    your inbox for a verification email.
                </p>

                <div className="change-form">
                    <Button title="Re-send Verification Email" className="text"
                            onClick={() => user.verifyEmail()}/>
                    &nbsp; or &nbsp;
                    <Button title="Logout" className="text" onClick={() => user.logout()}/>
                </div>
            </>}
        </> : <>
            {form === 'login' && <>
                <div className={`input-field ${loginError ? 'error' : ''}`}>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="text" value={email} onChange={e => {
                        setEmail(e.target.value);
                        setLoginError(false)
                    }} placeholder="example@domain.com"/>
                </div>

                <div
                    className={`input-field ${validateEmail(email) && !email.endsWith('@sasaki.com') && (email.indexOf('@sasaki.co') === -1 || password.length !== 0) ? '' : 'hidden'} ${loginError ? 'error' : ''}`}>
                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" value={password} onChange={e => {
                        setPassword(e.target.value);
                        setLoginError(false)
                    }} placeholder="********"/>
                    <Button title="Forgot Password?" className="text" onClick={() => setForm('password')}/>
                </div>

                {loginError && (
                    <p className="error">
                        Failed to login with these credentials
                    </p>
                )}

                <Button
                    title={<>Login {email.endsWith('@sasaki.com') && 'with Google'}<ChevronRight/></>}
                    className={`primary ${!(!loggingIn && validateEmail(email) && (email.endsWith('@sasaki.com') || password.length !== 0)) ? 'disabled' : ''}`}
                    disabled={!(!loggingIn && validateEmail(email) && (email.endsWith('@sasaki.com') || password.length !== 0))}
                    onClick={login}
                />
            </>}

            {form === 'create' && <>
                <h2>Create your Carbon Conscience Account</h2>

                <div className="input-field">
                    <label htmlFor="name">Full Name</label>
                    <input id="name" type="text" value={name} onChange={e => setName(e.target.value)}
                           placeholder="First Last"/>
                </div>

                <div className="input-field">
                    <label htmlFor="email">Email</label>
                    <input id="email" type="text" value={email} onChange={e => setEmail(e.target.value)}
                           placeholder="example@domain.com"/>
                </div>

                <div className={`input-field ${passwordError ? 'error' : ''}`}>
                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" value={password}
                           onChange={e => checkPassword(e.target.value, passwordConfirm)}
                           placeholder="********"/>
                </div>

                <div className={`input-field ${passwordError ? 'error' : ''}`}>
                    <label htmlFor="passwordConfirm">Confirm Password</label>
                    <input id="passwordConfirm" type="password" value={passwordConfirm}
                           onChange={e => checkPassword(password, e.target.value)} placeholder="********"/>
                </div>

                {passwordError && (
                    <p className="error">
                        The passwords do not match
                    </p>
                )}

                <Button
                    title={<>Create Account <ChevronRight/></>}
                    className={`primary ${loggingIn || name.length === 0 || !validateEmail(email) || password.length === 0 || password !== passwordConfirm ? 'disabled' : ''}`}
                    disabled={loggingIn || name.length === 0 || !validateEmail(email) || password.length === 0 || password !== passwordConfirm}
                    onClick={create}
                />

                <div className="change-form">
                    Have An Account? <Button title="Sign In" className="text"
                                             onClick={() => setForm('login')}/>
                </div>
            </>}
        </>}

        {form === 'password' && <>
            <h2>Reset your password</h2>
            <p>
                Enter the email address associated with your account and we'll send you a link to reset your
                password.
            </p>

            <div className="input-field">
                <label htmlFor="email">Email</label>
                <input id="email" type="text" value={email} onChange={e => setEmail(e.target.value)}
                       placeholder="example@domain.com"/>
            </div>

            <Button
                title={<>Continue <ChevronRight/></>}
                className={`primary ${!validateEmail(email) ? 'disabled' : ''}`}
                disabled={!validateEmail(email)}
                onClick={() => {
                    user.resetPassword(email);
                    setForm('resetting')
                }}
            />

            <Button title="Return To Sign In" className="text change-form"
                    onClick={() => setForm('login')}/>
        </>}

        {form === 'invalid' && <>
            <p>
                Could not create an account with the email provided. Please contact {config.contact} for
                access.
            </p>

            <Button title="Return To Sign In" className="text change-form"
                    onClick={() => setForm('login')}/>
        </>}

        {form === 'resetting' && <>
            <p>
                If the email you have provided has an account with us, you will be receiving an password
                reset email shortly.
            </p>

            <Button title="Return To Sign In" className="text change-form"
                    onClick={() => setForm('login')}/>
        </>}

        {form === 'login' && !user.isLoggedIn && (
            <div className="change-form">
                Don't Have An Account? <Button title="Sign Up" className='text'
                                               onClick={() => setForm('create')}/>
            </div>
        )}
    </div>
});

const LoginMobileWarning = ()=> {
    return <div className="LoginMobileWarning">
        <p>This product is designed for larger screens. Please visit Carbon Conscience on a larger screen to login and use the tool in its fullest capacity. Thank you!</p>
        <p>To learn more, watch a walk-through of the tool: <a href="https://vimeo.com/sasvideos/carbon-conscience-primer" target={'_blank'} rel={'noreferrer'}>How to Reduce Carbon Impacts from Kickoff</a></p>
    </div>
}

export default observer(function Login({screens}: { screens: any[] }) {
    const [showingGuide, showGuide] = useState(false);

    const {config} = useDtpStores();

    const onScreensRef = useCallback(ref => {
        console.log(ref);
        if (ref) {
            const {width} = ref.getBoundingClientRect();
            ref.style.height = `${width / 1.44}px`;

            const onResize = new ResizeObserver(() => {
                const {width} = ref.getBoundingClientRect();
                ref.style.height = `${width / 1.44}px`;
            });

            onResize.observe(ref);
        }
    }, []);

    return (
        <div className="Login">
            <div className="forms">
                <div className="login-panel">
                    <img className="logo" src={config.logo} alt="Logo"/>
                    <h3>{config.project}</h3>
                    <LoginMobileWarning/>
                    <LoginMechanics/>
                </div>
            </div>

            <div className="landing">
                <div className="screens" ref={onScreensRef}>
                    {screens.map(screen => (
                        <img key={screen.src} src={screen.src} alt={screen.alt}/>
                    ))}
                </div>
            </div>

            <footer>
                <a className={'sasaki-logo'} href="https://www.sasaki.com" target="_blank" rel="noreferrer noopener">
                    <img src={SasakiLogo} alt="Sasaki Logo"/>
                </a>

                <button onClick={() => showGuide(true)}>
                    <Info/>
                    <span>Product Information</span>
                </button>
                <a className="button" href="https://sasakistrategies.typeform.com/carbon" target="_blank"
                   rel="noopener noreferrer">
                    <HelpCircle/>
                    <span>Contact</span>
                </a>
            </footer>

            <div className={`user-guide ${showingGuide ? 'active' : ''}`}>
                <div className='user-guide-gutter' onClick={() => showGuide(false)}/>

                <div className="user-guide-window">
                    <header>Product Information</header>
                    <button className={'close-button'} onClick={() => showGuide(false)}>x</button>
                    <div className="content">
                        <ToolInfo/>
                    </div>
                </div>
            </div>
        </div>
    );
});
