import FirebaseConfig from './FirebaseConfig';


type Config = FirebaseConfig & {
    title: string;
    logo?: string;
    product: string;
    project: string;
    contact: string;
    collection: string;
    introDescription: string;
    source?: string;
    whitelist: string[];
};

export const DefaultConfig: Config = {
    title: 'Design Tools Platform',
    product: 'Design Tools Platform',
    project: 'Design Tools Platform',
    contact: 'strategies@sasaki.com',
    collection: 'dtp',
    introDescription: 'Measure carbon impacts. Build with a conscience.',
    whitelist: [],

    // Firebase
    apiKey: "AIzaSyDKi1b6iQZD5bOMadkNQ3ciaiZVHZ7J98I",
    authDomain: "carbon-conscience.firebaseapp.com",
    projectId: "carbon-conscience",
    storageBucket: "carbon-conscience.appspot.com",
    messagingSenderId: "371436513085",
    appId: "1:371436513085:web:2633c46d0c8d0e3b200754",
    measurementId: "G-SK6PYVPJHS",
};


export default Config;
