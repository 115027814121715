import React from "react";
import {Color} from "chart.js/types/color";
import {FontSpec} from "chart.js";
import {observer} from "mobx-react";
import {Bar} from "react-chartjs-2";

type SimpleAxisOptions = {
    ticks? :any,
    title?: {
        display: boolean;
        text: string | string[];
        color?: Color;
        font?: FontSpec;
        padding?: number | {
            top: number;
            bottom: number;
        };
    };
};

export type BarChartItem = {
    color: string,
    label: string,
    value: number,
    range?: {min: number, max: number}
}

export type SimpleChartOptions = {
    responsive: boolean,
    maintainAspectRatio: boolean,
    indexAxis: 'x' | 'y';//not sure why this is needed: TS not being smart here
    plugins: {
        legend: {
            display: boolean,
        },
        tooltip?: any,
    },
    scales?: {
        x?: SimpleAxisOptions,
        y?: SimpleAxisOptions,
    },
}
export const BarChart = observer((props: { data: BarChartItem[], format: (value: number)=>string, height: number }) => {
    let {data, height, format} = props;

    height = height * 0.75;//seems to be needed to counteract maintainAspectRatio?

    const values = data.map(v=>v.value);
    const labels = data.map(v=>v.label);
    const colors = data.map(v=>v.color);

    const options: SimpleChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        indexAxis: 'y',
        scales: {
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: format
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        let label = context.dataset.label;
                        if (context.parsed.x !== null) {
                            label = format(context.parsed.x);
                        }
                        return label;
                    }
                }
            },
        },
    };

    const costChartData = {
        labels: labels,
        datasets: [
            {
                backgroundColor: colors,
                data: values
            }
        ]
    };

    // console.log('HEIGHT', data.length * 10); -- setting height dynamically results in constant resizing and lock-up
    return <Bar height={height} data={costChartData} options={options}/>
});

export const HighLowStackChart = observer((props: { labels: string[], data: { low: number, high: number }[], colors: string[] }) => {
    const {labels, data, colors} = props;

    const applyAlpha = (hex: string, alpha = 1): string => {
        const red = parseInt(hex.slice(1, 3), 16);
        const green = parseInt(hex.slice(3, 5), 16);
        const blue = parseInt(hex.slice(5, 7), 16);

        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    };
    const zeroArr = (idx: number, val: number, tot: number) => {
        const ans = [];
        for (let i = 0; i < tot; i++) {
            if (i === idx) {
                ans.push(val)
            } else {
                ans.push(0)
            }
        }
        return ans;
    };
    const datasets = [];
    for (let i = 0; i < colors.length; i++) {
        const color = colors[i];
        const {low, high} = data[i];
        const mid = high - low / 2;
        datasets.push({
            label: labels[i],
            data: zeroArr(i, low, colors.length),
            backgroundColor: '#ffffff',
        });
        datasets.push({
            label: labels[i],
            data: zeroArr(i, mid, colors.length),
            backgroundColor: applyAlpha(color, 0.6),
        });
        datasets.push({
            label: labels[i],
            data: zeroArr(i, mid, colors.length),
            backgroundColor: color,
        });
    }

    const labelledData = {
        labels: labels,
        datasets
    };
    const options: any = {
        type: 'bar',
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        }
    };

    console.log('HighLowStackChart', labelledData)
    // console.log('HEIGHT', data.length * 10); -- setting height dynamically results in constant resizing and lock-up
    return <Bar data={labelledData} options={options}/>
});
