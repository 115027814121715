import { observer } from "mobx-react";
import React from "react";
import { useDtpStores } from "../../index";
import QRCode from "react-qr-code";
import manualOverrideIcon from "../assets/calculator.svg";
import designIcon from "../assets/pen-tool.svg";

export const DesignToggleInstructions = ({sendBackText}:{sendBackText:string}) => {
    const width = 20, height = 20;
    return <>
        <div className={'icon-info'}><img src={designIcon} width={width} height={height}/> {sendBackText}
        </div>
        <div className={'icon-info'}><img src={manualOverrideIcon} width={width} height={height}/> You can always choose
            to manually override values if you like.
        </div>
        <div className={'icon-info'}>Click the icon to the left of the area input box to switch between these modes.
        </div>
        <div className={'footnote'}>
            <p>Share responsibly: The links generated in this panel do not require a login. This makes it easy to connect different computers and devices, but only share with those you trust.</p>
        </div>

    </>
}

export const PaintTheMap = observer(() => {
    const mainStore = useDtpStores();
    const { dataStore } = mainStore;
    const { documentId } = dataStore;
    const ptmUrlBase = 'https://maps.sasaki.com/engage/paint/dtp/';
    // const ptmUrlBase = 'http://localhost:63344/MapBoxTiles/canvas-tiles/dtp/index.html';

    //NOTE: we're using documentId for documentId and streamId for simple case

    let url = `${ptmUrlBase}?documentId=${encodeURIComponent(documentId)}&streamId=${encodeURIComponent(documentId)}`;
    return <div className="PaintTheMap">
        <div className="qr-code">
            <QRCode value={url}/>
            <p>👆 snap this on your tablet device for the best painting experience</p>
        </div>

        <p>or open the <a target={'_blank'} href={url}>painting tool</a> in a new browser window</p>
        <DesignToggleInstructions sendBackText={'Once connected, your painted areas will be automatically applied to the matching landuses.'}/>
    </div>;
});

