import {observer} from 'mobx-react';
import Modal from '@sasaki-dev/react-modal';

import {useDtpStores} from '../..';
import {Button} from '../elements/Button';
import {LabelledSelectList} from "../../simulations/TrafficSimulation";
import {DensityTypes} from "../../stores/ks/Typologies";
import React from "react";
import {CURRENCY_UNIT_OPTIONS, MEASUREMENT_UNIT_OPTIONS} from "../../stores/ks/Units";


export default observer(function SettingsModal() {
    const {config, ui, user, dataStore} = useDtpStores();
    const {unitSettings} = dataStore;
    return (
        <Modal className={`SettingsModal ${ui.settingsModalIsOpen ? 'active' : ''}`}
               onClose={() => ui.setSettingsModalOpen(false)}>
            <header>Settings</header>
            {/*<p>documentId: {dataStore.documentId}</p>*/}
            <div className="content">
                <LabelledSelectList label={'Measurement Units'} options={MEASUREMENT_UNIT_OPTIONS}
                                    getter={() => unitSettings.measurementUnits}
                                    setter={(val) => unitSettings.setMeasurementUnits(val)}/>
                <LabelledSelectList label={'Cost Units'} options={CURRENCY_UNIT_OPTIONS}
                                    getter={() => unitSettings.currencyUnits}
                                    setter={(val) => unitSettings.setCurrencyUnits(val)}/>


                <div className="modal-buttons">
                    <Button title='Done' className="primary" onClick={() => ui.setSettingsModalOpen(false)}/>
                </div>
            </div>
        </Modal>
    );
});
