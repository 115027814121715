import {autorun, reaction} from "mobx";
import {Typology} from "../stores/ks/Typologies";
import {DataStore} from "../stores/ks/DataStore";
import {IMainStore} from "../stores/MainStore";
import RealtimeConnection from "./RealtimeConnection";
import {MutableVersion} from "../stores/ks/Streams";

export const setupConnections = (mainStore: IMainStore) => {
    //TODO the old version used a method where we just watched the activeStream and then loaded that when it was activated
    //the new approach allows composites with multiple streams
    //for each stream we need to watch for updates (new versions coming in)
    //and then load those on-demand (or maybe just load them as soon as they arrive)

    //we also need to figure out if we want stream definitions to be serialized into their own firestore collection
    //or whether they can just be included in the document data.
    //the advantage of the former is that streams can be shared between documents
    //documents are also specific to each dashboard, so we'd need them to be separate if we want to re-use the same streams in different dashboards
    // autorun(() => {
    //     // console.log("AUTORUN UPDATE activeStreamId", rootStore.document.activeStreamId);
    //     if (rootStore.document.activeStreamId) {
    //         const activeStream = rootStore.document.activeStream;
    //         // console.log("AUTORUN UPDATE activeStream.currentVersion", activeStream.dataStream.currentVersion);
    //         if (activeStream.dataStream.currentVersion) {
    //             // console.log("AUTORUN UPDATE activeStream.currentVersion.modified", activeStream.dataStream.currentVersion.modified);
    //             dataConnection.applyVersion(activeStream.dataStream.currentVersion)
    //         }
    //     }
    // })

    const onMeasurement = (data: any) => {
        const simpleStream = mainStore.dataStore.getStream('simple-stream')
        if (!simpleStream) return;
        const mutableStream = simpleStream.currentVersion as MutableVersion;
        if (!mutableStream) return;
        mutableStream.setData(data);
    };

    const broadcastUses = (documentId: string, uses: any) => {
        let realtimeConnection: RealtimeConnection;
        // const dataStream = mainStore.document.activeStream.dataStream;
        const ensureConnection = (callback: (v: RealtimeConnection) => void) => {
            if (!documentId) return;
            if (!realtimeConnection) {
                realtimeConnection = new RealtimeConnection(documentId, onMeasurement);
                realtimeConnection.connect(callback);
            } else {
                callback(realtimeConnection);
            }
        }
        ensureConnection((connection) => {
            connection.broadcastUses(uses);
        });
    }

    //debounce because reaction can happen while user is typing or dragging the slider
    let dataToBroadcast: { documentId: string, value: any } | null = null;
    setInterval(() => {
        if (!dataToBroadcast) return;
        broadcastUses(dataToBroadcast.documentId, dataToBroadcast.value);
        dataToBroadcast = null;
    }, 500);

    reaction(() => ({
        schema: '0.1',
        settings: {
            measurementUnits: mainStore.dataStore.unitSettings.measurementUnits
        },
        typologies: mainStore.dataStore.typologies.map(t => {
            return {
                id: t.id,
                name: t.name,
                color: t.color,
                isSelected: false //TODO
            }
        })
    }), (value, r) => {
        // console.log("AUTORUN UPDATE typologies");
        dataToBroadcast = {documentId: mainStore.dataStore.documentId, value}
    });
}
