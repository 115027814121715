import { fontFamily, SvgProps } from "./shared";
import { useDtpStores } from "../../../../core";
import React from "react";

const netCarbonPerSmKgCO2 = (colType: string, vegType: string) => {
    if (colType === 'Reforestation/Ecosystem Restoration') {
        if (vegType === 'Tropical Rainforest') return -46.73;
        if (vegType === 'Prairie Grassland') return -32.92;
        if (vegType === 'Temperate Wetland and Salt Marsh') return -69.17;
        if (vegType === 'Temperate Continental Forest') return -46.12;
    }
    if (colType === 'Preservation') {
        if (vegType === 'Tropical Rainforest') return -22.32;
        if (vegType === 'Prairie Grassland') return -14.36;
        if (vegType === 'Temperate Wetland and Salt Marsh') return -48.42;
        if (vegType === 'Temperate Continental Forest') return -28.26;
    }
    throw new Error(`not supported ${colType} + ${vegType}`);
};

const calcEquivalentSqM = (colType: string, vegType: string, kgCO2: number) => {
    return kgCO2 / netCarbonPerSmKgCO2(colType, vegType) * -1;
};

const displayEquivalent = (colType: string, vegType: string, kgCO2: number, areaUnits: string) => {
    const sqM = calcEquivalentSqM(colType, vegType, kgCO2);
    if (areaUnits === 'ac') {
        return `${Math.round(100 * sqM / 4046.86) / 100} ac`;
    }
    return `${Math.round(100 * sqM / 10000) / 100} ha`;
};

export type EquivalentsProps = SvgProps & {
    kgCO2: number
};
export const Equivalents = (props: EquivalentsProps) => {
    const { kgCO2, ...rest } = props;
    const mainStore = useDtpStores();
    const { dataStore } = mainStore;
    const categoryX = 0;
    const categoryWidth = 280;
    const colWidth = 140;
    const headerHeight = 28;
    const rowHeight = 24;

    const colTypes = [
        'Preservation',
        'Reforestation/Ecosystem Restoration',
    ];
    const vegTypes = [
        'Tropical Rainforest',
        'Prairie Grassland',
        'Temperate Wetland and Salt Marsh',
        'Temperate Continental Forest',
    ];

    return <g {...rest}>
        <text fontFamily={fontFamily} fontSize={'11px'} alignmentBaseline="middle"
              x={categoryX}>{`Equivalent ${dataStore.unitSettings.largeAreaUnits} of:`} </text>

        {colTypes.map((colType, i) =>
            <text key={colType} fontFamily={fontFamily} fontSize={'11px'} alignmentBaseline="middle"
                  x={categoryX + categoryWidth + i * colWidth}>{colType}</text>
        )}
        {vegTypes.map((vegType, i) =>
            <g transform={`translate(0, ${headerHeight + rowHeight * i})`}>

                <text key={vegType} fontFamily={fontFamily} fontSize={'11px'} alignmentBaseline="middle"
                      x={categoryX}>{vegType}</text>

                {colTypes.map((colType, i) =>
                    <text key={colType} fontFamily={fontFamily} fontSize={'11px'} alignmentBaseline="middle"
                          x={categoryX + categoryWidth + i * colWidth}>{displayEquivalent(colType, vegType, kgCO2, dataStore.unitSettings.largeAreaUnits)}</text>
                )}
            </g>
        )}

    </g>
};
