import React from 'react';
import ReactDOM from 'react-dom';

import Skin from './skins/carbon-conscience';
// import Skin from './skins/cc-hellenikon';


ReactDOM.render(
    <React.StrictMode>
        <Skin />
    </React.StrictMode>,
    document.getElementById('root')
);
