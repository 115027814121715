import {ExtendedModel, Model, model, modelAction, prop} from "mobx-keystone";
import {computed} from "mobx";
import {DataStore, IColoredSharedMix} from "./DataStore";

import {
    GrossTypologyFromDensity, IComponentMix,
    MixedTypology,
    SurfaceTypology, Typology, TypologyLinked,
} from "./Typologies";

import {CostMetric} from "./Metrics";

@model("dtp/Simulation")
export class Simulation extends Model({}) {
    @computed
    get name(): string {
        return 'unnamed Simulation'
    }

    @computed
    get id(): string {
        throw new Error('no unique id for this simulation')
    }

    renderTypologyInput(typology: Typology): JSX.Element | null {
        return <div>Override required for {this.name}</div>
    }

    renderOutputs(): JSX.Element | null {
        return <div>Override required for {this.name}</div>
    }
}

@model("dtp/SimulationInput")
export class SimulationInput extends ExtendedModel(TypologyLinked, {}) {
}


@model("dtp/MixComponent")
export class MixComponent extends Model({
    shareAmount: prop<number>(1, {setterAction: true}),
}) implements IColoredSharedMix {
    get color() {
        return '#ffffff';
    }
}


@model("dtp/MixedComponentInput")
export class MixedComponentInput extends ExtendedModel(SimulationInput, {
    components: prop<MixComponent[]>(() => []),
}) implements IComponentMix {
    @modelAction
    addComponent() {
        throw new Error(`addComponent should be implemented in subclass: ${this.$modelType}`);
    }

    redistributeComponents() {
        const total = this.components.reduce((sum, component) => sum + component.shareAmount, 0);
        this.components.forEach((component, i) => {
            component.shareAmount = component.shareAmount / total;
        });
    }

    componentsOfType<T extends MixComponent>(modelTypes: string[]): T[] {//NOTE: couldn't find an elegant solution with generics only - this feels hacky, but TypeScript generics are limited
        const ans: T[] = [];
        this.components.forEach((component: MixComponent) => {
            const t = component as T;
            if (modelTypes.indexOf(component.$modelType) > -1) {
                ans.push(t);
            }
        })
        return ans;
    }
}
