export const stripModelIds = (snapshot:any) => {
    if (snapshot && snapshot.$modelId) {
        delete snapshot.$modelId;
    }

    if( snapshot !== null && typeof snapshot == "object" ) {
        Object.entries(snapshot).forEach(([key, value]) => {
            // key is either an array index or object key
            stripModelIds(value);
        });
    }

    return snapshot;
};

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function randomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
