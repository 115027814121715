import Config, { DefaultConfig } from '../../core/types/Config';
import logo from './assets/logo.svg';

const config: Config = {
    ...DefaultConfig,

    logo,
    title: 'Carbon Conscience',
    product: '',
    project: 'Carbon Conscience',
    contact: 'https://sasakistrategies.typeform.com/carbon',
    collection: 'Carbon Conscience',
};

export default config;
