import { observer } from "mobx-react";
import { useDtpStores } from "../../../../core";
import { EmbodiedCarbonSimulation } from "../../../../core/simulations/EmbodiedCarbonSimulation";
import React, { useState } from "react";
import Modal from '@sasaki-dev/react-modal';
import { Button } from "../../../../core/ui/elements/Button";
import { LabelledNumericInput } from "../../../../core/ui/NumericInput";
import { BarChartItem } from "../../../../core/ui/charts/BarChart";
import print from 'print-js'
import SvgImageDownloader from "../../../../core/utils/SvgImageDownloader";
import { carbonConscienceLogoText, sasakiLogo } from "./assets/pathData";
import { fontFamily, SvgProps } from "./shared";
import { Equivalents } from "./Equivalents";

const svgDownloader = new SvgImageDownloader();



type LollipopChartProps = {
    color: string;
    title?: string;
    value: number;
    barHeight: number;
    scale: number;
    min?: number;
    max?: number;
    showValues?: boolean;
};

const fmt = (v: number) => {
    if (v === 0) return '0';
    return Math.round(v / 1000).toLocaleString();
};

const Banner = (props: SvgProps & { svgWidth: number }) => {
    const { svgWidth, ...rest } = props;

    return <g {...rest}>
        <rect fill={'#F2F5FA'} x={0} width={svgWidth} height={55}/>
        {/*<text fontSize={'28px'} x={75} y={40} fontFamily={'Lustria'} fontWeight={'bold'} fill={'#4a4ae3'}>Carbon Conscience</text>*/}
        <g transform={'translate(15, 5) scale(0.75)'}>
            <path
                d="M 28.608 1.568 C 28.829 1.568 39.973 19.728 39.973 34.878 C 39.973 50.029 29.278 56.432 28.608 56.432 C 27.938 56.432 17.243 50.029 17.243 34.878 C 17.243 19.728 28.387 1.568 28.608 1.568 Z"
                fill="#CCC"/>
            <path
                d="M 28.608 1.568 C 28.829 1.568 39.973 19.728 39.973 34.878 C 39.973 50.029 29.278 56.432 28.608 56.432 C 27.938 56.432 17.243 50.029 17.243 34.878 C 17.243 19.728 28.387 1.568 28.608 1.568 Z"
                fill="rgb(244, 145, 31)" opacity="0.5"/>
            <path
                d="M 11.365 0 C 11.586 0 22.73 18.16 22.73 33.311 C 22.73 48.461 12.035 54.865 11.365 54.865 C 10.695 54.865 -0 48.461 0 33.311 C 0 18.16 11.144 -0 11.365 0 Z"
                transform="translate(31.351 5.486) rotate(30 11.365 27.432)" fill="rgb(251, 97, 62)"
                opacity="0.5"/>
            <path
                d="M 11.365 0 C 11.586 -0 22.73 18.16 22.73 33.311 C 22.73 48.461 12.035 54.865 11.365 54.865 C 10.695 54.865 0 48.461 0 33.311 C -0 18.16 11.144 0 11.365 0 Z"
                transform="translate(3.919 5.486) rotate(-30 11.365 27.432)" fill="rgb(140, 219, 103)"
                opacity="0.5"/>
        </g>
        <g transform={`translate(70, 10) scale(0.7)  `}>
            <path
                d={carbonConscienceLogoText}
                fill={'#4a4ae3'}
                stroke={'#4a4ae3'}/>
        </g>
        <g transform={`translate(${svgWidth - 120}, 10) scale(0.35)  `}>
            <path
                d={sasakiLogo}
                fill={'#a9a8a7'}/>
        </g>
    </g>
};


export const LollipopChart = observer((props: LollipopChartProps & SvgProps) => {
    const { color, value, min, max, scale, barHeight, title, showValues, ...rest } = props;
    if (value === 0) return null;
    const toX = (v: number) => {
        return v * scale;
    };

    const rangeBar = () => {
        if (min === undefined || max === undefined) return null;
        const minX = toX(min);
        const maxX = toX(max);
        return <rect fill={color}
                     rx={barHeight}
                     x={minX}
                     y={-barHeight}
                     opacity={0.4}
                     height={barHeight * 2}
                     width={maxX - minX}
        />

    }

    const radius = barHeight;
    let rightX = toX(value) + radius;
    if (max) rightX = Math.max(rightX, toX(max));
    const valueBoxMargin = 10;

    let valueLabel = !!max && !!min ? `${fmt(min)} - ${fmt(max)} tCO₂` : `${fmt(value)} tCO₂`;
    const valueBoxW = Math.max(40, valueLabel.length * 6 + 20);
    const valueBoxH = barHeight * 3;

    let valueBoxX = value > 0 ? rightX + valueBoxMargin : toX(value) - valueBoxW - valueBoxMargin - radius;
    let labelX = value > 0 ? valueBoxX + valueBoxW + valueBoxMargin : valueBoxX - valueBoxMargin;
    let labelAnchor = value > 0 ? 'start' : 'end';


    return <g {...rest}>
        <rect fill={color} x={Math.min(0, toX(value))} y={-barHeight / 2} height={barHeight}
              width={Math.abs(toX(value))}/>
        <circle fill={color} r={radius} cx={toX(value)} cy={0}/>
        {/*<text x={toX(value)}>{fmt(value)}</text>*/}
        {title &&
            <text fontFamily={fontFamily} fontSize={'11px'} alignmentBaseline="middle" textAnchor={labelAnchor}
                  x={labelX}>{title}</text>}
        {showValues && <g transform={`translate(${valueBoxX},0)`}>
            <rect rx={valueBoxH / 2} fill={'#F2F5FA'} x={0} y={-valueBoxH / 2} height={valueBoxH}
                  width={valueBoxW}/>
            <text fontFamily={fontFamily} fontSize={'11px'} textAnchor="middle" alignmentBaseline="middle"
                  x={valueBoxW / 2}>{valueLabel}</text>
        </g>}
        {rangeBar()}
    </g>
});

type GridProps = {
    color: string;
    textColor: string;
    values: number[];
    height: number;
    scale: number;
};
export const Grid = observer((props: GridProps & SvgProps) => {
    const { color, textColor, values, height, scale, ...rest } = props;
    const toX = (v: number) => {
        return v * scale;
    };

    return <g {...rest}>
        {values.slice(0, 250).map((v, i) => <g key={i}>
            <line x1={toX(v)} x2={toX(v)} y1={0} y2={height} stroke={color}/>
            <text fontFamily={fontFamily} textAnchor="middle" x={toX(v)} y={height + 18}
                  fill={textColor}>{fmt(v)}</text>
        </g>)}
    </g>
});


type MergedElement = { min: number, max: number, embodied: BarChartItem, sequestered: BarChartItem, stored: BarChartItem };

export const SummaryChartModal = observer(() => {
    const [extent, setExtent] = useState(-1);
    const extentMetric = {
        value: extent,
        setValue: (v: number) => setExtent(v)
    }

    const [gridStep, setGridStep] = useState(0);
    const gridStepMetric = {
        value: gridStep,
        setValue: (v: number) => setGridStep(v)
    }
    const mainStore = useDtpStores();
    const { layout, ui, dataStore } = mainStore;

    if (!ui.exportModalIsOpen) return null;
    const carbonSimulation = dataStore.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;

    const {
        embodiedData,
        sequesteredData,
        storedData,
        totals
    } = carbonSimulation.chartElements();

    const netVal = ({ min, max, sequestered, stored }: MergedElement) => {
        return sequestered.value + stored.value + ((max + min) / 2)
    }
    const hasVal = ({ min, max, sequestered, stored }: MergedElement) => {
        return sequestered.value < 0
            || stored.value < 0 || min > 0;
    }

    const mergedElements = embodiedData.map((e, i) => {
        return {
            color: embodiedData[i].color,
            min: embodiedData[i].range?.min || 0,
            max: embodiedData[i].range?.max || 0,
            embodied: embodiedData[i],
            sequestered: sequesteredData[i],
            stored: storedData[i],
        };
    }).filter(hasVal);

    mergedElements.sort((a, b) => netVal(a) - netVal(b));

    const unitSize = 1000;//tCO₂

    let useExtent = extent;
    if (useExtent === -1) {
        let maxEither = 0;

        mergedElements.forEach(({ color, min, max, embodied, sequestered, stored }, i) => {
            maxEither = Math.max(maxEither, Math.max(max, Math.abs(sequestered.value + stored.value)));
        });
        if (maxEither > 0) {
            useExtent = Math.ceil(maxEither / unitSize);
            setExtent(useExtent)
        }
    }

    const totalRowHt = 40;
    const elemHt = 28;
    const height = Math.max(30 + mergedElements.length * elemHt, 300);

    const chartWidth = 950;
    const paddingX = 150;

    const boxesAndTextW = 160;//approximation to allow space for labels and numbers
    const scale = (chartWidth / 2 - boxesAndTextW) / (useExtent * unitSize);

    const gridPositions = [];
    if (gridStep > 0) {
        for (let i = gridStep; i <= extent; i += gridStep) {
            gridPositions.push(i * unitSize);
            gridPositions.push(-i * unitSize);
        }
    }

    // const svgWidth =

    let svgWidth = chartWidth + paddingX;
    let svgHeight = height + 500;

    const totalCO2 = totals.sequestered + totals.stored + ((totals.embodiedLow + totals.embodiedHigh) / 2);
    return <Modal className={`SummaryChart InfoModal ${ui.exportModalIsOpen ? 'active' : ''}`}
                  onClose={() => ui.setInfoModalOpen(false)}>
        <header>Carbon Impact Summary Chart</header>
        {/*<p>documentId: {dataStore.documentId}</p>*/}
        <div className="content">
            <div id={'print'} className={'print-preview'} style={{ width: svgWidth }}>
                <svg id={'summary-chart-svg'} viewBox={`0 0 ${svgWidth} ${svgHeight}`} width={svgWidth}
                     height={svgHeight}>

                    <rect fill={'#ffffff'} x={0} width={svgWidth} height={svgHeight}/>
                    <Banner svgWidth={svgWidth} transform={`translate(0,0)`}/>

                    <g transform={`translate(${svgWidth / 2},120)`}>
                        <text fontFamily={fontFamily} fontSize="18px" textAnchor="start" alignmentBaseline="baseline"
                              x={-chartWidth / 2} y={-14}>{'Stored & Sequestered Carbon'}</text>
                        <text fontFamily={fontFamily} fontSize="18px" textAnchor="end" alignmentBaseline="baseline"
                              x={chartWidth / 2} y={-14}>{'Emitted Carbon'}</text>
                        <line x1={-chartWidth / 2} x2={chartWidth / 2} y1={0} y2={0} stroke={'#333333'}/>
                        <Grid height={height} values={gridPositions} scale={scale} color={'#e0e0e0'}
                              textColor={'#cccccc'}/>
                        {mergedElements.map(({ color, min, max, embodied, sequestered, stored }, i) => <g key={i}
                                                                                                          transform={`translate(0,${30 + i * elemHt})`}>
                            {sequestered.value + stored.value < 0 &&
                                <LollipopChart showValues={true} title={embodied.label} barHeight={8}
                                               color={embodied.color}
                                               scale={scale}
                                               value={sequestered.value + stored.value}/>}
                            <LollipopChart showValues={true} title={embodied.label} barHeight={8} min={min} max={max}
                                           color={embodied.color}
                                           scale={scale}
                                           value={(max + min) / 2}/>
                            <LollipopChart opacity={0.35} barHeight={2} color={'#000000'}
                                           scale={scale}
                                           value={sequestered.value + stored.value + ((max + min) / 2)}/>
                            {/*<circle cx={toX()} fill={'#000000'} r={3}/>*/}
                        </g>)}
                        <line x1={0} x2={0} y1={0} y2={height} stroke={'#333333'}/>
                        <line x1={-chartWidth / 2} x2={chartWidth / 2} y1={height} y2={height} stroke={'#333333'}/>
                        <rect x={-chartWidth / 2 - totalRowHt / 2} y={height + 30} rx={totalRowHt / 2} fill={'#F2F5FA'}
                              height={totalRowHt}
                              width={chartWidth + totalRowHt}/>

                        <text fontFamily={fontFamily} fontSize="14px" textAnchor="start" alignmentBaseline="middle"
                              x={-chartWidth / 2}
                              y={height + 30 + totalRowHt / 2}>{`${fmt(-totals.stored)} tCO₂ seq. + ${fmt(-totals.sequestered)} tCO₂ stored = ${fmt(-(totals.sequestered + totals.stored))} tCO₂`}</text>
                        <text fontFamily={fontFamily} fontSize="14px" textAnchor="middle" alignmentBaseline="middle"
                              x={0}
                              y={height + 30 + totalRowHt / 2}>{`${fmt(totalCO2)} tCO₂ net`}</text>
                        <text fontFamily={fontFamily} fontSize="14px" textAnchor="end" alignmentBaseline="middle"
                              x={chartWidth / 2}
                              y={height + 30 + totalRowHt / 2}>{`${fmt(totals.embodiedLow)} - ${fmt(totals.embodiedHigh)} tCO₂ emitted`}</text>
                        <Equivalents transform={`translate(-240,${height + 100})`} kgCO2={totalCO2}/>
                    </g>
                </svg>
            </div>
            <div className={'options'}>
                <LabelledNumericInput label={'Chart Extents (tCO₂)'} metric={extentMetric}
                                      range={{ min: 0, max: 100000 }}/>
                <LabelledNumericInput label={'Grid Step (tCO₂)'} metric={gridStepMetric}
                                      range={{ min: 0, max: 10000 }}/>
            </div>
            <div className="modal-buttons">
                <div className={"action-buttons"}>
                    <Button title='Print' className={'secondary'} onClick={() => {
                        print({
                            documentTitle: 'Carbon Conscience Summary',
                            printable: 'print',
                            type: 'html',
                            maxWidth: svgWidth
                        });
                    }}/>
                    <Button title='Download SVG' className={'secondary'} onClick={() =>
                        svgDownloader.downloadSVG('#summary-chart-svg', 'carbon summary-chart')
                    }/>
                    <Button title='Download PNG' className={'secondary'} onClick={() =>
                        svgDownloader.downloadSVGAsPNG('#summary-chart-svg', 'carbon summary-chart', 2.5)
                    }/>
                </div>
                <div className="space"/>
                <Button title='Done' className="primary" onClick={() => ui.setExportModalOpen(false)}/>
            </div>
        </div>
    </Modal>
});
