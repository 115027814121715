import {observer} from "mobx-react";
import {ITweakable} from "../stores/ks/DataStore";
import {IMetric} from "../stores/ks/Metrics";
import React from "react";
import {getUid} from "./helpers";

export interface IRange {
    min: number;
    max: number;
}

export const NumericInput = observer(({metric, id, range}: { metric: IMetric, id?: string , range?:IRange}) => {

    return <>
        <input id={id} className="NumericInput"
               type="number"
               value={metric.value}
               min={range && range.min}
               max={range && range.max}
               onChange={(e) => {
                   let value = parseFloat(e.target.value);
                   metric.setValue(value);
               }}
        />
    </>;
});

export const NumericReadOnly = observer(({
                                             metric,
                                             id,
                                             format
                                         }: { metric: ITweakable, id?: string, format?: (v: number) => string }) => {
    format = format || (v => v.toLocaleString());
    return <>
        <input id={id} className="NumericInput"
               type="text"
               value={format(metric.currentValue)}
               disabled={true}
               onChange={(e) => {
               }}
        />
    </>;
});

export const LabelledNumericInput = observer(({metric, label, range}: { metric: IMetric, label: string, range?:IRange }) => {
    const uid = getUid();
    return <div className={'entry'}>
        <label htmlFor={`below_${uid}`}>{label}</label>
        <NumericInput id={`below_${uid}`} metric={metric} range={range}/>
    </div>;
});

export const NumericOverrideInput = observer(({tweakable, id, range}: { tweakable: ITweakable, id?: string, range?:IRange }) => {
    return <>
        <input id={id} className="NumericInput"
               type="number"
               value={tweakable.overrideValue}
               min={range && range.min}
               max={range && range.max}
               onChange={(e) => {
                   let value = parseFloat(e.target.value);
                   tweakable.setOverrideValue(value);
               }}
        />
    </>;
});
