import {action, computed, observable} from "mobx";
import {IRect, ISize} from "../types/IRect";
import {IMainStore} from "./MainStore";
import {EditModes} from "./UiStore";

export default class LayoutStore {
    @observable width: number = 800;
    @observable height: number = 600;

    @observable toolbarHeight: number = 65;//Match toolbarHeight in scss
    @observable outputsPanelMinWidth: number = 400;
    @observable outputsPanelWidthFrac: number = 0.333;

    @observable rightPanelMinWidth: number = 350;
    @observable rightPanelWidthFrac: number = 0.2;

    private mainStore: IMainStore;

    constructor(mainStore:IMainStore) {
        this.mainStore = mainStore;
        window.addEventListener('resize', () => {
            this.setSize({width: window.innerWidth, height: window.innerHeight});
        });
        this.setSize({width: window.innerWidth, height: window.innerHeight});
    }

    @action
    setRightPanelSize(minWidth:number, frac:number) {
        this.rightPanelMinWidth = minWidth;
        this.rightPanelWidthFrac = frac;
    }

    @computed
    get toolBar(): IRect {
        return {
            x: 0, y: 0, width: this.width, height: this.toolbarHeight
        }
    }

    @computed
    get outputsPanel(): IRect {
        let width = this.outputsPanelWidthFrac * this.width;
        width = Math.max(width, this.outputsPanelMinWidth);
        return {
            x: 0, y: this.toolBar.height, width: width, height: this.height - this.toolBar.height
        }
    }

    @computed
    get landusePanel(): IRect {
        let width = this.width - this.outputsPanel.width - this.streamsPanel.width;
        return {
            x: this.outputsPanel.width, y: this.toolBar.height, width: width, height: this.height - this.toolBar.height
        }
    }

    @computed
    get landusePanelCentered() {
        //only show panel on right (and landusePanel as 'centered') if we're not in edit mode
        return this.mainStore.ui.editMode === EditModes.None
    }

    @computed
    get streamsPanel(): IRect {
        let width = this.rightPanelWidthFrac * this.width;
        if (this.landusePanelCentered) {
            width = Math.max(width, this.rightPanelMinWidth);
        } else {
            width = 0;
        }
        return {
            x: this.width - width, y: this.toolBar.height, width: width, height: this.height - this.toolBar.height
        }
    }

    @action
    setSize(size: ISize) {
        this.width = size.width;
        this.height = size.height;
    }
}
