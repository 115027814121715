import {ExtendedModel, Model, model, modelAction, prop} from "mobx-keystone";
import {action, computed} from "mobx";
import {ITweakable} from "./DataStore";
import {AreaMetric, IMetric, TweakableAreaMetric} from "./Metrics";

export const CONVERT_SQ_M_TO_SQ_FT = 10.7639;
export const CONVERT_SQ_M_TO_ACRE = 1 / 4046.86;
export const CONVERT_M_TO_FT = 3.28084;

export const MEASUREMENT_UNIT_OPTIONS = ['Meters', 'Feet'];

const USD = '$'; // Dollar
const CNY = '¥'; // Chinese Yuan
const EUR = '€'; // Euro
const GBP = '£'; // British Pound Sterling
const INR = '₹'; // Indian Rupee
const JPY = '¥'; // Japanese Yen
const KRW = '₩'; // South Korean Won
const PHP = '₱'; // Philippine Peso
const VND = '₫'; // Vietnamese Dong
const CRC = '₡'; // Costa Rican Colón

export const CURRENCY_UNIT_OPTIONS = [
    USD,
    // CNY,
    EUR,
    GBP,
    INR,
    JPY,
    VND,
    KRW,
    PHP,
    CRC,
];

@model("dtp/UnitSettings")
export class UnitSettings extends Model({
    measurementUnits: prop<string>('Meters', {setterAction: true}),
    currencyUnits: prop<string>('$', {setterAction: true}),
}) {
    @modelAction
    setCurrencyUnits(value: string): void {
        this.currencyUnits = value;
    }

    @modelAction
    setMeasurementUnits(value: string): void {
        this.measurementUnits = value;
    }

    getAreaDisplay(area: number): string {
        if (this.measurementUnits === 'Feet') {
            area = area * CONVERT_SQ_M_TO_SQ_FT;
        }
        return Math.round(area).toLocaleString() + ' ' + this.areaUnits;
    }

    @computed
    get areaUnits() {
        if (this.measurementUnits === 'Feet') {
            return 'sq. ft.';
        }
        return 'sq. m';
    }

    @computed
    get largeAreaUnits() {
        if (this.measurementUnits === 'Feet') {
            return 'ac';
        }
        return 'ha';
    }

    @computed
    get linearUnits() {
        if (this.measurementUnits === 'Feet') {
            return 'ft';
        }
        return 'm';
    }
}

//this is a simple wrapper class for an AreaMetric that allows the user to input a number in one unit system and have it
//be stored in another system - e.g. to let the user enter things in sq.ft.
export class AreaMetricConverter implements ITweakable {
    private metric: TweakableAreaMetric;
    private unitSettings: UnitSettings;
    private decimalPlaces: number;

    constructor(metric: TweakableAreaMetric, unitSettings: UnitSettings, decimalPlaces: number = 2) {
        this.metric = metric;
        this.unitSettings = unitSettings;
        this.decimalPlaces = decimalPlaces;
    }

    displayValue(area: number) {
        if (this.unitSettings.measurementUnits === 'Feet') {
            return this.toPrecision(area * CONVERT_SQ_M_TO_SQ_FT);
        }
        return area;
    }

    internalizeValue(area: number) {
        if (this.unitSettings.measurementUnits === 'Feet') {
            return area / CONVERT_SQ_M_TO_SQ_FT;
        }
        return area;
    }

    @computed
    get currentValue() {
        return this.displayValue(this.metric.currentValue);
    }

    @computed
    get rawValue() {
        return this.displayValue(this.metric.value);
    }

    @computed
    get overrideEnabled() {
        return this.metric.overrideEnabled;
    }

    @computed
    get overrideValue() {
        return this.displayValue(this.metric.overrideAreaSqM);
    }

    @action
    setOverrideEnabled(enabled: boolean): void {
        this.metric.setOverrideEnabled(enabled);
    }

    @action
    setOverrideValue(value: number): void {
        this.metric.setOverrideValue(this.internalizeValue(value));
    }

    //Note: precision must be used to avoid floating point errors that break the UX when typing numbers and displaying conversions
    private toPrecision(number: number) {
        const multiplier = Math.pow(2, this.decimalPlaces);
        return Math.round(number * multiplier) / multiplier;
    }
}
