import {observer} from "mobx-react";
import React from "react";
import {useDtpStores} from "../../../core";
import {fixedRectStyle} from "../../../core/ui/helpers";
import {Simulation} from "../../../core/stores/ks/Simulation";
import {PanelHeader} from "../../../core/ui/elements/PanelHeader";

export const SimulationOutputs = observer(() => {
    const {dataStore} = useDtpStores();

    return <div className={'SimulationOutputs'}>
        {dataStore.simulations.map((simulation: Simulation) => {
            return <div key={simulation.id} className={'section'}>
                {/*<div>{simulation.name}</div>*/}
                {simulation.renderOutputs()}
            </div>
        })}
    </div>
});

export const OutputsPanel = observer(() => {
    const {layout} = useDtpStores();
    return <div style={fixedRectStyle(layout.outputsPanel)} className={'OutputsPanel'}>
        <div className={'panelOuter left'}>
            <PanelHeader title={'Metrics'}
                         description={'View carbon impacts update as you test landuse assumptions. Carbon units (tCO₂) are tonnes of CO₂ equivalent.'}/>
            <div className={'panelInner'}>
                <SimulationOutputs/>
            </div>
        </div>
    </div>
});
