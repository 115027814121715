import {observer} from "mobx-react";
import {useDtpStores} from "../../../../core";
import {
    CarbonInputComponent,
    CarbonUseCard, CarbonUseCardTitles,
    EmbodiedCarbonSimulation
} from "../../../../core/simulations/EmbodiedCarbonSimulation";
import {fixedRectStyle} from "../../../../core/ui/helpers";
import {PanelHeader} from "../../../../core/ui/elements/PanelHeader";
import {CompareCategoryOptions} from "./CompareCategoryOptions";
import {ApplyCancel} from "../../../../core/ui/elements/ApplyCancel";
import {EditModes} from "../../../../core/stores/UiStore";
import React from "react";
import {editLanduseBack, landuseManagerBack} from "../LandusePanel";
import {GrossTypologyFromDensity} from "../../../../core/stores/ks/Typologies";

export const EditComponent = observer(() => {
    const mainStore = useDtpStores();
    const {layout, ui, dataStore} = mainStore;
    const {activeComponent, activeTypology} = ui;

    const carbonInputComponent = activeComponent as CarbonInputComponent;

    if (!carbonInputComponent) return null;

    const {selection} = carbonInputComponent;

    const carbonSimulation = dataStore.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;
    if (!carbonSimulation) return null;

    return <>
        <PanelHeader breadCrumbs={[landuseManagerBack(ui), editLanduseBack(ui)]} title={'Select Material'}
                     description={'Choose the material for this component'}/>
        <div className={'panelInner'}>
            <div className="editComponentInner">
                <div className="header">

                </div>
                <div className="column">
                    <h2>{activeTypology?.name}</h2>
                    {selection.tier1Selection && (selection.comparisonItem || selection.matchingItem) &&
                    <CarbonUseCardTitles type={selection.tier1Selection}/>
                    }
                    {selection.comparisonItem &&
                    <div>Previously applied:
                        <CarbonUseCard value={selection.comparisonItem} classOverride={'previous'}
                                       isSelected={false}
                                       onClick={() => {
                                       }}/>
                    </div>
                    }
                    {selection.matchingItem &&
                    <div>Currently applied:
                        <CarbonUseCard value={selection.matchingItem} isSelected={true} onClick={() => {
                        }}/>
                    </div>
                    }
                    {carbonSimulation.renderComponentEditorInputCol1(carbonInputComponent)}

                </div>
                <div className="column scroll">
                    <div className="innerScroll">
                        {carbonSimulation.renderComponentEditorInputCol2(carbonInputComponent)}
                        <CompareCategoryOptions carbonInputComponent={carbonInputComponent} selection={selection}/>
                    </div>
                </div>
            </div>
            <ApplyCancel onApply={() => {
                ui.setEditMode(EditModes.EditLanduse)
            }} onCancel={() => {
                ui.setEditMode(EditModes.EditLanduse);
                mainStore.applyCancelState(`edit_${EditModes.EditComponent}`);
            }}/>

        </div>
    </>
});
