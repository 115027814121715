import {
    ExtendedModel,
    findParent,
    getRoot,
    Model,
    model,
    modelAction,
    objectMap,
    prop,
    Ref,
    rootRef
} from "mobx-keystone";
import {computed} from "mobx";
import {Typology, typologyRef} from "./Typologies";
import {DataStore} from "./DataStore";
import {IMetricGetter, TweakableAreaMetric} from "./Metrics";

export const streamRef = rootRef<DataStream>("dtp/DataStreamRef");
export const compositeRef = rootRef<Composite>("dtp/CompositeRef");
export const versionRef = rootRef<Version>("dtp/VersionRef");

export const CURRENT_SCHEMA = '0.1';

@model("dtp/MetaData")
export class MetaData extends Model({
    projectId: prop<string>(''),
}) {
}

@model("dtp/Version")
export class Version extends Model({
    dataHash: prop<string>(''),
    byteSize: prop<number>(0),
    commitMessage: prop<string>(''),
    modifiedBy: prop<string>(''),
    modified: prop<string>(''),
    sourceFile: prop<string>(''),
    sourceTool: prop<string>(''),
}) {
    data: any;

    async loadData() {
        this.data = [
            {'surfaceTypology1': 5000},
            {'surfaceTypology2': 2000},
            {'typology1': 2000},
        ];
    }

    @computed
    get surfaceMetrics():IValueSet {
        const sums: IValueSet = {};

        if (!this.data) return sums;
        //TODO add more sophisticated normalization for different stream data sources
        Object.keys(this.data).forEach((k) => {
            sums[k] = this.data[k];
        });
        return sums;
    }
}


@model("dtp/FauxVersion")
export class FauxVersion extends ExtendedModel(Version, {}) {
    @modelAction
    setData(data: any) {
        this.data = data;
    }

    static fromData(data: any) {
        const ans = new FauxVersion({});
        ans.setData(data);
        return ans;
    }
}

//most Versions are intended to be immutable and always contain the same data - so you can refer back to older states in design
//the MutableVersion is an alternative where we want a simpler approach and don't want every update to be stored
//this works well for PaintTheMap where we don't want every brush stroke to create a new Version
@model("dtp/MutableVersion")
export class MutableVersion extends ExtendedModel(Version, {
    valueMap: prop(() => objectMap<number>())
}) {
    @modelAction
    setData(data: any) {
        Object.keys(data).forEach((k) => {
            this.valueMap.set(k, data[k]);
        });
    }

    static fromData(data: any) {
        const ans = new MutableVersion({});
        ans.setData(data);
        return ans;
    }

    @computed
    get surfaceMetrics() {
        const sums: IValueSet = {};
        for (let k of this.valueMap.keys()) {
            sums[k] = this.valueMap.get(k) || 0;
        }
        return sums;
    }
}


@model("dtp/DataStream")
export class DataStream extends Model({
    id: prop<string>('', {setterAction: true}),
    name: prop<string>('', {setterAction: true}),
    schema: prop<string>(CURRENT_SCHEMA, {setterAction: true}),
    contentType: prop<string>('', {setterAction: true}),
    versionHistory: prop<Version[]>(),
    currentVersionRef: prop<Ref<Version> | undefined>(() => versionRef('')),

    metadata: prop<MetaData>()
}) {

    @computed
    get currentVersion() {
        const version = this.currentVersionRef ? this.currentVersionRef.maybeCurrent : undefined;
        return version ? version : this.versionHistory[this.versionHistory.length - 1];
    }

    @modelAction
    setCurrentVersion(version: Version | undefined) {
        if (!version) return;
        this.currentVersionRef = versionRef(version);
    }

    @computed
    get surfaceMetrics(): IValueSet {
        return this.currentVersion.surfaceMetrics;
    }

    @computed
    get grossMetrics(): IValueSet {
        return {
            'retail': 1000
        }
    }
}

export interface IValueSet {
    [key: string]: number;
}

export interface ISuperSet {
    [key: string]: IValueSet;
}


export function addValueSets(a: IValueSet, b: IValueSet) {
    Object.keys(b).forEach((k) => {
        if (a[k]) {
            a[k] = a[k] + b[k];
        } else {
            a[k] = b[k]
        }
    });
}

@model("dtp/Composite")
export class Composite extends Model({
    name: prop<string>(''),
    streamRefs: prop<Ref<DataStream>[]>(() => []),
}) {
    @modelAction
    addDataStream(stream: DataStream | undefined) {
        if (!stream) return;
        if (!this.dataStore.getStream(stream.id)) throw new Error("unknown tag")
        this.streamRefs.push(streamRef(stream));
    }

    @computed
    get streams(): DataStream[] {
        const ans: DataStream[] = [];
        this.streamRefs.forEach((s) => {
            if (s.maybeCurrent) {
                ans.push(s.maybeCurrent);
            }
        })
        return ans;
    }

    @computed
    get dataStore(): DataStore {
        return getRoot(this);
    }

    //this can return a computed list of normalized values for each typology
    @computed
    get surfaceMetrics(): IValueSet {
        const sums: IValueSet = {};
        this.streams.forEach((stream) => {
            addValueSets(sums, stream.surfaceMetrics);
        });
        return sums;
    }

    //this can return a computed list of normalized values for each typology
    @computed
    get grossMetrics(): IValueSet {
        return {
            'retail': 1000
        }
    }
}

@model("dtp/TweakablePipedSurfaceAreaMetric")
export class TweakablePipedSurfaceAreaMetric extends ExtendedModel(TweakableAreaMetric, {}) implements IMetricGetter {
    getValueFromComposite(composite: Composite, typologyId: string) {
        const surfaceMetrics = composite.surfaceMetrics;
        return surfaceMetrics[typologyId] || 0
    }

    @computed
    get rawValue(): number {
        if (!this.typology) return 0;
        if (!this.dataStore.activeComposite) return 0;
        return this.getValueFromComposite(this.dataStore.activeComposite, this.typology.id);
    }

    @computed
    get typology(): Typology | undefined {
        //TODO grab the parent typology for this metric...
        return findParent<Typology>(this, (parentNode: any) => {
            return parentNode instanceof Typology;
        });
    }

    @computed
    get dataStore(): DataStore {
        return getRoot(this);
    }
}

@model("dtp/TweakablePipedGrossAreaMetric")
export class TweakablePipedGrossAreaMetric extends ExtendedModel(TweakablePipedSurfaceAreaMetric, {}) {
    getValueFromComposite(composite: Composite, typologyId: string) {
        const grossMetrics = composite.grossMetrics;
        return grossMetrics[typologyId] || 0
    }
}
