import {
    applySnapshot,
    ExtendedModel,
    fromSnapshot,
    getRoot,
    Model,
    model,
    modelAction,
    prop,
    prop_mapObject,
    Ref,
    rootRef
} from "mobx-keystone";

import {Simulation} from "./Simulation";
import {Typology, typologyRef} from "./Typologies";
import {Composite, compositeRef, DataStream} from "./Streams";
import {computed} from "mobx";
import {CarbonInputComponent, EmbodiedCarbonSimulation} from "../../simulations/EmbodiedCarbonSimulation";
import {stripModelIds} from "../../utils/utils";
import {getUid} from "../../ui/helpers";
import {UnitSettings} from "./Units";

const tempIdForTesting = 'd-TEST-iubfosiufboiugbfoiubdsiufbsdifusb';
@model("dtp/DataStore")
export class DataStore extends Model({
    // documentId: prop<string>(tempIdForTesting),
    documentId: prop<string>(`d-${getUid()}-${getUid()}`),
    name: prop<string>('', {setterAction: true}),
    typologies: prop<Typology[]>(() => []),
    unitSettings: prop<UnitSettings>(() => new UnitSettings({})),
    simulations: prop<Simulation[]>(() => []),
    composites: prop<Composite[]>(() => []),
    streams: prop<DataStream[]>(() => []),
    activeCompositeRef: prop<Ref<Composite> | undefined>(() => compositeRef('')),
}) {
    @modelAction
    addTypology(typology: Typology) {
        this.typologies.push(typology);
    }

    @computed
    get activeComposite() {
        const composite = this.activeCompositeRef ? this.activeCompositeRef.maybeCurrent : undefined;
        return composite ? composite : this.composites[0];
    }

    @modelAction
    setActiveComposite(composite: Composite | undefined) {
        if (!composite) return;
        this.activeCompositeRef = compositeRef(composite);
    }

    @modelAction
    clearStreams() {
        this.streams = [];
    }

    @modelAction
    clearComposites() {
        this.composites = [];
    }

    getTypology(id: string) {
        return this.typologies.find(t => t.id === id);
    }

    getStream(id: string) {
        return this.streams.find(t => t.id === id);
    }

    @modelAction
    addSimulation(simulation: Simulation) {
        this.simulations.push(simulation);
    }

    @modelAction
    addComposite(composite: Composite) {
        this.composites.push(composite);
    }

    @modelAction
    addStream(stream: DataStream) {
        this.streams.push(stream);
    }

    typologiesOfType<T extends Typology>(modelTypes: string[]): T[] {//NOTE: couldn't find an elegant solution with generics only - this feels hacky, but TypeScript generics are limited
        const ans: T[] = [];
        this.typologies.forEach((typology: Typology) => {
            const t = typology as T;
            if (modelTypes.indexOf(typology.$modelType) > -1) {
                ans.push(t);
            }
        })
        return ans;
    }

    getUnusedId(prefix: string) {
        let cnt = 0;
        const exists = (id: string) => {
            const match = this.typologies.find(t => t.id === id);
            return !!match;
        }
        while (exists(`${prefix}${cnt}`)) {
            cnt++;
        }
        return `${prefix}${cnt}`;
    }

    getUnusedColor(colors: string[], defaultIfFull: string) {
        const exists = (color: string) => {
            const match = this.typologies.find(t => t.color === color);
            return !!match;
        }
        for (let i = 0; i < colors.length; i++) {
            const color = colors[i];
            if (!exists(color)) {
                return color;
            }
        }
        return defaultIfFull;
    }

    static fromSnapshot(snapshot: any): DataStore {
        const ans = fromSnapshot<DataStore>(snapshot);
        //NOTE: it should be enough to simply return the result of fromSnapshot here, but there seems to be a bug with
        // derived classes like CarbonInputComponent when deserializing
        // this serves as a temporary workaround
        const carbonInputComponents:any = {};
        if (snapshot.simulations) {
            snapshot.simulations.forEach(((simulation: any) => {
                if (simulation.$modelType === 'dtp/EmbodiedCarbonSimulation') {
                    simulation.carbonInputs.forEach((carbonInput: any) => {
                        carbonInputComponents[carbonInput.typologyRef.id] = carbonInput.components;
                    })
                }
            }))
        }
        const carbonSimulation = ans.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;
        if (carbonSimulation) {
            carbonSimulation.carbonInputs.forEach((carbonInput) => {
                if (carbonInput.typologyRef) {
                    let components = carbonInputComponents[carbonInput.typologyRef.id];
                    if (components) {
                        carbonInput.setComponents(components.map((snapshot:any) => {
                            return new CarbonInputComponent(snapshot)
                        }));
                    }
                }
            });
        }

        return ans;
    }
}


export interface ISharedMix {
    shareAmount: number;
}

export interface IColoredSharedMix extends ISharedMix {
    color: string;
}

export interface ILabel {
    label: string;
}

export interface IGrossTypology {
    grossArea: number;
}

export interface IBuildingTypology {
    isBuilding: boolean;
    floorsBelowGrade: number;
    totalFloors: number;
}

export interface ISurfaceTypology {
    surfaceArea: number;
}

export interface ITweakable {
    overrideEnabled: boolean;
    currentValue: number;
    overrideValue: number;
    rawValue: number;
    setOverrideValue: (value: number) => void;
    setOverrideEnabled: (enabled: boolean) => void;
}

