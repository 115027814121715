import {observer} from "mobx-react";
import {TweakableAreaMetric} from "../stores/ks/Metrics";
import {OverrideToggle} from "./OverrideToggle";
import {NumericInput, NumericOverrideInput, NumericReadOnly} from "./NumericInput";
import {getUid} from "./helpers";
import React from "react";
import {SelectList} from "../simulations/TrafficSimulation";
import {ITweakable} from "../stores/ks/DataStore";

export const TweakableInput = observer(({tweakableMetric, hideToggle, id, format}: { tweakableMetric: ITweakable, hideToggle?:boolean, id?: string, format?: (v: number)=> string }) => {
    return <div className={'TweakableInput'}>{!hideToggle && <OverrideToggle tweakable={tweakableMetric}/>}
        {tweakableMetric.overrideEnabled ? <>
                <NumericOverrideInput id={id} tweakable={tweakableMetric}/>
            </> :
            <>
                <NumericReadOnly id={id} metric={tweakableMetric} format={format}/>
            </>
        }
    </div>
});
export const LabelledTweakableInput = observer((props: { tweakableMetric: ITweakable, hideToggle?:boolean, label: string }) => {
    const {tweakableMetric, hideToggle, label} = props;
    const uid = getUid();
    return <div className={'entry'}>
        <label htmlFor={uid}>{label}</label>
        <TweakableInput id={uid} tweakableMetric={tweakableMetric} hideToggle={hideToggle}/>
    </div>
});
