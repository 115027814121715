import { ReactElement } from 'react';
import {observer} from "mobx-react";

export const IconButton = observer(({iconUrl, icon, onClick, onMouseEnter, onMouseLeave}: { iconUrl?: string, icon?: ReactElement, onClick?: () => void, onMouseEnter?: () => void, onMouseLeave?: () => void }) => {
    return (
        <button 
            className="IconButton" 
            onClick={onClick} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave} 
            style={iconUrl ? ({backgroundImage: `url('${iconUrl}')`}) : ({})}
        >
            {icon}
        </button>
    );
});
