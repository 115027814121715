import { observer } from "mobx-react";
import { useDtpStores } from "../../index";
import { fixedRectStyle } from "../helpers";
import { PanelHeader } from "../elements/PanelHeader";
import React from "react";
import { PaintTheMap } from "./PaintTheMap";
import { ToolLinkOptions } from "../../stores/ks/PersistStore";
import { TotalPix } from "./TotalPix";
import { Button } from "../elements/Button";

const ToolLinkPicker = observer(() => {
    const { persist } = useDtpStores();

    return <div>
        <div className={'tool-option'}>
            <h3>Piku</h3>
            <p>If you have an existing image for your landuses, use this tool to count areas from image pixels.</p>
            <Button className={'rounder'} title={'Use'} onClick={() => {
                persist.setToolLinkOption(ToolLinkOptions.TotalPix);
            }}/>
        </div>
        <div className={'tool-option'}>
            <h3>PaintTheMap</h3>
            <p>Use our paint tool in a new browser window or on a tablet to quickly do area take-offs from a map.</p>
            <Button className={'rounder'} title={'Use'} onClick={() => {
                persist.setToolLinkOption(ToolLinkOptions.PaintTheMap);
            }}/>
        </div>

    </div>
});

export const ConnectionsPanel = observer(() => {
    const { layout, persist } = useDtpStores();
    return <div className={'ConnectionsPanel'} style={fixedRectStyle(layout.streamsPanel)}>
        <div className={'panelOuter right'}>
            <PanelHeader title={'Connect your Design'}
                         description={'Connect to a design-measurement tool for quick and easy area takeoffs.'}/>
            <div className={'panelInner'}>
                <div className={'optionsList'}>
                    {!persist.toolLinkOption && <ToolLinkPicker/>}
                    {!!persist.toolLinkOption && <button onClick={() => {
                        persist.setToolLinkOption(ToolLinkOptions.NONE);
                    }}>back</button>}

                    {persist.toolLinkOption === ToolLinkOptions.PaintTheMap && <PaintTheMap/>}
                    {persist.toolLinkOption === ToolLinkOptions.TotalPix && <TotalPix/>}
                </div>
            </div>

        </div>
    </div>;
});
