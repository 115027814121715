import {observer} from "mobx-react";
import {ITweakable} from "../stores/ks/DataStore";
import manualOverrideIcon from "./assets/calculator.svg";
import designIcon from "./assets/pen-tool.svg";
import {IconButton} from "./elements/IconButton";
import gearIcon from "../../skins/carbon-conscience/assets/settings.svg";
import React from "react";

export const OverrideToggle = observer(({tweakable} : {tweakable:ITweakable}) => {

    return <>
        <IconButton iconUrl={tweakable.overrideEnabled ? manualOverrideIcon : designIcon} onClick={() => {
            tweakable.setOverrideEnabled(!tweakable.overrideEnabled);
        }}/>
    </>;
});
