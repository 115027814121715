import {detach, ExtendedModel, getRoot, Model, model, modelAction, prop} from "mobx-keystone";
import {computed} from "mobx";
import {observer} from "mobx-react";
import React, {useState} from "react";
import trafficCodes, {IteCode} from "../assets/IteCodes";
import {ComponentsMixer} from "../ui/ComponentsMixer";
import {Metric} from "../stores/ks/Metrics";
import {MixComponent, MixedComponentInput, Simulation} from "../stores/ks/Simulation";
import {DataStore, IGrossTypology} from "../stores/ks/DataStore";
import {GrossTypology, Typology} from "../stores/ks/Typologies";
import {NumericInput} from "../ui/NumericInput";
import {getUid} from "../ui/helpers";
import {CONVERT_SQ_M_TO_SQ_FT} from "../stores/ks/Units";

const iteCodes = trafficCodes;
const categories: string[] = [];
iteCodes.forEach((iteCode, i) => {
    if (categories.indexOf(iteCode.category) < 0) {
        categories.push(iteCode.category);
    }
});
export const SelectList = observer((props: { options: string[], getter: () => string, setter: (val: string) => void, id?:string }) => {
    const {options, getter, setter, id} = props;
    const selectedValue = getter();
    // console.log('SelectList', selectedValue)
    return <select id={id} value={selectedValue} onChange={(e) => setter(e.target.value)}>
        <option value="-" disabled={false}>-select-</option>
        {options.map(option => {
            return <option key={option} value={option}>{option}</option>
        })}
    </select>;
});
export const LabelledSelectList = observer((props: { options: string[], getter: () => string, setter: (val: string) => void, label: string }) => {
    const {options, getter, setter, label} = props;
    const uid = getUid();
    return <div className={'entry'}>
        <label htmlFor={uid}>{label}</label>
        <SelectList id={uid} options={options} getter={getter} setter={setter}/>
    </div>
});

export const IteCodePicker = observer(({component}: { component: TrafficInputComponent }) => {
    const [category, setCategory] = useState<string>('-')
    const [iteCode, setIteCode] = useState<string>('-')

    const categoryOptions: string[] = [];
    iteCodes.forEach((iteCode, i) => {
        if (iteCode.category === category) {
            categoryOptions.push(iteCode.name);
        }
    });

    return <>
        <SelectList options={categories} getter={() => category} setter={(val) => setCategory(val)}/>
        <SelectList options={categoryOptions} getter={() => iteCode} setter={(val) => {
            setIteCode(val)
            const iteCode: IteCode | undefined = iteCodes.find(c => c.name === val);
            if (iteCode) {
                component.pmPeak.setValue(iteCode.pmPeak);
                component.adt.setValue(iteCode.adt);
                component.iteCode = iteCode.code;
            }
        }}/>
    </>;
});


@model("dtp/TrafficSimulation")
export class TrafficSimulation extends ExtendedModel(Simulation, {
    trafficInputs: prop<TrafficInput[]>(() => []),
}) {

    @computed
    get id(): string {
        return 'TRAFFIC'
    }

    @computed
    get name(): string {
        return 'Traffic Model'
    }

    @modelAction
    addInput(typology: Typology, numShares: number): void {
        let trafficInput = new TrafficInput({});
        for (let i = 0; i < numShares; i++) {
            trafficInput.components.push(new TrafficInputComponent({shareAmount: 1 / numShares}));
        }
        trafficInput.setTypology(typology);
        this.trafficInputs.push(trafficInput);
    }


    renderTypologyInput(typology: Typology): JSX.Element | null {
        const linkedInput = this.trafficInputs.find(input => input.typology === typology);
        if (!linkedInput) {
            return null;
        }
        const isMixed = linkedInput.components.length > 1;
        return <div>
            <h4>{typology.name}</h4>
            <div>
                <button onClick={() => {
                    linkedInput.addComponent();
                }}>Add component
                </button>
                {isMixed &&
                <ComponentsMixer componentMix={linkedInput}/>
                }
                {linkedInput.componentsOfType<TrafficInputComponent>(['dtp/TrafficInputComponent']).map((component) => {
                    return <div>share: {component.shareAmount}
                        | <b>{component.iteCode}</b> |
                        PM Peak: <NumericInput metric={component.pmPeak}/>
                        ADT: <NumericInput metric={component.adt}/>
                        <IteCodePicker component={component}/>
                        {isMixed && <button onClick={() => {
                            component.delete();
                            linkedInput.redistributeComponents();
                        }}>x</button>}
                    </div>
                })}
            </div>

        </div>
    }

    renderOutputs(): JSX.Element | null {
        const dataStore = getRoot(this) as DataStore;
        if (!dataStore) {
            return null;
        }
        return <div>{
            dataStore.typologiesOfType<GrossTypology>(['dtp/GrossTypology', 'dtp/GrossTypologyFromDensity']).map((grossTypology: GrossTypology) => {
                const linkedInput = this.trafficInputs.find(input => input.typology === grossTypology);
                if (!linkedInput) {
                    return null;
                }
                return <div>
                    {grossTypology.name} ADT:({linkedInput.adt}); PM Peak:({linkedInput.pmPeak})
                </div>
            })}
        </div>
    }
}

@model("dtp/TrafficInput")
export class TrafficInput extends ExtendedModel(MixedComponentInput, {}) {
    sumValue(getter: (component: TrafficInputComponent) => number) {
        const grossTypology = this.typology as unknown as IGrossTypology;
        if (!grossTypology) return 0;
        let sum = 0;
        this.componentsOfType<TrafficInputComponent>(['dtp/TrafficInputComponent']).forEach((component) => {
            sum += component.shareAmount * getter(component) * grossTypology.grossArea;
        });
        return sum;
    }

    get adt() {
        return this.sumValue((component) => component.adtTrips);
    }

    get pmPeak() {
        return this.sumValue((component) => component.pmPeakTrips);
    }

    @modelAction
    addComponent() {
        //make 'space' for new component
        const newAmt = 1 / (this.components.length + 1);
        this.components.forEach((component, i) => {
            component.shareAmount *= (1 - newAmt);
        });
        this.components.push(new TrafficInputComponent({shareAmount: newAmt}))
    }
}

//TODO - should the component reference the IteCode element (in which case all elements have to be saved in each document)
//or should we just keep an ID and the associated data for the selected elements?
@model("dtp/TrafficInputComponent")
export class TrafficInputComponent extends ExtendedModel(MixComponent, {
    iteCode: prop<string>('', {setterAction: true}),
    adt: prop<Metric>(() => new Metric({value: 10}), {setterAction: true}),
    pmPeak: prop<Metric>(() => new Metric({value: 1}), {setterAction: true}),
}) {
    delete() {
        detach(this);
    }

    get adtTrips() {
        //trips are calculated using metrics of trips/1000sf
        //the value returned should be multiplied by gross area in sq m
        //multiplier for 'gross' in in sq m: convert to sq ft
        return this.adt.value / (1000 * CONVERT_SQ_M_TO_SQ_FT);
    }

    get pmPeakTrips() {
        return this.pmPeak.value / (1000 * CONVERT_SQ_M_TO_SQ_FT);
    }

}
