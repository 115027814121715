import {ExtendedModel, Model, model, modelAction, prop} from "mobx-keystone";
import {computed} from "mobx";
import {ITweakable} from "./DataStore";
import {CONVERT_M_TO_FT, CONVERT_SQ_M_TO_ACRE, CONVERT_SQ_M_TO_SQ_FT} from "./Units";

export interface IMetricGetter {
    value: number;
}

export interface IMetric extends IMetricGetter {
    setValue: (value: number) => void;
}

@model("dtp/Metric")
export class Metric extends Model({
    value: prop<number>(0, {setterAction: true}),//sq m
}) implements IMetric {
    @modelAction
    setValue(value: number): void {
        this.value = value;
    }
}


@model("dtp/AreaMetric")
export class AreaMetric extends Model({
    areaSqM: prop<number>(0, {setterAction: true}),//sq m
}) implements IMetric {
    @computed
    get areaSqFt() {
        return this.areaSqM * CONVERT_SQ_M_TO_SQ_FT
    }

    @computed
    get areaAcres() {
        return this.areaSqM * CONVERT_SQ_M_TO_ACRE;
    }

    @modelAction
    setValue(value: number): void {
        this.areaSqM = value;
    }

    @computed
    get value() {
        return this.areaSqM;
    }
}

@model("dtp/TweakableAreaMetric")
export class TweakableAreaMetric extends  ExtendedModel(AreaMetric, {
    overrideAreaSqM: prop<number>(0, {setterAction: true}),//sq m
    overrideEnabled: prop<boolean>(false),
}) implements IMetric, ITweakable {
    @computed
    get currentValue() {
        return this.overrideEnabled ? this.overrideValue : this.rawValue;
    }

    @computed
    get rawValue() {
        return this.value;
    }

    @computed
    get overrideValue() {
        return this.overrideAreaSqM;
    }

    @modelAction
    setOverrideEnabled(enabled: boolean): void {
        this.overrideEnabled = enabled;
    }

    @modelAction
    setOverrideValue(value: number): void {
        this.overrideAreaSqM = value;
    }
}

@model("dtp/CostMetric")
export class CostMetric extends Model({
    value: prop<number>(0, {setterAction: true}),
    currency: prop<string>('$', {setterAction: true}),
}) implements IMetric {
    @modelAction
    setValue(value: number): void {
        this.value = value;
    }
    @modelAction
    setCurrency(value: string): void {
        this.currency = value;
    }
}

@model("dtp/LinearMetric")
export class LinearMetric extends Model({
    linearM: prop<number>(0, {setterAction: true}),
}) implements IMetric {
    @computed
    get linearFt() {
        return this.linearM * CONVERT_M_TO_FT
    }

    @modelAction
    setValue(value: number): void {
        this.linearM = value;
    }

    @computed
    get value() {
        return this.linearM;
    }
}
