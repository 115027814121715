import {observer} from "mobx-react";
import React from "react";
import {useDtpStores} from "../../index";
import QRCode from "react-qr-code";
import manualOverrideIcon from "../assets/calculator.svg";
import designIcon from "../assets/pen-tool.svg";
import { DesignToggleInstructions } from "./PaintTheMap";

export const TotalPix = observer(() => {
    const mainStore = useDtpStores();
    const {dataStore} = mainStore;
    const {documentId} = dataStore;
    const urlBase = 'https://piku-piku.web.app/';
    //NOTE: we're using documentId for documentId and streamId for simple case
    let url = `${urlBase}?documentId=${encodeURIComponent(documentId)}&streamId=${encodeURIComponent(documentId)}`;
    return <div className="TotalPix">
        <p>Open the <a target={'_blank'} href={url}>pixel counting tool</a> in a new browser window</p>
        <DesignToggleInstructions sendBackText={'Use the \'Send to CC\' button to send your measured areas back here.'}/>

    </div>;
});

