import {action, computed, observable} from "mobx";
import carbonItems from "./assets/items.json";
import {CarbonInputComponent} from "../EmbodiedCarbonSimulation";

export type ItemBase = {
    uid: string,
    name: string,
    ref: string,
    parents: string[],
    stored?: number | null,
    details?: string | null,
}

export type BuildingItem = ItemBase & {
    costLow_AboveGrade?: number | null,
    costLow_BelowGrade?: number | null,
    costHigh_AboveGrade?: number | null,
    costHigh_BelowGrade?: number | null,
}
export type LandscapeItem = ItemBase & {
    costLow?: number | null,
    costHigh?: number | null,
    sequestered?: number | null,
}
export type CarbonItem = BuildingItem & LandscapeItem;


export class CarbonSelection {//NOTE - temporary store using pure MobX (not Keystone)
    @observable tier2Selection = '';
    @observable tier3Selection = '';
    @observable tier4Selection = '';

    @observable carbonInputComponent;
    @observable comparisonItem: CarbonItem | undefined;

    constructor(carbonInputComponent: CarbonInputComponent) {
        this.carbonInputComponent = carbonInputComponent;
        if (this.carbonInputComponent) {
            this.setSelectionFromId(this.carbonInputComponent.itemId);
        }
    }

    @computed get tier1Selection() {
        return this.carbonInputComponent.topLevelSelection || '';
    }

    @action
    setSelection(selections: string[]) {
        selections.forEach((val, i) => {
            this.setTierSelection(i, val);
        });
    }

    @action
    setSelectionFromId(itemId: string) {
        const item = carbonItems.find(item => item.uid === itemId);
        if (item) {
            this.setSelection([...item.parents, this.displayName(item)])
            return item;
        }
    }

    @computed get matchingItem(): CarbonItem | undefined {
        const matchingParents = [
            this.tier1Selection,
            this.tier2Selection,
            this.tier3Selection,
        ];
        return carbonItems.find(item => {
            for (let i = 0; i < matchingParents.length; i++) {
                if (item.parents[i] !== matchingParents[i]) return false;
            }
            return this.displayName(item) === this.tier4Selection;
        });
    }

    @action
    setMatchingItem(item: CarbonItem) {
        this.tier4Selection = this.displayName(item);
    }

    @action
    setTierSelection(tierIdx: number, value: string) {
        switch (tierIdx) {
            // case 0:
            //     this.tier1Selection = value;
            //     break;
            case 1:
                this.tier2Selection = value;
                break;
            case 2:
                this.tier3Selection = value;
                break;
            case 3:
                this.tier4Selection = value;
                break;
        }
    }

    @action
    storeSelectedAsComparison() {
        this.comparisonItem = this.matchingItem;
    }

    @computed get tier1Options() {
        const ans: string[] = [];
        carbonItems.forEach((item, i) => {
            const tier1 = item.parents[0];
            if (ans.indexOf(tier1) < 0) {
                ans.push(tier1);
            }
        });
        return ans;
    }

    @computed get tier2Options() {
        const ans: string[] = [];
        if (!this.tier1Selection) return ans;
        carbonItems.forEach((item, i) => {
            const tier1 = item.parents[0];
            if (tier1 !== this.tier1Selection) return;
            const tier2 = item.parents[1];
            if (ans.indexOf(tier2) < 0) {
                ans.push(tier2);
            }
        });
        return ans;
    }

    @computed get tier3Options() {
        const ans: string[] = [];
        if (!(this.tier1Selection && this.tier2Selection)) return ans;
        carbonItems.forEach((item, i) => {
            const tier1 = item.parents[0];
            if (tier1 !== this.tier1Selection) return;
            const tier2 = item.parents[1];
            if (tier2 !== this.tier2Selection) return;
            const tier3 = item.parents[2];
            if (ans.indexOf(tier3) < 0) {
                ans.push(tier3);
            }
        });
        return ans;
    }

    @computed get allItemsUnderTier2() {
        const ans: any[] = [];
        if (!(this.tier1Selection && this.tier2Selection)) return ans;
        carbonItems.forEach((item, i) => {
            const tier1 = item.parents[0];
            if (tier1 !== this.tier1Selection) return;
            const tier2 = item.parents[1];
            if (tier2 !== this.tier2Selection) return;
            ans.push(item);
        });
        return ans;
    }


    @computed get tier4Items() {
        const ans: CarbonItem[] = [];
        if (!(this.tier1Selection && this.tier2Selection && this.tier3Selection)) return ans;
        carbonItems.forEach((item, i) => {
            const tier1 = item.parents[0];
            if (tier1 !== this.tier1Selection) return;
            const tier2 = item.parents[1];
            if (tier2 !== this.tier2Selection) return;
            const tier3 = item.parents[2];
            if (tier3 !== this.tier3Selection) return;
            ans.push(item);
        });
        return ans;
    }

    @computed get tier4Options() {
        const ans: string[] = [];
        this.tier4Items.forEach((item) => {
            const tier4 = this.displayName(item);
            if (ans.indexOf(tier4) < 0) {
                ans.push(tier4);
            }
        });
        return ans;
    }

    displayName(item: { name: string, ref: string }) {
        return `(${item.ref}) ${item.name}`
    }

    static fullDisplayName(item: CarbonItem, includeRef: boolean = true) {
        const type = item.parents[0];
        let prefix = '';
        if (includeRef) {
            prefix = `(${item.ref}) `
        }
        if (type === 'Landscape') {
            return `${prefix}${item.name}`
        } else {
            return `${prefix} ${item.parents[1]} > ${item.parents[2]} > ${item.name}`
        }
    }

    @computed
    get selectedDisplayName() {
        if (!this.matchingItem) return '';
        return CarbonSelection.fullDisplayName(this.matchingItem);
    }
}
