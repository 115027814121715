import {action, autorun, computed, observable} from "mobx";

import Config, {DefaultConfig} from '../types/Config';
import {DataStore} from "./ks/DataStore";
import PersistStore from './ks/PersistStore';
import {
    GrossTypologyFromDensity,
    MixedTypology,
    SurfaceTypology,
} from "./ks/Typologies";
import {AreaMetric, TweakableAreaMetric} from "./ks/Metrics";
import {CostSimulation} from "../simulations/CostSimulation";
import {applySnapshot, getSnapshot, registerRootStore} from "mobx-keystone";
import {TrafficSimulation} from "../simulations/TrafficSimulation";
import {EmbodiedCarbonSimulation} from "../simulations/EmbodiedCarbonSimulation";
import {Composite, DataStream, FauxVersion, MetaData, TweakablePipedSurfaceAreaMetric, Version} from "./ks/Streams";
import LayoutStore from "./LayoutStore";
import UiStore from './UiStore';
import UserStore from './UserStore';

export interface ICancelStates {
    applyCancelState: (key: string) => void,
    storeCancelState: (key: string) => void,
}

export interface IMainStore extends ICancelStates {
    config: Config;
    dataStores: DataStore[];
    layout: LayoutStore;
    ui: UiStore;
    user: UserStore;
    dataStore: DataStore;
    selectedDataStoreIndex: number;
    persist: PersistStore;

    setDataStore(loadFromStorage: undefined | DataStore): void;
}

export default class MainStore implements IMainStore {

    config: Config;

    @observable
    dataStores: DataStore[];

    @observable
    selectedDataStoreIndex: number = 0;

    @observable
    layout: LayoutStore;

    @observable
    persist: PersistStore;

    @observable
    ui: UiStore;

    @observable
    user: UserStore;

    cancelStates: any = {};
    private readonly onInitDataStore: (dataStore:DataStore) => void;

    constructor(config: Config = DefaultConfig, stores: { ui?: UiStore, user?: UserStore }, onInitDataStore:(dataStore:DataStore)=>void) {
        this.config = config;
        this.onInitDataStore = onInitDataStore;
        this.layout = new LayoutStore(this);//TODO figure out better way to support extending LayoutStore to subclass and assigning here...?
        this.persist = new PersistStore({});
        this.persist.configure(config);

        this.ui = stores.ui || new UiStore();
        this.user = stores.user || new UserStore(this);

        //NOTE: MainStore is NOT Keystone. The root of the tree is DataStore
        //multiple DataStores can be loaded at once into memory (e.g. for comparing scenarios - full file scenarios rather than stream Composites)
        this.dataStores = [];

        this.dataStores.push(this.createDataStore('Option 1'));
        // this.dataStores.push(this.initDataStore('Option 2'));
        // this.dataStores.push(this.initDataStore('Option 3'));
    }

    @computed get dataStore(): DataStore {
        return this.dataStores[this.selectedDataStoreIndex];
    }

    @action
    setSelectedDataStoreIndex(value: number) {
        this.selectedDataStoreIndex = value;
    }

    @action
    setActiveDataStore(dataStore: DataStore) {
        this.selectedDataStoreIndex = this.dataStores.indexOf(dataStore);
    }

    private createDataStore(name?: string) {
        const dataStore = new DataStore({name});
        this.initDataStore(dataStore);
        return dataStore;
    }

    private initDataStore(dataStore: DataStore) {
        registerRootStore(dataStore);

        const embodiedCarbonSimulation = new EmbodiedCarbonSimulation({});
        dataStore.addSimulation(embodiedCarbonSimulation);

        const costSimulation = new CostSimulation({});
        dataStore.addSimulation(costSimulation);

        // this.initStreams(dataStore);
        this.onInitDataStore(dataStore);

        return dataStore;

    }

    storeCancelState(key: string) {
        this.cancelStates[key] = getSnapshot(this.dataStore);
    }

    @action applyCancelState(key: string) {
        applySnapshot<DataStore>(this.dataStore, this.cancelStates[key]);
    }

    newDocument() {
        let newDoc = new DataStore({});
        this.initDataStore(newDoc);
        this.persist.newDocument(newDoc);
        this.setDataStore(newDoc);
    }

    setDataStore(dataStore: DataStore | undefined): void {
        if (!dataStore) return;
        this.dataStores[this.selectedDataStoreIndex] = dataStore;
    }

}
