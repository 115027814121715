import {observer} from "mobx-react";

export const TextInput = observer((props: { value: string, setValue: (value: string) => void, id?: string }) => {
    const {value, setValue, id} = props;
    return <>
        <input className="TextInput" id={id}
               type="text"
               value={value}
               onChange={(e) => {
                   setValue(e.target.value);
               }}
        />
    </>;
});
