//Note: this is used as a temporary 'lookup' for codes and values that is used to populate components
//the full 'library' is NOT saved with the document and therefore IteCode is not defined as a keystone class
export class IteCode {
    category: string;
    code: string;
    name: string;
    adt: number;
    adtStudies: number;
    pmPeak: number;
    pmPeakStudies: number;
    constructor(category: string, code: string, name: string, adt: number, adtStudies: number, pmPeak:number, pmPeakStudies: number) {
        this.adt = adt;
        this.adtStudies = adtStudies;
        this.pmPeak = pmPeak;
        this.pmPeakStudies = pmPeakStudies;
        this.category = category;
        this.code = code;
        this.name = name;
    }
}

const trafficCodes:IteCode[] = [];

trafficCodes.push(new IteCode("Residential", '210', "Single Family Detached (unit)", 9.57, 350, 1.01, 302));
trafficCodes.push(new IteCode("Residential", '220', "Apartment (unit)", 6.72, 86, 0.62, 90));
trafficCodes.push(new IteCode("Residential", '221', "Low-Rise Apartment (occ. unit)", 6.59, 22, 0.58, 27));
trafficCodes.push(new IteCode("Residential", '222', "High-Rise Apartment (unit)", 4.2, 9, 0.35, 17));
trafficCodes.push(new IteCode("Residential", '223', "Mid-Rise Apartment (unit)", 0, 0, 0.39, 7));
trafficCodes.push(new IteCode("Residential", '224', "Rental Townhouse (unit)", 0, 0, 0.72, 1));
trafficCodes.push(new IteCode("Residential", '230', "Residential Condominium/Townhouse (unit)", 5.86, 54, 0.52, 62));
trafficCodes.push(new IteCode("Residential", '231', "Low-Rise Residential Condo/Townhouse (unit)", 0, 0, 0.78, 5));
trafficCodes.push(new IteCode("Residential", '232', "High-Rise Residential Condo/Townhouse (unit)", 4.18, 4, 0.38, 5));
trafficCodes.push(new IteCode("Residential", '233', "Luxury Condominium/Townhouse (occ. unit)", 0, 0, 0.56, 4));
trafficCodes.push(new IteCode("Residential", '240', "Mobile Home Park (occ. unit)", 4.99, 35, 0.59, 23));
trafficCodes.push(new IteCode("Residential", '250', "Retirement Community (occ. unit)", 0, 0, 0.27, 6));
trafficCodes.push(new IteCode("Residential", '251', "Elderly Housing - Detached (unit)", 3.71, 7, 0.26, 11));
trafficCodes.push(new IteCode("Residential", '252', "Elderly Housing - Attached (occ. unit)", 3.48, 1, 0.11, 7));
trafficCodes.push(new IteCode("Residential", '253', "Congregate Care Facility (unit)", 2.02, 2, 0.17, 3));
trafficCodes.push(new IteCode("Residential", '253', "Congregate Care Facility (occ. unit)", 2.15, 2, 0.17, 2));
trafficCodes.push(new IteCode("Residential", '254', "Assisted Living (occ. beds)", 2.74, 15, 0.22, 7));
trafficCodes.push(new IteCode("Residential", '255', "Continuing Care Retirement Community (occ. units)", 2.81, 5, 0.29, 9));
trafficCodes.push(new IteCode("Residential", '260', "Recreational Homes (units)", 3.16, 2, 0.26, 2));
trafficCodes.push(new IteCode("Residential", '260', "Recreational Homes (acres)", 1.33, 2, 0.11, 2));
trafficCodes.push(new IteCode("Residential", '270', "Residential Planned Unit Development (unit)", 7.5, 13, 0.62, 18));
trafficCodes.push(new IteCode("Residential", '270', "Residential Planned Unit Development (acres)", 46.78, 4, 4.05, 4));
trafficCodes.push(new IteCode("Residential", '310', "Hotel (room)", 8.17, 10, 0.59, 25));
trafficCodes.push(new IteCode("Residential", '310', "Hotel (occ. room)", 8.92, 4, 0.7, 20));
trafficCodes.push(new IteCode("Residential", '311', "All Suites Hotel (room)", 4.9, 3, 0.4, 5));
trafficCodes.push(new IteCode("Residential", '311', "All Suites Hotel (occ. room)", 6.24, 3, 0.55, 4));
trafficCodes.push(new IteCode("Residential", '312', "Business Hotel (occ. room)", 7.27, 1, 0.62, 3));
trafficCodes.push(new IteCode("Residential", '320', "Motel (room)", 5.63, 10, 0.47, 25));
trafficCodes.push(new IteCode("Residential", '320', "Motel (occ. room)", 9.11, 15, 0.58, 22));
trafficCodes.push(new IteCode("Residential", '330', "Resort Hotel (room)", 0, 0, 0.42, 10));
trafficCodes.push(new IteCode("Residential", '330', "Resort Hotel (occ. room)", 0, 0, 0.49, 10));
trafficCodes.push(new IteCode("Office", '710', "General Office Building (1,000 sf)", 11.01, 78, 1.49, 235));
trafficCodes.push(new IteCode("Office", '714', "Corporate HQ Building (1,000 sf)", 7.98, 8, 1.4, 21));
trafficCodes.push(new IteCode("Office", '715', "Single Tenant Office Building (1,000 sf)", 11.57, 14, 1.73, 42));
trafficCodes.push(new IteCode("Office", '720', "Medical/Dental Office Building (1,000 sf)", 36.13, 10, 3.72, 41));
trafficCodes.push(new IteCode("Office", '750', "Office Park (1,000 sf)", 11.42, 12, 1.5, 31));
trafficCodes.push(new IteCode("Office", '760', "Research and Development Center (1,000 sf)", 8.11, 28, 1.08, 34));
trafficCodes.push(new IteCode("Office", '760', "Research and Development Center (acres)", 79.61, 25, 15.44, 26));
trafficCodes.push(new IteCode("Office", '770', "Business Park (1,000 sf)", 12.76, 15, 1.29, 20));
trafficCodes.push(new IteCode("Office", '770', "Business Park (acres)", 149.79, 12, 16.84, 12));
trafficCodes.push(new IteCode("Commercial", '812', "Bldg Material & Lumber Store (1,000 sf)", 45.16, 4, 4.49, 6));
trafficCodes.push(new IteCode("Commercial", '813', "Free-Standing Discount Superstore (1,000 sf)", 49.21, 10, 3.87, 10));
trafficCodes.push(new IteCode("Commercial", '814', "Specialty Retail Center (1,000 sf GLA)", 44.32, 4, 2.71, 5));
trafficCodes.push(new IteCode("Commercial", '815', "Free-Standing Discount Store (1,000 sf)", 56.02, 23, 5.06, 47));
trafficCodes.push(new IteCode("Commercial", '816', "Hardware/Paint Store (1,000 sf)", 51.29, 3, 4.84, 8));
trafficCodes.push(new IteCode("Commercial", '817', "Nursery (Garden Center) (1,000 sf)", 36.08, 11, 3.8, 12));
trafficCodes.push(new IteCode("Commercial", '817', "Nursery (Garden Center) (acres)", 96.21, 11, 7.92, 12));
trafficCodes.push(new IteCode("Commercial", '818', "Nursery (Wholesale) (1,000 sf)", 39, 1, 5.17, 6));
trafficCodes.push(new IteCode("Commercial", '818', "Nursery (Wholesale) (acres)", 19.5, 1, 0.45, 6));
trafficCodes.push(new IteCode("Commercial", '820', "Shopping Center (1,000 sf GLA)", 42.94, 302, 3.75, 407));
trafficCodes.push(new IteCode("Commercial", '823', "Factory Outlet Center (1,000 sf)", 26.59, 11, 2.29, 14));
trafficCodes.push(new IteCode("Commercial", '848', "Tire Store (1,000 sf)", 24.87, 5, 4.12, 16));
trafficCodes.push(new IteCode("Commercial", '848', "Tire Store (service bays)", 0, 0, 3.79, 8));
trafficCodes.push(new IteCode("Commercial", '849', "Tire Superstore (1,000 sf)", 23.36, 12, 2.11, 12));
trafficCodes.push(new IteCode("Commercial", '849', "Tire Superstore (service bays)", 30.55, 12, 3.17, 12));
trafficCodes.push(new IteCode("Commercial", '850', "Supermarket (1,000 sf)", 102.24, 4, 10.45, 42));
trafficCodes.push(new IteCode("Commercial", '851', "Convenience Market (Open 24 Hours) (1,000 sf)", 737.99, 8, 52.41, 33));
trafficCodes.push(new IteCode("Commercial", '852', "Convenience Market (Open 15-16 Hours) (1,000 sf)", 0, 0, 34.57, 5));
trafficCodes.push(new IteCode("Commercial", '853', "Convenience Market w/Gasoline Pumps (1,000 sf)", 845.6, 10, 60.61, 53));
trafficCodes.push(new IteCode("Commercial", '853', "Convenience Market w/Gasoline Pumps (fuel position)", 542.6, 10, 19.22, 53));
trafficCodes.push(new IteCode("Commercial", '854', "Discount Supermarket (1,000 sf)", 96.82, 7, 8.9, 15));
trafficCodes.push(new IteCode("Commercial", '860', "Wholesale Market (1,000 sf)", 6.73, 1, 0.21, 1));
trafficCodes.push(new IteCode("Commercial", '861', "Discount Club (1,000 sf)", 41.8, 19, 4.24, 25));
trafficCodes.push(new IteCode("Commercial", '862', "Home Improvement Superstore (1,000 sf)", 29.8, 9, 2.45, 11));
trafficCodes.push(new IteCode("Commercial", '863', "Electronics Superstore (1,000 sf)", 45.04, 3, 4.5, 3));
trafficCodes.push(new IteCode("Commercial", '864', "Toy/Children's Superstore (1,000 sf)", 0, 0, 4.99, 2));
trafficCodes.push(new IteCode("Commercial", '865', "Baby Superstore (1,000 sf)", 0, 0, 1.82, 1));
trafficCodes.push(new IteCode("Commercial", '866', "Pet Supply Superstore (1,000 sf)", 0, 0, 4.96, 1));
trafficCodes.push(new IteCode("Commercial", '867', "Office Supply Superstore (1,000 sf)", 0, 0, 3.4, 2));
trafficCodes.push(new IteCode("Commercial", '868', "Book Superstore (1,000 sf)", 0, 0, 19.53, 1));
trafficCodes.push(new IteCode("Commercial", '869', "Discount Home Furnishing Store (1,000 sf)", 47.81, 1, 4.01, 1));
trafficCodes.push(new IteCode("Commercial", '870', "Apparel Store (1,000 sf)", 66.4, 1, 3.83, 7));
trafficCodes.push(new IteCode("Commercial", '879', "Arts and Crafts Store (1,000 sf)", 56.55, 1, 6.21, 2));
trafficCodes.push(new IteCode("Commercial", '880', "Pharmacy/Drugstore w/o Drive-Thru (1,000 sf)", 90.06, 6, 8.42, 6));
trafficCodes.push(new IteCode("Commercial", '881', "Pharmacy/Drugstore with Drive-Thru (1,000 sf)", 88.16, 3, 8.62, 12));
trafficCodes.push(new IteCode("Commercial", '890', "Furniture Store (1,000 sf)", 5.06, 13, 0.46, 16));
trafficCodes.push(new IteCode("Commercial", '895', "Video Arcade (1,000 sf) Not in ITE Manual 7th Ed.", 0, 0, 10.64, 1));
trafficCodes.push(new IteCode("Commercial", '896', "Video Rental Store (1,000 sf)", 0, 0, 13.6, 6));
trafficCodes.push(new IteCode("Commercial", '911', "Walk-In Bank (1,000 sf)", 156.48, 1, 33.15, 1));
trafficCodes.push(new IteCode("Commercial", '912', "Drive-In Bank (1,000 sf)", 246.49, 19, 45.74, 47));
trafficCodes.push(new IteCode("Commercial", '912', "Drive-In Bank (drive-in lanes)", 411.17, 6, 51.08, 34));
trafficCodes.push(new IteCode("Commercial", '931', "Quality Restaurant (1,000 sf)", 89.95, 15, 7.49, 24));
trafficCodes.push(new IteCode("Commercial", '931', "Quality Restaurant (seat)", 2.86, 11, 0.26, 15));
trafficCodes.push(new IteCode("Commercial", '932', "High-Turnover (Sit-Down) Restaurant (1,000 sf)", 127.15, 14, 10.92, 38));
trafficCodes.push(new IteCode("Commercial", '932', "High-Turnover (Sit-Down) Restaurant (seat)", 4.83, 2, 0.42, 14));
trafficCodes.push(new IteCode("Commercial", '933', "Fast Food w/o Drive Thru (1,000 sf)", 716, 1, 26.15, 4));
trafficCodes.push(new IteCode("Commercial", '933', "Fast Food w/o Drive Thru (seat)", 42.12, 1, 2.13, 1));
trafficCodes.push(new IteCode("Commercial", '934', "Fast Food w/Drive Thru (1,000 sf)", 496.12, 21, 34.64, 110));
trafficCodes.push(new IteCode("Commercial", '934', "Fast Food w/Drive Thru (seat)", 19.52, 10, 0.94, 27));
trafficCodes.push(new IteCode("Commercial", '935', "Fast Food w/Drive Thru & No Seats (1,000 sf)", 153.85, 2, 0, 0));
trafficCodes.push(new IteCode("Commercial", '936', "Drinking Place(1,000 sf)", 0, 0, 11.34, 12));
trafficCodes.push(new IteCode("Commercial", '941', "Quick Lubrication Vehicle Shop (serv position)", 40, 1, 5.19, 8));
trafficCodes.push(new IteCode("Commercial", '942', "Automobile Care Center (1,000 sf GLA)", 0, 0, 3.38, 5));
trafficCodes.push(new IteCode("Commercial", '942', "Automobile Care Center (service stall)", 0, 0, 2.17, 1));
trafficCodes.push(new IteCode("Commercial", '841', "New Car Sales (1,000 sf)", 33.34, 11, 2.64, 34));
trafficCodes.push(new IteCode("Commercial", '943', "Automobile Parts and Service Center (1,000 sf)", 0, 0, 4.46, 1));
trafficCodes.push(new IteCode("Commercial", '944', "Gasoline/Service Station (fuel position)", 168.56, 6, 13.86, 27));
trafficCodes.push(new IteCode("Commercial", '945', "Service Station w/Conv. Market (fuel position)", 162.78, 11, 13.38, 37));
trafficCodes.push(new IteCode("Commercial", '945', "Service Station w/Conv. Market (1,000 sf)", 0, 0, 96.37, 31));
trafficCodes.push(new IteCode("Commercial", '946', "Serv Station w/Conv. Market & Car Wash (fuel position)", 152.84, 10, 13.33, 26));
trafficCodes.push(new IteCode("Commercial", '947', "Self-Service Car Wash (wash stall)", 108, 1, 5.54, 6));
trafficCodes.push(new IteCode("Commercial", '948', "Automated Car Wash (1,000 sf)", 0, 0, 14.12, 1));
trafficCodes.push(new IteCode("Industrial", '760', "Research and Development Center (1,000 sf)", 8.11, 28, 1.08, 34));
trafficCodes.push(new IteCode("Industrial", '770', "Business Park (1,000 sf)", 12.76, 15, 1.29, 20));
trafficCodes.push(new IteCode("Industrial", '110', "General Light Industrial (1,000 sf)", 6.97, 18, 0.98, 26));
trafficCodes.push(new IteCode("Industrial", '120', "General Heavy Industrial (1,000 sf)", 1.5, 3, 0, 0));
trafficCodes.push(new IteCode("Industrial", '130', "Industrial Park (1,000 sf)", 6.96, 49, 0.86, 42));
trafficCodes.push(new IteCode("Industrial", '140', "Manufacturing (1,000 sf)", 3.82, 62, 0.74, 54));
trafficCodes.push(new IteCode("Industrial", '150', "Warehousing (1,000 sf)", 4.96, 16, 0.47, 26));
trafficCodes.push(new IteCode("Industrial", '151', "Mini-Warehouse (1,000 sf)", 2.5, 14, 0.26, 13));
trafficCodes.push(new IteCode("Industrial", '152', "High-Cube Warehouse (1,000 sf)", 0, 0, 0.12, 3));
trafficCodes.push(new IteCode("Industrial", '170', "Utilities (1,000 sf)", 0, 0, 0.49, 1));
trafficCodes.push(new IteCode("Industrial", '21', "Commercial Airport (flights per day)", 104.73, 2, 5.75, 2));
trafficCodes.push(new IteCode("Industrial", '22', "General Aviation Airport (based aircraft)", 5, 9, 0.52, 2));
trafficCodes.push(new IteCode("Industrial", '30', "Truck Terminal (1,000 sf)", 9.85, 1, 0.82, 1));
trafficCodes.push(new IteCode("Industrial", '90', "Park-and-Ride Lot (parking space)", 4.5, 4, 0.63, 9));
trafficCodes.push(new IteCode("Industrial", '93', "Light Rail Transit Station (parking space)", 2.51, 2, 1.24, 1));
trafficCodes.push(new IteCode("Recreational", '411', "City Park (acre)", 1.59, 3, 0, 0));
trafficCodes.push(new IteCode("Recreational", '412', "County Park (acre)", 2.28, 22, 0.06, 3));
trafficCodes.push(new IteCode("Recreational", '413', "State Park (acre)", 0.65, 12, 0, 1));
trafficCodes.push(new IteCode("Recreational", '414', "Water Slide Park (park space)", 1.67, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '415', "Beach Park (acre)", 29.81, 10, 1.3, 2));
trafficCodes.push(new IteCode("Recreational", '416', "Campground/Recreational Vehicle Park (acre)", 74.38, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '416', "Campground/Recreational Vehicle Park (site)", 0, 0, 0.37, 2));
trafficCodes.push(new IteCode("Recreational", '417', "Regional Park (acre)", 4.57, 5, 0.2, 2));
trafficCodes.push(new IteCode("Recreational", '417', "Regional Park (emp)", 0, 0, 0, 0));
trafficCodes.push(new IteCode("Recreational", '418', "National Monument (acre)", 5.37, 2, 0.42, 1));
trafficCodes.push(new IteCode("Recreational", '420', "Marina (boat berth)", 2.96, 11, 0.19, 2));
trafficCodes.push(new IteCode("Recreational", '420', "Marina (acres)", 20.93, 3, 0, 0));
trafficCodes.push(new IteCode("Recreational", '430', "Golf Course (acre)", 5.04, 24, 0.3, 5));
trafficCodes.push(new IteCode("Recreational", '430', "Golf Course (hole)", 35.74, 18, 2.74, 11));
trafficCodes.push(new IteCode("Recreational", '431', "Miniature Golf Course (hole)", 0, 0, 0.33, 1));
trafficCodes.push(new IteCode("Recreational", '432', "Golf Driving Range (tees)", 13.65, 1, 1.25, 7));
trafficCodes.push(new IteCode("Recreational", '433', "Batting Cages (cages)", 2.22, 3, 0, 0));
trafficCodes.push(new IteCode("Recreational", '435', "Multipurpose Recreational Facility (acre)", 90.38, 1, 5.77, 1));
trafficCodes.push(new IteCode("Recreational", '437', "Bowling Alley (1,000 sf)", 33.33, 1, 3.54, 1));
trafficCodes.push(new IteCode("Recreational", '437', "Bowling Alley (lane)", 33.33, 1, 3.54, 1));
trafficCodes.push(new IteCode("Recreational", '440', "Adult Cabaret (1,000 sf)", 0, 0, 3.67, 1));
trafficCodes.push(new IteCode("Recreational", '441', "Live Theatre (seat)", 0, 0, 0.02, 1));
trafficCodes.push(new IteCode("Recreational", '443', "Movie Theatre w/o Matinee (1,000 sf)", 78.06, 1, 6.16, 1));
trafficCodes.push(new IteCode("Recreational", '443', "Movie Theatre w/o Matinee (seat)", 1.76, 1, 0.06, 1));
trafficCodes.push(new IteCode("Recreational", '444', "Movie Theatre w/Matinee (1,000 sf)", 0, 0, 3.8, 2));
trafficCodes.push(new IteCode("Recreational", '444', "Movie Theatre w/Matinee (seat)", 0, 0, 0.07, 2));
trafficCodes.push(new IteCode("Recreational", '445', "Multiplex Movie Theater (1,000 sf)", 0, 0, 5.22, 7));
trafficCodes.push(new IteCode("Recreational", '445', "Multiplex Movie Theater (seats)", 0, 0, 0.29, 11));
trafficCodes.push(new IteCode("Recreational", '452', "Horse Racetrack (acre)", 43, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '452', "Horse Racetrack (seats)", 0.61, 2, 0.06, 1));
trafficCodes.push(new IteCode("Recreational", '460', "Arena (acre)", 33.33, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '465', "Ice Rink (1,000 sf)", 0, 0, 2.36, 1));
trafficCodes.push(new IteCode("Recreational", '465', "Ice Rink (seats)", 1.26, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '473', "Casino/Video Lottery Establishment (1,000 sf)", 0, 0, 13.43, 6));
trafficCodes.push(new IteCode("Recreational", '480', "Amusement Park (acre)", 75.76, 1, 3.95, 1));
trafficCodes.push(new IteCode("Recreational", '481', "Zoo (acre)", 114.88, 1, 0, 0));
trafficCodes.push(new IteCode("Recreational", '488', "Soccer Complex (fields)", 71.33, 3, 20.67, 3));
trafficCodes.push(new IteCode("Recreational", '491', "Tennis Courts (court)", 31.04, 3, 3.88, 3));
trafficCodes.push(new IteCode("Recreational", '492', "Racquet Club (court)", 38.7, 9, 3.35, 8));
trafficCodes.push(new IteCode("Recreational", '492', "Racquet Club (1,000 sf)", 14.03, 5, 0.84, 3));
trafficCodes.push(new IteCode("Recreational", '493', "Health Club (1,000 sf)", 32.93, 1, 4.05, 3));
trafficCodes.push(new IteCode("Recreational", '495', "Recreational Community Center (1,000 sf)", 22.88, 1, 1.64, 3));
trafficCodes.push(new IteCode("Public Uses", '501', "Military Base", 1.78, 7, 0.39, 6));
trafficCodes.push(new IteCode("Public Uses", '520', "Elementary School (student)", 1.29, 37, 0.28, 32));
trafficCodes.push(new IteCode("Public Uses", '520', "Elementary School (1,000 sf)", 14.49, 31, 3.13, 32));
trafficCodes.push(new IteCode("Public Uses", '522', "Middle School Jr High School (student)", 1.62, 20, 0.15, 11));
trafficCodes.push(new IteCode("Public Uses", '522', "Middle School Jr High School (1,000 sf)", 13.78, 20, 1.19, 9));
trafficCodes.push(new IteCode("Public Uses", '530', "High School (student)", 1.71, 51, 0.14, 34));
trafficCodes.push(new IteCode("Public Uses", '530', "High School (1,000 sf)", 12.89, 43, 0.97, 22));
trafficCodes.push(new IteCode("Public Uses", '534', "Private School (K-8) (student)", 0, 0, 0.61, 5));
trafficCodes.push(new IteCode("Public Uses", '534', "Private School (K-8) (1,000 sf)", 0, 0, 0, 0));
trafficCodes.push(new IteCode("Public Uses", '536', "Private School (K-12) (student)", 2.48, 2, 0.17, 3));
trafficCodes.push(new IteCode("Public Uses", '536', "Private School (K-12) (1,000 sf)", 0, 0, 5.5, 1));
trafficCodes.push(new IteCode("Public Uses", '540', "Junior/Community College (student)", 1.54, 6, 0.12, 5));
trafficCodes.push(new IteCode("Public Uses", '540', "Junior/Community College (1,000 sf)", 27.49, 4, 2.54, 3));
trafficCodes.push(new IteCode("Public Uses", '550', "University/College (student)", 2.38, 7, 0.21, 7));
trafficCodes.push(new IteCode("Public Uses", '560', "Church (1,000 sf)", 9.11, 8, 0.66, 11));
trafficCodes.push(new IteCode("Public Uses", '561', "Synagogue (1,000 sf)", 10.64, 1, 1.69, 1));
trafficCodes.push(new IteCode("Public Uses", '565', "Day Care Center (1,000 sf)", 79.26, 6, 13.18, 63));
trafficCodes.push(new IteCode("Public Uses", '565', "Day Care Center (student)", 4.48, 11, 0.82, 71));
trafficCodes.push(new IteCode("Public Uses", '566', "Cemetery (acre)", 4.73, 5, 0.84, 1));
trafficCodes.push(new IteCode("Public Uses", '571', "Prison (1,000 sf)", 0, 0, 2.91, 1));
trafficCodes.push(new IteCode("Public Uses", '590', "Library (1,000 sf)", 54, 9, 7.09, 10));
trafficCodes.push(new IteCode("Public Uses", '591', "Lodge/Fraternal Organization (members)", 0.29, 1, 0.03, 1));
trafficCodes.push(new IteCode("Public Uses", '610', "Hospital (1,000 sf)", 17.57, 14, 1.18, 7));
trafficCodes.push(new IteCode("Public Uses", '610', "Hospital (bed)", 11.81, 20, 1.3, 8));
trafficCodes.push(new IteCode("Public Uses", '620', "Nursing Home (1,000 sf)", 6.1, 2, 0.42, 2));
trafficCodes.push(new IteCode("Public Uses", '620', "Nursing Home (bed)", 2.37, 5, 0.22, 4));
trafficCodes.push(new IteCode("Public Uses", '620', "Nursing Home (occ. bed) - not in ITE Manual 7th ed.", 3.24, 1, 0.17, 11));
trafficCodes.push(new IteCode("Public Uses", '630', "Clinic (1,000 sf)", 31.45, 2, 5.18, 1));
trafficCodes.push(new IteCode("Public Uses", '730', "Government Office Building (1,000 sf)", 68.93, 1, 11.03, 1));
trafficCodes.push(new IteCode("Public Uses", '731', "State Motor Vehicles Department (1,000 sf)", 166.02, 8, 17.09, 8));
trafficCodes.push(new IteCode("Public Uses", '732', "U.S. Post Office (1,000 sf)", 108.19, 8, 10.89, 13));
trafficCodes.push(new IteCode("Public Uses", '733', "Government Office Complex (1,000 sf)", 27.92, 2, 2.85, 2));

export default trafficCodes;
