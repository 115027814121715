import { action, observable } from "mobx";

import {Typology} from "./ks/Typologies";
import {ISharedMix} from "./ks/DataStore";


export const EditModes = {
    None: 0,
    EditLanduse: 1,
    EditComponent: 2,
}

export default class UiStore {

    @observable editMode: number = EditModes.None;
    @observable activeTypology: Typology | undefined;
    @observable activeComponent: ISharedMix | undefined;
    @observable fileModalIsOpen: boolean = false;
    @observable infoModalIsOpen: boolean = false;
    @observable settingsModalIsOpen: boolean = false;
    @observable exportModalIsOpen: boolean = false;
    @observable saveAsModalIsOpen: boolean = false;

    @action
    setEditMode(mode: number, activeElement?: Typology): void {
        this.editMode = mode;

        if (activeElement) {
            this.activeTypology = activeElement;
        }
    }

    @action
    setEditComponentMode(activeElement: ISharedMix): void {
        this.editMode = EditModes.EditComponent;
        if (activeElement) {
            this.activeComponent = activeElement;
        }
    }

    @action
    setFileModalOpen(isOpen: boolean|undefined = true) {
        this.fileModalIsOpen = isOpen;
    }

    @action
    setInfoModalOpen(isOpen: boolean|undefined = true) {
        this.infoModalIsOpen = isOpen;
        console.log(this.infoModalIsOpen);
    }

    @action
    setSettingsModalOpen(isOpen: boolean|undefined = true) {
        this.settingsModalIsOpen = isOpen;
    }

    @action
    setExportModalOpen(isOpen: boolean|undefined = true) {
        this.exportModalIsOpen = isOpen;
    }

    @action
    setSaveAsModalOpen(isOpen: boolean|undefined = true) {
        this.saveAsModalIsOpen = isOpen;
    }

}
