import {observer} from "mobx-react";
import {HorizontalMixer} from "./HorizontalMixer";
import React from "react";
import {IComponentMix} from "../stores/ks/Typologies";
import {ILabel} from "../stores/ks/DataStore";

export const ComponentsMixer = observer(({componentMix}: { componentMix: IComponentMix }) => {
    const colorsB = componentMix.components.map(c => c.color);
    const valuesB = componentMix.components.map(c => c.shareAmount * 100);
    let labels = [];
    labels = componentMix.components.map(c => {
        const component = c as unknown as ILabel;
        return component?.label;
    });

    let along = 0;
    const breakValuesB = valuesB.map(v => {
        along += v;
        return along;
    });
    breakValuesB.pop();//don't include last value (should be 100%)
    console.log('ComponentsMixer', colorsB, breakValuesB);


    return <div className="ComponentsMixer">
        <HorizontalMixer colors={colorsB} labels={labels} breakValues={breakValuesB} onChange={(values) => {
            let along = 0;
            values.push(100);//account for difference between # of 'breaks' vs bars
            values.forEach((v, i) => {
                componentMix.components[i].shareAmount = (v - along) / 100;
                along = v;
            });
        }}/>
    </div>;
});

