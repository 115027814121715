import {observer} from "mobx-react";
import React from "react";
import {Swatch} from "../../../core/ui/elements/Swatch";
import {TweakableInput} from "../../../core/ui/TweakableInput";
import {
    DensityTypes,
    GrossTypology,
    GrossTypologyFromDensity,
    SurfaceTypology
} from "../../../core/stores/ks/Typologies";
import {Button} from "../../../core/ui/elements/Button";
import {useDtpStores} from "../../../core";
import {EditModes} from "../../../core/stores/UiStore";
import {IconButton} from "../../../core/ui/elements/IconButton";
import gearIcon from '../assets/settings.svg';
import trashIcon from '../assets/trash-2.svg';
import landscape from '../assets/mountains.svg';
import building from '../assets/home.svg';
import {CarbonInputComponent, EmbodiedCarbonSimulation} from "../../../core/simulations/EmbodiedCarbonSimulation";

const MaterialsInfo = observer(({grossTypology}: { grossTypology: GrossTypologyFromDensity }) => {
        const mainStore = useDtpStores();
        const {dataStore} = mainStore;

        const carbonSimulation = dataStore.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;
        if (!carbonSimulation) return null;
        return <>
            <img src={grossTypology.densityType === 'Building' ? building : landscape}
                 alt={`${grossTypology.densityType} icon`}/>
            {carbonSimulation.renderMaterialsFor(grossTypology)}
        </>
    }
);

export const LanduseItem = observer(({grossTypology}: { grossTypology: GrossTypologyFromDensity }) => {
        const mainStore = useDtpStores();
        const { ui } = mainStore;

        let edit = () => {
            mainStore.storeCancelState(`edit_${EditModes.EditLanduse}`);
            ui.setEditMode(EditModes.EditLanduse, grossTypology);
        };
        return <div className={'LanduseItem'}>
            <Swatch color={grossTypology.color}/>
            <div className="title">
                <h3 onClick={edit}>{grossTypology.name}</h3>
            </div>
            <div className={'MaterialsInfo'} onClick={edit}>
                {grossTypology.densityType === DensityTypes.Empty ?
                    <span className={'text'}>Set type...</span> :
                    <MaterialsInfo grossTypology={grossTypology}/>
                }
            </div>
            <div className="areaInput">
                <TweakableInput tweakableMetric={grossTypology.surfaceAreaConversion} hideToggle={false}
                                format={v => Math.round(v).toLocaleString()}/>
            </div>
            <div className={'rightButtonArea'}>
                <IconButton iconUrl={trashIcon} onClick={() => {
                    grossTypology.remove();
                }}/>
                <IconButton iconUrl={gearIcon} onClick={edit}/>
            </div>
        </div>
    }
);
