import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {computed, observable} from 'mobx';
import {applySnapshot, getSnapshot, Model, model, modelAction, prop} from 'mobx-keystone';

import Config from '../../types/Config';
import {DataStore} from './DataStore';
import {randomString} from '../../utils/utils';


const ENV_PREFIX = process.env.REACT_APP_ENV === 'production' ? '' : `${process.env.REACT_APP_ENV}_`;

export interface IPersistStore {
    id: string;
    exists: boolean;

    list(): any;

    load(): void;

    newDocument(dataStore: DataStore): void;

    save(dataStore: DataStore): void;

    seed(): void;

    data: any;
    created: number;
    description: string;
    modified: number;
    scenarioName: string;
    user: string;
    isLoggedIn: boolean;

    login(provider?: string): void;

    branch(): void;

    configure(config: Config): void;

    setDescription(description: string): void;

    setId(id: string): void;

    setScenarioName(scenarioName: string): void;
}

export enum ToolLinkOptions {
    NONE = "",
    PaintTheMap = "PaintTheMap",
    TotalPix = "TotalPix",
}

@model('dtp/PersistStore')
class PersistStore extends Model({
    id: prop<string>(() => randomString(20)),
    scenarioName: prop<string>(''),
    description: prop<string>(''),
    user: prop<string>(''),
    created: prop<number>(() => Date.now()),
    modified: prop<number>(0),
    toolLinkOption: prop<string>(ToolLinkOptions.NONE),
    data: prop<string>('{}'),
}) implements IPersistStore {

    private _db: any;
    private _collection: string = 'default';

    @observable
    exists: boolean = false;

    @modelAction
    configure(config: Config) {

        // @ts-ignore
        if (firebase.apps.length === 0) {
            firebase.initializeApp(config);
        }

        // @ts-ignore
        this._db = firebase.firestore();

        this._collection = config.collection;

        this._db.collection(this._collection).doc(this.id).get().then((doc: any) => {
            this._setExists(doc.exists);
        });

        this.seed();

        firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
                this._setUser(user.uid);
            } else {
                this._setUser('');
            }
        });
    }

    branch() {
        this.setId(randomString(20));
        this._setCreated(Date.now());
    }

    async delete(id: string) {
        const doc = await this._db.collection(this._collection).doc(id).get();

        if (doc && doc.exists) {
            doc.ref.delete();
        }
    }

    async list() {
        const collection = await this._db.collection(this._collection).where('user', '==', this.user).get();
        return collection.docs.map((doc: any) => doc.data()).sort((a: any, b: any) => a.created < b.created ? 1 : -1);
    }

    @modelAction
    async load() {
        const doc: any = await this._db.collection(this._collection).doc(this.id).get();
        const data = doc.data();

        if (data) {
            data.$modelId = this.$modelId;
            applySnapshot(this, data);
            this._setExists(true);
        }

        return DataStore.fromSnapshot(JSON.parse(this.data));
    }

    newDocument(dataStore:DataStore) {
        this.branch();
        // this.resetFromNew();
        this.setScenarioName('');
        this.setDescription('');
        this._setExists(false);
    }

    @modelAction
    login(provider?: string) {
        if (provider === 'google') {
            firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
        } else {
            firebase.auth().signInAnonymously();
        }
    }

    @computed
    get isLoggedIn() {
        return this.user !== '';
    }

    seed() {

    }

    save(dataStore: DataStore) {
        this._setModified(Date.now());
        this._setData(JSON.stringify(getSnapshot(dataStore)));
        this._db.collection(this._collection).doc(this.id).set(getSnapshot(this));
        this._setExists(true);
    }

    @modelAction
    setDescription(description: string) {
        this.description = description;
    }

    @modelAction
    setId(id: string) {
        this.id = id;
    }

    @modelAction
    setScenarioName(scenarioName: string) {
        this.scenarioName = scenarioName;
    }

    @modelAction
    private _setCreated(created: number) {
        this.created = created;
    }

    @modelAction
    private _setModified(modified: number) {
        this.modified = modified;
    }

    @modelAction
    private _setData(data: any) {
        this.data = data;
    }

    @modelAction
    private _setUser(user: string) {
        this.user = user;
    }

    @modelAction
    private _setExists(exists: boolean) {
        this.exists = exists;
    }

    @modelAction
    setToolLinkOption(option: string) {
        this.toolLinkOption = option;
    }

}

export default PersistStore;
