import { ExtendedModel, model, prop, rootRef, modelAction } from "mobx-keystone";
import { MixComponent } from "../../../stores/ks/Simulation";
import { ILabel } from "../../../stores/ks/DataStore";
import { LandscapeDataSchema, SiteElementSchema } from "./schemas";
import { SiteElement } from "./SiteElement";
import { AppliedSiteElement } from "./AppliedSiteElement";
import { LandscapeData } from "./LandscapeData";
import { computed } from "mobx";
import { globals } from "./assumptions";

export const siteElementRef = rootRef<SiteElement>("dtp/SiteElementRef");

function add(accumulator: number, a: number) {
    return accumulator + a;
}
@model("dtp/SiteLanduse")
export class SiteLanduse extends ExtendedModel(MixComponent, {
    itemId: prop<string>('', { setterAction: true }),
    subItems: prop<AppliedSiteElement[]>(() => []),
}) implements ILabel {
    get label() {
        return '';
    }

    @modelAction
    addSubItem(subItem: AppliedSiteElement) {
        this.subItems.push(subItem);
    }

    @computed
    get costLow() {
        return this.subItems.map(a => (a.siteElement?.costLow || 0) * a.percentage).reduce(add);
    }

    // Embodied Carbon Cost (High)  (kg CO2 eq./m^2)
    @computed
    get costHigh() {
        return this.subItems.map(a => (a.siteElement?.costHigh || 0) * a.percentage).reduce(add);
    }

    // Net Carbon Sequestered (60 years)  (kg CO2 eq./m^2)
    @computed
    get sequestered() {
        return this.subItems.map(a => (a.siteElement?.sequestered || 0) * a.percentage).reduce(add);
    }

    // Carbon Stored in Materials (kg CO2 eq./m^2) OR existing ecosystems to be preserved
    @computed
    get stored() {
        return this.subItems.map(a => (a.siteElement?.stored || 0) * a.percentage).reduce(add);
    }
}


