//NOTE: we have multiple configs for firebase. this one is for the centralized DTP
export default {//from Settings > General > Your Apps (Section) > Config (Radio)
    apiKey: "AIzaSyDLqR2WOLWbBdQRytoKM6TfD3XrTZimNY0",
    authDomain: "design-tools-platform.firebaseapp.com",
    databaseURL: "https://design-tools-platform.firebaseio.com",
    projectId: "design-tools-platform",
    storageBucket: "design-tools-platform.appspot.com",
    messagingSenderId: "1023792604039",
    appId: "1:1023792604039:web:c274063c42bc7bac07e922",
    measurementId: "G-X38904MNNV",
    clientId: "1023792604039-r6ogvd4v5hgmfbmd75apvd5lufhmaprf.apps.googleusercontent.com",
};

