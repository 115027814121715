import {observer} from "mobx-react";
import React from "react";
import {MainLayout} from "./ui/MainLayout";

import { useDtpStores } from '../../core';
import Login from '../../core/ui/Login';

import Carbon1 from './assets/Carbon01.png';
import Carbon2 from './assets/Carbon02.png';
import Carbon3 from './assets/Carbon03.png';
import Carbon4 from './assets/Carbon04.png';

const screens = [
    { src: Carbon2, alt: '' },
    { src: Carbon3, alt: '' },
    { src: Carbon1, alt: '' },
    { src: Carbon4, alt: '' },
];

export const App = observer(() => {
    const { user } = useDtpStores();

    return <>
        {user.isLoggedIn && user.isVerified ? (
            <MainLayout/>
        ): (
            <Login screens={screens} />
        )}
    </>
});
