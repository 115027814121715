import {observer} from "mobx-react";
import React from "react";
import {useDtpStores} from "../../../core";
import {fixedRectStyle} from "../../../core/ui/helpers";
import {PanelHeader} from "../../../core/ui/elements/PanelHeader";
import {Button} from "../../../core/ui/elements/Button";
import {DensityTypes, GrossTypologyFromDensity} from "../../../core/stores/ks/Typologies";
import {TweakablePipedSurfaceAreaMetric} from "../../../core/stores/ks/Streams";
import {LanduseItem} from "./LanduseItem";
import UiStore, {EditModes} from "../../../core/stores/UiStore";
import {EmbodiedCarbonSimulation} from "../../../core/simulations/EmbodiedCarbonSimulation";
import {CostSimulation} from "../../../core/simulations/CostSimulation";
import {EditComponent} from "./carbon/EditComponent";
import {EditLanduse} from "./carbon/EditLanduse";
import {DataStore} from "../../../core/stores/ks/DataStore";


const newColorSet =
    ["#d27930",
        "#6c4ac6",
        "#6ebb45",
        "#c54cc4",
        "#bcab43",
        "#54509e",
        "#6bb37d",
        "#cf4082",
        "#5ab9b1",
        "#d2433e",
        "#4b9bba",
        "#854431",
        "#7288c5",
        "#516730",
        "#c58cd6",
        "#cd966f",
        "#7c3c6a",
        "#d6889d"];

export const landuseManagerBack = (ui: UiStore) => {
    return {
        title: 'Landuse Manager', onClick: () => {
            ui.setEditMode(EditModes.None)
        }
    }
};
export const editLanduseBack = (ui: UiStore) => {
    return {
        title: 'Edit Landuse', onClick: () => {
            ui.setEditMode(EditModes.EditLanduse)
        }
    }
}

export const connectTypology = (dataStore: DataStore, newTypology: GrossTypologyFromDensity) => {
    dataStore.addTypology(newTypology);
    const carbonSimulation = dataStore.simulations.find(s => s.id === 'CARBON') as EmbodiedCarbonSimulation;
    if (carbonSimulation) {
        carbonSimulation.addInput(newTypology, 1);
    }
    const costSimulation = dataStore.simulations.find(s => s.id === 'COST') as CostSimulation;
    if (costSimulation) {
        costSimulation.addInput(newTypology, 100);
    }
};

export const LandusePanelList = observer(() => {
    const mainStore = useDtpStores();
    const {layout, ui, dataStore} = mainStore;

    const addNewTypology = () => {
        const newTypology = new GrossTypologyFromDensity({
            id: dataStore.getUnusedId('flex'),
            densityType: DensityTypes.Empty,
            name: 'New Landuse',
            color: dataStore.getUnusedColor(newColorSet, '#cccccc'),
            surfaceAreaMetric: new TweakablePipedSurfaceAreaMetric({overrideEnabled: false})
        });
        connectTypology(dataStore, newTypology);
        ui.setEditMode(EditModes.EditLanduse, newTypology);//automatically enter edit mode for new use
    };

    let typologies = dataStore.typologiesOfType<GrossTypologyFromDensity>(['dtp/GrossTypologyFromDensity']);
    return <>
        <PanelHeader title={'Landuse Manager'}
                     description={'Create and edit landuse elements, then assign materials to see impacts'}/>
        <div className={'panelInner'}>
            <div className={'landuseList'}>
                {typologies.map((grossTypology) => {
                    return <LanduseItem key={grossTypology.id} grossTypology={grossTypology}/>
                })}
                <Button title={'Create Landuse'} onClick={() => {
                    mainStore.storeCancelState(`edit_${EditModes.EditLanduse}`);
                    addNewTypology();
                }}/>
            </div>
            {typologies.length === 0 &&
            <p className={'get-started-help'}>Create a Landuse get started. To learn more about this tool, please watch this walk-through: <a href="https://vimeo.com/sasvideos/carbon-conscience-primer" target={'_blank'} rel={'noreferrer'}>How to Reduce Carbon
                Impacts from Kickoff</a></p>
            }

        </div>
    </>
});


export const LandusePanel = observer(() => {
    const mainStore = useDtpStores();
    const {layout, ui} = mainStore;

    return <div style={fixedRectStyle(layout.landusePanel)} className={'LandusePanel'}>
        <div className={'panelOuter ' + (layout.landusePanelCentered ? ' center' : ' right')}>
            {ui.editMode === EditModes.None && <LandusePanelList/>}
            {ui.editMode === EditModes.EditLanduse && <EditLanduse/>}
            {ui.editMode === EditModes.EditComponent && <EditComponent/>}
        </div>
    </div>
});
