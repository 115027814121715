import {observer} from "mobx-react";
import React from "react";
import {ParallelPlot} from "../../../../core/ui/charts/ParallelPlot";
import {CarbonSelection} from "../../../../core/simulations/embodiedCarbon/CarbonSelection";
import {CarbonInputComponent} from "../../../../core/simulations/EmbodiedCarbonSimulation";

export const CompareCategoryOptions = observer(({selection, carbonInputComponent}: { selection: CarbonSelection, carbonInputComponent: CarbonInputComponent }) => {
    const optionsData: any[] = [];
    const joinRanges = [];
    const fields = [];

    selection.allItemsUnderTier2.forEach((op) => {
        let item:any = {
            record: op,
            isSelected: selection.tier4Selection === selection.displayName(op)
        };
        if (op.uid === selection.comparisonItem?.uid) {
            item.colorOverride = '#ff00ff';
        }
        optionsData.push(item);//
    })

    let width = 580;
    if (selection.tier1Selection === 'Building') {
        joinRanges.push(['costLow_AboveGrade', 'costHigh_AboveGrade']);
        joinRanges.push(['costLow_BelowGrade', 'costHigh_BelowGrade']);
        fields.push(...[
            'costLow_BelowGrade',
            'costLow_AboveGrade',
            'costHigh_BelowGrade',
            'costHigh_AboveGrade',
            'stored',
        ]);
        width = 800;
    } else {
        joinRanges.push(['costLow', 'costHigh']);
        fields.push(...[
            'costLow',
            'costHigh',
            'sequestered',
            'stored',
        ]);
    }

    return <div>
        <p>Compare all options for {selection.tier2Selection}:</p>
        <ParallelPlot joinRanges={joinRanges} data={optionsData} fields={fields} width={width} height={280}
                      yAxisLabels={{top:'Higher Carbon Impact', bottom:'Lower Carbon Impact'}}
                      onLineClick={(record) => {
                          const val = selection.setSelectionFromId(record.uid);
                          if (val) {
                              carbonInputComponent.setValues(val);
                          }
                      }}/>
    </div>;
});
