import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {DatabaseReference, getDatabase, ref, set, onValue} from "firebase/database";
import {initializeApp} from "firebase/app"
import config from "./config";

const firebaseApp = initializeApp(config, 'DTP');
// const firebaseApp = firebase.apps[0];

export default class RealtimeConnection {
    //a 'chat' connection using firebase realtime db that allows communication on a stream
    //example use case would be syncing program uses across the platform (e.g. PaintTheMap, Grasshopper)
    private documentId: string;
    private userId: string | null;
    private dbRefOutgoing: DatabaseReference | undefined;
    private dbRefIncoming: DatabaseReference | undefined;

    lastIncomingTimestamp: number = 0;
    private onMeasurement: (data: any) => void;

    constructor(documentId: string, onMeasurement: (data:any)=>void) {
        this.documentId = documentId;
        this.userId = null;
        this.onMeasurement = onMeasurement;
    }

    connect(callback: (v: RealtimeConnection) => void) {
        const auth = getAuth(firebaseApp);
        signInAnonymously(auth).then(() => {
            console.log('ANON sign in for DTP');
        }).catch((error) => {
            console.error(error);
        });
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userId = user.email;
                const db = getDatabase(firebaseApp);
                this.dbRefOutgoing = ref(db, `/documents/${this.documentId}/definitions`);
                this.dbRefIncoming = ref(db, `/documents/${this.documentId}/measurements`);
                onValue(this.dbRefIncoming, (snapshot: any) => {
                    const fbData = snapshot.val();
                    console.log('MEASUREMENT RECEIVED', fbData)
                    if (fbData && fbData.timestamp > this.lastIncomingTimestamp) {
                        this.lastIncomingTimestamp = fbData.timestamp;
                        this.onMeasurement(fbData.content);
                        console.log('NEW DATA', fbData.content);
                    }
                }, function (errorObject) {
                    console.log('The read failed: ' + errorObject.message);
                });
                if (callback) callback(this);
            }
        });
    }

    async broadcastUses(content: any) {
        console.log('inside broadcastUses..');
        console.log('this.dbRef: ', this.dbRefOutgoing);
        if (!this.dbRefOutgoing) {
            return;
        }
        await set(this.dbRefOutgoing, {
            content: content,
            timestamp: Date.now(),
            userId: this.userId
        });
    }
}
