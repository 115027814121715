import {observer} from "mobx-react";
import React from "react";
import {Button} from "./Button";

export interface IBreadCrumb {
    title: string,
    onClick: () => void
}

export const ApplyCancel = observer((props: { onApply: () => void, onCancel: () => void, applyText?: string, cancelText?: string }) => {
    const {onApply, onCancel, applyText, cancelText} = props;
    return <div className={'ApplyCancel'}>
        <Button onClick={() => onCancel()} title={cancelText || 'Cancel'}/>
        <Button onClick={() => onApply()} title={applyText || 'Apply'}/>
    </div>
});
