import {observer} from "mobx-react";

import FileModal from '../../../core/ui/FileModal';
import InfoModal from '../../../core/ui/InfoModal';
import SaveAsModal from '../../../core/ui/SaveAsModal';
import MenuBar from "./MenuBar";
import {OutputsPanel} from "./OutputsPanel";
import {LandusePanel} from "./LandusePanel";
import {StreamsPanel} from "../../../core/ui/streams/StreamsPanel";
import {ConnectionsPanel} from "../../../core/ui/connections/ConnectionsPanel";
import SettingsModal from "../../../core/ui/SettingsModal";
import {SummaryChartModal} from "./carbon/SummaryChartModal";

export const MainLayout = observer(() => {
    return <div className={'MainLayout'}>
        <SaveAsModal />
        <SettingsModal />
        <FileModal />
        <InfoModal />

        <MenuBar/>
        <OutputsPanel/>
        <LandusePanel/>
        <ConnectionsPanel/>
        <SummaryChartModal/>
    </div>
});
