import {IRect} from "../types/IRect";
import React, {RefObject, useEffect} from "react";

export const fixedRectStyle = (rect: IRect) => {
    return {
        top: rect.y,
        left: rect.x,
        width: rect.width,
        height: rect.height,
    }
}
export const getUid = () => `${Math.random() * 100000000000}`.replace('.', '');

type AnyEvent = MouseEvent | TouchEvent

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: AnyEvent) => void,
): void => {
    useEffect(() => {
        const listener = (event: AnyEvent) => {
            const el = ref?.current

            // Do nothing if clicking ref's element or descendent elements
            if (!el || el.contains(event.target as Node)) {
                return
            }

            handler(event)
        }

        document.addEventListener(`mousedown`, listener)
        document.addEventListener(`touchstart`, listener)

        return () => {
            document.removeEventListener(`mousedown`, listener)
            document.removeEventListener(`touchstart`, listener)
        }

        // Reload only if ref or handler changes
    }, [ref, handler])
};
