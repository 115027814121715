import {observer} from "mobx-react";
import React, {MouseEventHandler, useRef, useState} from "react";
import {Swatch} from "./Swatch";
import {SketchPicker} from "react-color";
import {useOnClickOutside} from "../helpers";

export const SwatchPicker = observer(({color, setColor}: { color: string, setColor: (color: string) => void }) => {
    const [open, setOpen] = useState<boolean>(false);
    const wrapperRef = useRef(null);

    const handleClickOutside = () => {
        setOpen(false);
    }

    useOnClickOutside(wrapperRef, handleClickOutside)
    return <div className="SwatchPicker">
        <Swatch color={color} onClick={() => {
            setOpen(!open);
        }}/>
        {open && <div className="picker-open" ref={wrapperRef}><SketchPicker
            disableAlpha
            color={color}
            onChangeComplete={(color) => {
                setColor(color.hex);
            }}
        /></div>}
    </div>;
});
