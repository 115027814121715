import {observer} from "mobx-react";

import ToolBar from '../../../../core/ui/ToolBar';
import {useDtpStores} from "../../../../core";
import {fixedRectStyle} from "../../../../core/ui/helpers";
import logo from '../../assets/logo.svg';
import sasakiLogo from '../../../../core/assets/img/sasaki_logo_gray.png';

export default observer(function MenuBar() {
    const { layout } = useDtpStores();

    return <div style={fixedRectStyle(layout.toolBar)} className={'MenuBar'}>
        <div className="mainRibbonTop-holder" style={{width: layout.outputsPanel.width}}>
            <div className="mainRibbonTop">
                <img width={42} height={42} src={logo} alt={'carbon conscience logo showing 3 petals comprising a floral design'}/>
                <h1>Carbon Conscience</h1>
            </div>
        </div>

        {/*tuck this panel under the other slightly*/}
        <div className="toolbarSlideOut" style={{left: layout.outputsPanel.width - 5, backgroundImage:`url(${sasakiLogo})`}}>
            <ToolBar />
        </div>
    </div>
});
