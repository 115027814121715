import {detach, ExtendedModel, findParent, getRoot, Model, model, modelAction, prop} from "mobx-keystone";
import {action, computed, observable, values, runInAction} from "mobx";
import {observer} from "mobx-react";
import React, {useState} from "react";

import landscapeItems from "./embodiedCarbon/assets/landscape.json";

import {SelectList} from "./TrafficSimulation";
import {ComponentsMixer} from "../ui/ComponentsMixer";
import {MixComponent, MixedComponentInput, Simulation} from "../stores/ks/Simulation";
import {
    DensityTypes,
    GrossTypology,
    GrossTypologyFromDensity,
    Typology
} from "../stores/ks/Typologies";
import {DataStore, IBuildingTypology, IGrossTypology, ILabel, ISurfaceTypology} from "../stores/ks/DataStore";
import {NumericInput} from "../ui/NumericInput";
import {Metric} from "../stores/ks/Metrics";
import {CarbonItem, CarbonSelection} from "./embodiedCarbon/CarbonSelection";
import {IconButton} from "../ui/elements/IconButton";
import trashIcon from "../../skins/carbon-conscience/assets/trash-2.svg";
import gearIcon from "../../skins/carbon-conscience/assets/settings.svg";
import {Button} from "../ui/elements/Button";
import {BarChart, BarChartItem, HighLowStackChart} from "../ui/charts/BarChart";
import { LandscapeData } from "./embodiedCarbon/elements/LandscapeData";

type NullyNum = number | null | undefined;

const smartUnits = (kgCO2: number) => {
    if (Math.abs(kgCO2) < 1000) {
        return Math.round(kgCO2).toLocaleString() + ' kgCO₂'
    }
    //display as metric tons
    return Math.round(kgCO2 / 1000).toLocaleString() + ' tCO₂'
}

const CarbonUseValue = observer((props: { value: NullyNum, compareTo: NullyNum, label: string }) => {
    const {value, label, compareTo} = props;
    const style = {
        borderColor: '#000000',
        backgroundColor: ''
    }
    if (compareTo !== null && compareTo !== undefined && value !== null && value !== undefined) {
        if (compareTo > value) {
            const frac = value / compareTo;
            if (frac < 0.5) {
                style.borderColor = '#00ef00';
                style.backgroundColor = '#7db87d';
            } else {
                style.borderColor = '#027b02';
                style.backgroundColor = '#a8c6a3';

            }
        }
        if (compareTo < value) {
            const frac = value / compareTo;
            if (frac < 0.8) {
                style.borderColor = '#840707';
                style.backgroundColor = '#c6adad';

            } else {
                style.borderColor = '#de0000';
                style.backgroundColor = '#b87d7d';
            }
        }
    }
    const format = (v: NullyNum) => {
        if (!v) return '-';
        return `${Math.round(v * 100) / 100}`;
    }
    //todo use label as tooltip? {label}:
    return <div style={style} className={"CarbonUseValue"}>
        {format(value)}
    </div>;
});

const CarbonUseTitle = observer(({label}: { label: string }) => {
    return <div className={"CarbonUseTitle"}>
        {label}
    </div>;
});

export const CarbonUseCardTitles = observer(({type}: { type: string }) => {
    //Note: match structure of CarbonUseValue components below
    return <div className={"CarbonUseCardTitles"}>
        {type === 'Landscape' ? <>
            <CarbonUseTitle label={'Cost Low'}/>
            <CarbonUseTitle label={'Cost High'}/>
            <CarbonUseTitle label={'Sequestered'}/>
        </> : <>
            <CarbonUseTitle label={'Low (Above Grd)'}/>
            <CarbonUseTitle label={'Low (Below Grd)'}/>
            <CarbonUseTitle label={'High (Above Grd)'}/>
            <CarbonUseTitle label={'High (Below Grd)'}/>
        </>}
        <CarbonUseTitle label={'Stored'}/>
    </div>
});

export const CarbonUseCard = observer((props: { onClick: (val: CarbonItem) => void, isSelected: boolean, value: CarbonItem, compareTo?: CarbonItem, classOverride?: string }) => {
    const {value, onClick, isSelected, compareTo, classOverride} = props;
    const type = value.parents[0];
    const classAppend = classOverride || (isSelected ? 'selected' : '')
    return <div className={"CarbonUseCard " + classAppend} onClick={() => onClick(value)}>
        <div className={'header'}>
            <div className="ref">{value.ref}</div>
            <div className="name">{CarbonSelection.fullDisplayName(value, false)}</div>
        </div>
        <div>
            {type === 'Landscape' ? <>
                <CarbonUseValue value={value.costLow} compareTo={compareTo?.costLow} label={'costLow'}/>
                <CarbonUseValue value={value.costHigh} compareTo={compareTo?.costHigh} label={'costHigh'}/>
                <CarbonUseValue value={value.sequestered} compareTo={compareTo?.sequestered} label={'sequestered'}/>
            </> : <>
                <CarbonUseValue value={value.costLow_AboveGrade} compareTo={compareTo?.costLow_AboveGrade}
                                label={'costLow_AboveGrade'}/>
                <CarbonUseValue value={value.costLow_BelowGrade} compareTo={compareTo?.costLow_BelowGrade}
                                label={'costLow_BelowGrade'}/>
                <CarbonUseValue value={value.costHigh_AboveGrade} compareTo={compareTo?.costHigh_AboveGrade}
                                label={'costHigh_AboveGrade'}/>
                <CarbonUseValue value={value.costHigh_BelowGrade} compareTo={compareTo?.costHigh_BelowGrade}
                                label={'costHigh_BelowGrade'}/>
            </>}
            <CarbonUseValue value={value.stored} compareTo={compareTo?.stored} label={'stored'}/>
        </div>
    </div>;
});

export const CarbonUseCardSelector = observer((props: { type: string, options: CarbonItem[], compareTo?: CarbonItem, getter: () => CarbonItem | undefined, setter: (val: CarbonItem) => void }) => {
    const {type, options, getter, setter, compareTo} = props;
    return <div className="CarbonUseCardSelector">
        <CarbonUseCardTitles type={type}/>
        {options.map(option => {
            return <CarbonUseCard key={option.uid} value={option} compareTo={compareTo} isSelected={getter() === option}
                                  onClick={setter}/>
        })}
    </div>;
});

export const InTheWeeds = observer(({selection}: { selection: CarbonSelection }) => {
    if (!selection.matchingItem || !selection.matchingItem.details) return null;
    return <div className="InTheWeeds">
        <h3>In the weeds</h3>
        <p>
            {selection.matchingItem.details}
        </p>
    </div>
});


export const CarbonUsePicker = observer(({
                                             component,
                                             selection
                                         }: { component: CarbonInputComponent, selection: CarbonSelection }) => {
    return <div className="CarbonUsePicker">
        {/*{selection.tier1Options.length > 0 &&*/}
        {/*<SelectList options={selection.tier1Options} getter={() => selection.tier1Selection}*/}
        {/*            setter={(val) => selection.setTierSelection(0, val)}/>}*/}

        {selection.tier2Options.length > 0 &&
        <SelectList options={selection.tier2Options} getter={() => selection.tier2Selection}
                    setter={(val) => selection.setTierSelection(1, val)}/>}
        {selection.tier3Options.length > 0 &&
        <SelectList options={selection.tier3Options} getter={() => selection.tier3Selection}
                    setter={(val) => selection.setTierSelection(2, val)}/>}


    </div>;
});


@model("dtp/EmbodiedCarbonSimulation")
export class EmbodiedCarbonSimulation extends ExtendedModel(Simulation, {
    carbonInputs: prop<CarbonInput[]>(() => []),
}) {

    constructor(data:any) {
        super(data);
            //@ts-ignore
        LandscapeData.load(landscapeItems);
    }

    @computed
    get id(): string {
        return 'CARBON'
    }

    @computed
    get name(): string {
        return 'Embodied Carbon'
    }

    @modelAction
    addInput(typology: Typology, numShares: number): void {
        let carbonInput = new CarbonInput({});
        carbonInput.setTypology(typology);//Note: needs to be set before adding input components which look for this...
        for (let i = 0; i < numShares; i++) {
            carbonInput.addCarbonInputComponent(new CarbonInputComponent({shareAmount: 1 / numShares}));
        }
        this.carbonInputs.push(carbonInput);
    }

    renderLanduseEditorInput(typology: Typology, editFn: (component: CarbonInputComponent) => void): JSX.Element | null {
        const linkedInput = this.carbonInputs.find(input => input.typology === typology);
        if (!linkedInput) {
            return null;
        }
        const isMixed = linkedInput.components.length > 1;
        const carbonComponents = linkedInput.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent']);
        const componentSet = isMixed || (carbonComponents.length > 0 && !!carbonComponents[0].displayInfo);
        return <div className={'carbonLanduseEditorInput'}>
            {/*{isMixed && <>Multiplier: <NumericInput metric={linkedInput.multiplier}/></>}*/}
            <h3>Material Components</h3>
            {isMixed &&
            <ComponentsMixer componentMix={linkedInput}/>
            }
            {linkedInput.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent']).map((component, i) => {
                let edit = () => {
                    component.selection.storeSelectedAsComparison();
                    editFn(component);
                };
                return <div className={'carbonInputComponent'} key={`component_${i}`}>
                    {isMixed && <div className='sharePerc'>{Math.round(component.shareAmount * 100)}%</div>}
                    <div className={'displayInfo'}
                         onClick={edit}>{component.displayInfo || '(Select material...)'}</div>
                    <div className={'rightButtonArea'}>
                        <IconButton iconUrl={trashIcon} onClick={() => {
                            component.delete();
                            linkedInput.redistributeComponents();
                        }}/>
                        <IconButton iconUrl={gearIcon} onClick={edit}/>
                    </div>
                </div>
            })}
            {componentSet && <Button title={'Add component'} onClick={() => {
                linkedInput.addComponent();
            }}/>}
        </div>
    }

    renderComponentEditorInputCol1(component: CarbonInputComponent): JSX.Element | null {
        return <>
            <CarbonUsePicker component={component} selection={component.selection}/>
            <InTheWeeds selection={component.selection}/>
        </>
    }

    renderComponentEditorInputCol2(component: CarbonInputComponent): JSX.Element | null {
        const {selection} = component;
        return <div>
            {selection.tier4Options.length > 0 &&
            <CarbonUseCardSelector type={selection.tier1Selection} compareTo={selection.comparisonItem}
                                   options={selection.tier4Items}
                                   getter={() => selection.matchingItem}
                                   setter={(val: CarbonItem) => {
                                       selection.setMatchingItem(val);
                                       component.setValues(val);
                                   }}/>}
        </div>
    }

    renderMaterialsFor(typology: Typology): JSX.Element | null {
        const linkedInput = this.carbonInputs.find(input => input.typology === typology);
        if (!linkedInput) {
            return null;
        }
        const toPerc = (share: number) => {
            return `${Math.round(share * 100)}%`
        };
        const isMixed = linkedInput.components.length > 1;
        if (isMixed) {
            return <>
                {linkedInput.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent']).map((component, i) => {
                    return <div key={`component_${i}`}><span className="perc">{toPerc(component.shareAmount)}</span>
                        <span className="num">#{component.selection.matchingItem?.ref}</span>
                    </div>
                })}
            </>
        } else {
            const component = linkedInput.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent'])[0];
            return <div>
                {component.selection.matchingItem ?
                    <>
                        <span className="num">#{component.selection.matchingItem.ref}</span>
                        <span className="text">{CarbonSelection.fullDisplayName(component.selection.matchingItem, false)}</span>
                    </> :
                    'Not set'}
            </div>
        }
    }

    renderTypologyInput(typology: Typology): JSX.Element | null {
        //all-in-one version... more compact, but not used in CC skin
        const linkedInput = this.carbonInputs.find(input => input.typology === typology);
        if (!linkedInput) {
            return null;
        }
        const isMixed = linkedInput.components.length > 1;
        return <div>
            <h4>{typology.name}</h4>
            <div>
                {isMixed && <>Multiplier: <NumericInput metric={linkedInput.multiplier}/></>}
                <button onClick={() => {
                    linkedInput.addComponent();
                }}>Add component
                </button>
                {isMixed &&
                <ComponentsMixer componentMix={linkedInput}/>
                }
                {linkedInput.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent']).map((component, i) => {
                    return <div key={`component_${i}`}>share: {component.shareAmount}
                        <CarbonUsePicker component={component} selection={component.selection}/>
                        {isMixed && <button onClick={() => {
                            component.delete();
                            linkedInput.redistributeComponents();
                        }}>x</button>}
                    </div>
                })}
            </div>

        </div>
    }

    chartElements() {
        const dataStore = getRoot(this) as DataStore;

        let embodiedData: BarChartItem[] = [];
        let sequesteredData: BarChartItem[] = [];
        let storedData: BarChartItem[] = [];

        const totals = {
            embodiedLow: 0,
            embodiedHigh: 0,
            sequestered: 0,
            stored: 0,
        }
        dataStore.typologiesOfType<GrossTypology>(['dtp/GrossTypology', 'dtp/GrossTypologyFromDensity']).map((grossTypology) => {
            const linkedInput = this.carbonInputs.find(input => input.typology === grossTypology);
            if (!linkedInput) {
                return null;
            }
            //low: linkedInput.costLow, high: linkedInput.costHigh
            embodiedData.push({
                label: grossTypology.name,
                color: grossTypology.color,
                value: Math.round(linkedInput.costHigh),
                range: {min: Math.round(linkedInput.costLow), max: Math.round(linkedInput.costHigh)}
            });
            sequesteredData.push({
                label: grossTypology.name,
                color: grossTypology.color,
                value: Math.round(linkedInput.sequestered)
            });
            storedData.push({
                label: grossTypology.name,
                color: grossTypology.color,
                value: Math.round(linkedInput.stored)
            });
            totals.embodiedLow += linkedInput.costLow;
            totals.embodiedHigh += linkedInput.costHigh;
            totals.sequestered += linkedInput.sequestered;
            totals.stored += linkedInput.stored;
        });

        return {
            embodiedData,
            sequesteredData,
            storedData,
            totals
        }
    }

    renderOutputs(): JSX.Element | null {

        // return <div>{
        //     dataStore.typologiesOfType<SurfaceTypology>(['dtp/SurfaceTypology', 'dtp/GrossTypology', 'dtp/GrossTypologyFromDensity']).map((surfaceTypology: SurfaceTypology) => {
        //         const linkedInput = this.carbonInputs.find(input => input.typology === surfaceTypology);
        //         if (!linkedInput) {
        //             return null;
        //         }
        //         return <div>
        //             {surfaceTypology.name}
        //             Embodied Carbon Cost:({linkedInput.costLow} - {linkedInput.costHigh});
        //             Sequestered:({linkedInput.sequestered});
        //             Stored:({linkedInput.stored});
        //         </div>
        //     })}
        // </div>
        const dataStore = getRoot(this) as DataStore;
        if (!dataStore) {
            return null;
        }

        const {
            embodiedData,
            sequesteredData,
            storedData,
            totals
        } = this.chartElements();

        const nonEmptyFilter = (v: BarChartItem) => v.value !== 0;

        const formatVal = (value: number) => {
            return smartUnits(value);
        }

        return <div>
            <div className="CarbonOutput">
                <ChartSection title={'Embodied Carbon (tCO₂)'}
                              total={`${formatVal(totals.embodiedLow)} - ${formatVal(totals.embodiedHigh)} (high value charted)`}>
                    <BarChart data={embodiedData.filter(nonEmptyFilter)} format={smartUnits} height={480}/>

                    {/*<HighLowStackChart labels={labels} data={embodiedData} colors={colors}/>*/}
                </ChartSection>
                <ChartSection title={'Carbon Sequestered (tCO₂)'} total={`${formatVal(totals.sequestered)}`}>
                    <BarChart data={sequesteredData.filter(nonEmptyFilter)} format={smartUnits} height={320}/>
                </ChartSection>
                <ChartSection title={'Carbon Stored (tCO₂)'} total={`${formatVal(totals.stored)}`}>
                    <BarChart data={storedData.filter(nonEmptyFilter)} format={smartUnits} height={320}/>
                </ChartSection>
            </div>
        </div>
    }
}

export const ChartSection = ({title, total, children}: { title: string, total: string, children: JSX.Element }) => {
    return <div className="content">
        <header>
            <h2>{title}</h2>
        </header>
        {children}
        <div className={'total'}>
            <div className={'total-title'}>Total</div>
            <div className={'total-val'}>{total}</div>
        </div>
    </div>
}

//TODO figure out how to account for building vs landscape inputs
//e.g. building needs 'above grade' and 'below grade' inputs that don't apply to landscape
//also is this an opportunity to centralize 'gross program' calcs to support multiple simulations?
//currently we can mix 'components' and each component can have building and landscape...
//maybe that is useful?
//otherwise we should move top-level building vs landscape decision above components - and that also lets us input floors above/below grade
//then we should decide whether to use 'GrossTypology' regardless or whether we create a new typology when we toggle from landscape to building...?
//if we do switch, we'll want to persist common typology settings (name, color etc).
@model("dtp/CarbonInput")
export class CarbonInput extends ExtendedModel(MixedComponentInput, {
    multiplier: prop<Metric>(() => new Metric({value: 1}), {setterAction: true}),
}) {


    sumValue(getter: (component: CarbonInputComponent) => number) {
        const grossTypology = this.typology as unknown as IGrossTypology;
        const components = this.componentsOfType<CarbonInputComponent>(['dtp/CarbonInputComponent']);
        let area = 0;
        if (grossTypology) {
            area = grossTypology.grossArea;
        } else {
            const surfaceTypology = this.typology as unknown as ISurfaceTypology;
            if (surfaceTypology) {
                area = surfaceTypology.surfaceArea;
            }
        }
        area = area * this.multiplier.value;
        let sum = 0;
        components.forEach((component) => {
            const val = getter(component);
            if (!isNaN(val)) {
                sum += component.shareAmount * val * area;
            }
        });
        return sum;

    }

    blendedCostLost(component: CarbonInputComponent, cost: Metric, costBelowGrade: Metric) {
        const buildingTypology = this.typology as unknown as IBuildingTypology;
        if (!buildingTypology || !buildingTypology.isBuilding) {
            return cost.value;
        }
        const {totalFloors, floorsBelowGrade} = buildingTypology;

        return cost.value * (totalFloors - floorsBelowGrade) / totalFloors +
            costBelowGrade.value * floorsBelowGrade / totalFloors;
    }

    get costLow() {
        return this.sumValue((component) => this.blendedCostLost(component, component.costLow, component.costLowBelowGrade));
    }

    get costHigh() {
        return this.sumValue((component) => this.blendedCostLost(component, component.costHigh, component.costHighBelowGrade));
    }

    get sequestered() {
        return this.sumValue((component) => component.sequestered.value);
    }

    get stored() {
        return this.sumValue((component) => component.stored.value);
    }

    @modelAction
    addComponent() {
        //make 'space' for new component
        const newAmt = 1 / (this.components.length + 1);
        this.components.forEach((component, i) => {
            component.shareAmount *= (1 - newAmt);
        });
        this.addCarbonInputComponent(new CarbonInputComponent({shareAmount: newAmt}));
    }

    @modelAction
    setComponents(carbonInputComponents: CarbonInputComponent[]) {
        this.components = carbonInputComponents;
    }

    @modelAction
    addCarbonInputComponent(carbonInputComponent: CarbonInputComponent) {
        this.components.push(carbonInputComponent)
    }
}


@model("dtp/CarbonInputComponent")
export class CarbonInputComponent extends ExtendedModel(MixComponent, {
    itemId: prop<string>('', {setterAction: true}),
    costLow: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
    costHigh: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
    costLowBelowGrade: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
    costHighBelowGrade: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
    sequestered: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
    stored: prop<Metric>(() => new Metric({value: 0}), {setterAction: true}),
}) implements ILabel {
    selection: CarbonSelection;

    constructor(data: any) {
        super(data || {});
        this.selection = new CarbonSelection(this);
    }

    @computed
    get topLevelSelection(): string | undefined {
        if (this.carbonInput) {
            const grossTypology = this.carbonInput.typology as GrossTypologyFromDensity;
            if (grossTypology) {
                return grossTypology.densityType === DensityTypes.Building ? 'Building' : 'Landscape';
            }
        }
    }

    @computed
    get carbonInput(): CarbonInput | undefined {
        return findParent<CarbonInput>(this, (parentNode: any) => {
            return parentNode instanceof CarbonInput;
        });
    }

    @modelAction
    delete() {
        detach(this);
    }

    @modelAction
    setValues(matchingItem: any) {
        if (!matchingItem) return;
        this.itemId = matchingItem.uid;
        this.costLow.value = matchingItem.costLow || matchingItem.costLow_AboveGrade;
        this.costHigh.value = matchingItem.costHigh || matchingItem.costHigh_AboveGrade;
        this.costLowBelowGrade.value = matchingItem.costLow_BelowGrade;
        this.costHighBelowGrade.value = matchingItem.costHigh_BelowGrade;
        this.sequestered.value = matchingItem.sequestered;
        this.stored.value = matchingItem.stored;
    }

    @computed
    get displayInfo() {
        return `${this.selection.selectedDisplayName}`;
    }

    @computed
    get label() {
        return `${this.displayInfo}`;
    }
}

