import {observer} from "mobx-react";
import React from "react";
import {GrossTypologyFromDensity} from "../../stores/ks/Typologies";
import {LabelledNumericInput, NumericInput} from "../NumericInput";
import {getUid} from "../helpers";

export const BuildingFloorsInput = observer(({grossTypology}: { grossTypology: GrossTypologyFromDensity }) => {

    return <div className={'BuildingFloorsInput'}>
        <LabelledNumericInput label={'Total floors'} metric={grossTypology.densityValue} range={{min:0, max:100}}/>
        <LabelledNumericInput label={'Below grade'} metric={grossTypology.buildingFloorsBelowGrade} range={{min:0, max:10}}/>
    </div>
});
